import React, { useState } from "react";
import { Table, Tag, Space, Button, Row, Col, Select } from "antd";
import { EditOutlined, CloseCircleFilled } from '@ant-design/icons';
import numeral from "numeral";
import "../../Manage/component/Table.css";
import moment from "moment";

export default function TableDelivery({ data, page, onChangePage, onClickDetail, onClickCanel, loading, pageResult, pageSuccess, handleChangePage }) {

  const { Option } = Select;
  const columns = [
    {
      title: "รหัสคูปอง/หัวข้อเรื่อง",
      dataIndex: "broadcast_subject",
      key: "broadcast_subject",
      align: "center",
      width: 280,
      render: (text, record, index) => {
        return <div style={{
          width: 140, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left' }}>{text}</span>
        </div>
      }
    },
    {
      title: "ชื่อคูปอง/ข้อความ",
      dataIndex: "broadcast_message",
      key: "broadcast_message",
      align: "center",
      render: (text, record, index) => {
        return <div style={{
          width: 240, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
        </div>
      }
    },
    {
      title: "วันที่ส่ง",
      dataIndex: "schedule_date",
      key: "schedule_date",
      align: "center",
      render: (text, record, index) => {
        return <span style={{ fontWeight: 'bold' }}>{moment(text).format("DD/MM/YYYY")}</span>;
      }
    },
    {
      title: "สถานะจัดส่ง",
      dataIndex: "statusName",
      key: "statusName",
      align: "center",
      width: 200,
      render: (text, record, index) => {
        return <div style={{ width: 100, display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
        </div>
      }
    },
    {
      title: "ดำเนินการ",
      key: "key",
      align: "center",
      render: (text, item) => (
        <>
          {item.send_status === "W" ?
            <Button type="danger" onClick={() => {
              onClickCanel(item)
            }
            }>
              <CloseCircleFilled /> ยกเลิก
            </Button>
            :
            null
          }

          <Button type="primary" className="btn btn-secondary" style={item.send_status === "W" ? { marginLeft: 48 } : null} onClick={() => {
            onClickDetail(item)
          }
          }>
            <EditOutlined /> รายละเอียด
          </Button>
        </>
      ),
    },
  ];

  function changeSizePage(value) {
    handleChangePage(value)
  }


  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={20} style={{ textAlign: "start" }}>

          <span style={{ fontWeight: 'bold', marginLeft: 8, paddingTop: 24 }}>ผลการค้นหา  {pageResult} รายการ</span>
        </Col>
        <Col xs={4} style={{ justifyContent: 'end' }}>

          <span style={{ fontWeight: 'bold', marginLeft: 24 }}>เเสดง</span>
          <Select
            defaultValue="10"
            style={{ marginLeft: 10, marginRight: 10 }}
            className="mr-2 ml-2 mb-3"
            onChange={changeSizePage}>
            <Option value="10">10</Option>
            <Option value="25">25</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </Select>
          รายการ
        </Col>

      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rowKey="member_code"
            columns={columns}
            dataSource={data}
            className="table-member"
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageSuccess,
              defaultPageSize: "10",
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: false,
              total: data.length > 0 ? data[0].totalrow : 0,
              onChange: onChangePage,
              current: page,
              showTotal: (total, range) =>
                `แสดงรายการที่ ${numeral(range[0]).format("0,0")} 
          ถึง ${numeral(range[1]).format("0,0")} 
          จาก ${numeral(data.length > 0 ? data.length : 0).format("0,0")} รายการ`,
            }}
          />
        </Col>

      </Row>
    </>
  );
}
