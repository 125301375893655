import React, { useState } from "react";
import { Drawer, Button, Avatar, Popover, Menu, Row, Col } from "antd";
import { MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import "./NavBar.css";
import Logo from "../../component/Logo/Logo";
import { Link } from "react-router-dom";

const NavBar = ({ menu, name }) => {
  const [visible, setVisible] = useState(false);

  const content = (
    <Menu mode={"inline"}>
      <Menu.Item
        key="1"
        icon={<LogoutOutlined />}
        onClick={() => (window.location = `/#/logout`)}
      >
        ออกจากระบบ
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="navbar">
      <Drawer
        title=" "
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        {menu}
      </Drawer>
      <Row>
        <Col xs={12}>
          <a href="/">
            <Logo
              style={{ width: 100, marginLeft: "1rem",marginTop:14 }}
              textAlign={"left"}
            />
          </a>

          {(window.innerWidth || document.body.clientWidth) < 992 ? (
            <MenuOutlined
              style={{ marginTop: 12,fontSize:28 }}
              className="btn-menu"
              onClick={() => setVisible(true)}
            />
          ) : null}

        </Col>
        <Col xs={12} style={{position:"absolute",right:0,paddingRight:10,cursor:"pointer",paddingTop:5}}>
          <Popover
            placement="bottomLeft"
            title={false}
            content={content}
            trigger="click"
          
          >
            <Avatar style={{ verticalAlign: "middle",backgroundColor: '#f56a00' }} size="large">
              {localStorage.getItem("firstName")?localStorage.getItem("firstName").length > 2 ? localStorage.getItem("firstName"):localStorage.getItem("firstName")[0]:""}
            </Avatar>
          </Popover>
        </Col>
      </Row>
    </nav>
  );
};

export default NavBar;
