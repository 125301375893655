import { Select } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../api/axios";

const DDL_Group = ({ onChange, value, optionAll, type }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    api
      .post(
        "api/DDl/Group",
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        const data = response.data;

        data.success && setData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      loading={loading}
      onChange={onChange}
      value={value}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {optionAll && <Option value="">ทั้งหมด</Option>}
      {data.length > 0 &&
        data.map((item) => (
          <Option key={item.group_code} value={item.group_code} name="group">
            {item.group_desc}
          </Option>
        ))}
    </Select>
  );
};

export default DDL_Group;
