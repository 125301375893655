import React from "react";
import { Layout } from "antd";
import "./SideBar.css"
const SideBar = ({ menu }) => {
  return (
    <Layout.Sider
      className="sidebar page-breadcrumb"
      breakpoint="lg"
      width="220"
      theme="light"
      collapsedWidth={0}
      trigger={null}
      id="sider_menu"
      onClick={()=> window.scrollTo(0, 0) }
    >
       {menu}
    </Layout.Sider>
  );
};

export default SideBar;