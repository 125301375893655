/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */

import { UserAddOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  lazy,
  Suspense,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import DDL_Branch from "../../component/Dropdown/DDL_Branch";
import DDL_Brand from "../../component/Dropdown/DDL_Brand";
import Spinner from "../../component/Spinner/Spinner";
import { api } from "../../api/axios";
import { onScrollToRef } from "./component/FormRegisterUser";
import axios from "axios";

const TableUser = lazy(() => import("./component/TableUser"));
const FormRegisterUser = lazy(() => import("./component/FormRegisterUser"));

const initialInputSearch = {
  userCode: "",
  brand: "",
  branch: "",
};

const SearchUser = () => {
  const [spinding, setSpinding] = useState(false);
  const [isSearchComponent, setIsSearchComponent] = useState(true);
  const [inputSearch, setInputSearch] = useState(initialInputSearch);
  const [userList, setUserList] = useState([]);
  const [form] = useForm();
  const elemRef = useRef(null);
  const userRef = useRef([]);
  const actionRef = useRef("");
  const [page, setPage] = useState(1);
  const { CancelToken } = axios;

  const onChangePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const onSearchUser = useCallback(
    (data = {}) => {
      setSpinding(true);

      const json = {
        userCode: data.userCode,
        BranchCode: data.branch,
        BrandCode: data.brand,
        Page: page,
        PageLength: 10,
        ActionType: actionRef.current,
      };

      api
        .post("api/Management/GetListUser", json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          cancelToken: CancelToken.source().token,
        })
        .then((response) => {
          const { data } = response;

          if (data.success) {
            setUserList(data.data);
            setSpinding(false);
          } else {
            setSpinding(false);
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            CancelToken.source().cancel("Operation canceled by the user.");
            setSpinding(false);
          } else {
            setSpinding(false);
          }
        });
    },
    [isSearchComponent, page]
  );

  const marginBottom = { marginBottom: 16 };

  const onFinish = ({ userCode, branch, brand }) => {
    setPage(1);
    onSearchUser({ userCode, branch, brand });
  };

  useEffect(() => {
    if (elemRef.current) {
      onScrollToRef(elemRef);
    }
  }, [onChangePage]);

  useEffect(() => {
    const { userCode, branch, brand } = form.getFieldsValue();

    onSearchUser({ userCode, branch, brand });
  }, [page, isSearchComponent]);

  const onClear = () => {
    actionRef.current = "CLEAR";
    form.setFieldsValue(initialInputSearch);
    setInputSearch(initialInputSearch);
    setPage(1);
    onSearchUser();
  };

  const onModalUser = (item, type = "CREATE") => {
    setIsSearchComponent(false);
    userRef.current = item;
    actionRef.current = type;
  };

  const onAddUser = () => {
    setIsSearchComponent(false);
    setUserList([]);
    actionRef.current = "CREATE";
  };

  return (
    <>
      <Spinner visible={spinding} />
      {isSearchComponent ? (
        <>
          <UserAddOutlined /> ค้นหาข้อมูลผู้ใช้งานระบบ
          <hr />
          <Form
            form={form}
            name="form-add-user"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={16} justify="center" style={marginBottom}>
              <Col xs={8} md={6} lg={6}>
                <Form.Item name="userCode" label="ชื่อผู้ใช้งาน">
                  <Input value={inputSearch.userCode} />
                </Form.Item>
              </Col>
              <Col xs={8} md={6} lg={6}>
                <Form.Item name="branch" label="สาขา">
                  <DDL_Branch
                    placeholder="สาขา"
                    value={inputSearch.branch}
                    name="branch"
                    optionAll={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} md={6} lg={6}>
                <Form.Item name="brand" label="แบรนด์">
                  <DDL_Brand
                    placeholder="แบรนด์"
                    value={inputSearch.brand}
                    name="brand"
                    optionAll={false}
                    type="member"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify="center" style={marginBottom}>
              <Col xs={0} md={6}></Col>
              <Col xs={12} md={6} style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit">
                  <SearchOutlined />
                  ค้นหา
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button type="danger" onClick={onClear}>
                  <DeleteOutlined />
                  ล้าง
                </Button>
              </Col>
              <Col xs={0} md={6}></Col>
            </Row>
          </Form>
          <Row gutter={16} align="middle" justify="center" style={marginBottom}>
            <Col xs={10}>ผลการค้นหา</Col>
            <Col xs={14} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                style={{ height: 60 }}
                onClick={onAddUser}
                ref={elemRef}
              >
                เพิ่มข้อมูล <br />
                ผู้ใช้งานระบบ
              </Button>
            </Col>
          </Row>
          {Array.isArray(userList) && userList.length > 0 && (
            <Suspense fallback={<Spinner visible={spinding} />}>
              <TableUser
                data={userList}
                page={page}
                onChangePage={onChangePage}
                onModalUser={onModalUser}
              />
            </Suspense>
          )}
        </>
      ) : (
        <Suspense fallback={<Spinner visible={spinding} />}>
          <FormRegisterUser
            setIsSearchComponent={setIsSearchComponent}
            onSearchUser={onSearchUser}
            userList={userList.filter(
              (user) => user.userCode === userRef.current.userCode
            )}
            setSpinding={setSpinding}
            ref={actionRef}
          />
        </Suspense>
      )}
    </>
  );
};

export default SearchUser;
