import React, { useState, useEffect, memo } from "react";
import { api } from "../../api/axios";
import { Select } from "antd";

const { Option } = Select;

const DDL_Brand = ({ onChange, value, optionAll, type, actionType }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    const json = {};
    api
      .post("api/DDl/Brand", json, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setData(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Select
      showSearch
      style={
        type === "member" ? { width: "100%" } : { width: "100%", marginTop: 8 }
      }
      loading={loading}
      onChange={onChange}
      value={value}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={actionType === 'UPDATE'}
    >
      {optionAll === true ? <Option value="">ทั้งหมด</Option> : null}
      {data.length > 0
        && data.map((item) => (
            <Option key={item.brandCode} value={item.brandCode} name="brand">
              {item.brandNameEng}
            </Option>
          ))}
    </Select>
  );
}

export default memo(DDL_Brand);