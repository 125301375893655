import React, { useState, useEffect } from 'react'
import NavBar from "../../../component/NavBar/NavBar";
import { Layout, Card, Affix, Col, Row, Radio, Typography, Select, Button, Space, DatePicker, Image } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import times from '../../../json/time.json';
import { api } from "../../../api/axios";
import TableConfirmCustomer from "../table/TableConfirmCustomer";
import moment from "moment";
import SideBar from "../../../component/SideBar/SideBar";
import TopicMenu from "../../../component/TopicMenu";
import {
  SearchOutlined,
  PieChartOutlined,
  PlusSquareOutlined,
  FundViewOutlined,
  UserAddOutlined,
  BranchesOutlined
} from "@ant-design/icons";
export default function SuccessCustomer() {
  const { Title } = Typography;
  const [code, setCode] = useState("");
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [count, setCount] = useState('');
  const [image, setImage] = useState('');
  const marginBottom = { marginBottom: 36 };
  const marginTitle = { marginBottom: 16 };


  useEffect(() => {
    const code = localStorage.getItem("success_customer_code");
    var name = localStorage.getItem("success_customer_name");
    var message = localStorage.getItem("success_customer_message");
    var count = localStorage.getItem("success_customer_count");
    var image = localStorage.getItem("success_customer_image");
    setCode(code);
    setName(name)
    setMessage(message)
    setCount(count)
    setImage(image)
  }, []);




  const onComfirm = () => {
    window.location = `/#/management?key=2`;
  }





  const [selectedKey, setSelectedKey] = useState("2");
  const [menuApi, setMenuApi] = useState([]);

  const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

  const changeSelectedKey = (event) => {
    const key = event.key
    window.location = `/#/management?key=${key}`;
  };

  const Menu = (
    <TopicMenu
      icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />, <UserAddOutlined />, <BranchesOutlined />]}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <div>
      <Affix>
        <NavBar menu={null} name={"PHARMAX USER"} />
      </Affix>
      <Layout>
        <Affix offsetTop={60}>
          <SideBar menu={Menu} />
        </Affix>
        <Layout.Content style={{ marginLeft: 48, marginRight: 48, marginTop: 24 }}>
          <Card style={{ paddingRight: 24, paddingLeft: 24 }}>



            <Title level={4} style={{ marginTop: 36 }}>
              ยืนยันข้อมูลลูกค้า
            </Title>
            <hr style={marginBottom} />

            <Row style={{ marginTop: 12, marginBottom: 36 }}>
              <Col xs={{ span: 24 }} lg={{ span: 8 }} style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  width={"70%"}
                  src={image}
                />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 16 }} style={{ marginTop: 24 }} >
                <Title level={5} style={marginTitle}>
                  รหัสคูปอง/หัวข้อเรื่อง : {code}
                </Title>
                <Title level={5} style={marginTitle}>
                  ชื่อคูปอง/ข้อความ : {message}
                </Title>
                <Title level={5} style={marginTitle}>
                  จำนวนคงเหลือ {count} คูปอง
                </Title>
                <Title level={5} style={{ marginTop: 24, marginBottom: 16, color: "#A5CA7F" }}>
                  ดำเนินการส่งคูปอง/ข้อความสำเร็จ
                </Title>
                <Button
                  type="primary"
                  onClick={() => {
                    onComfirm()
                  }}
                  style={{ width: 60 }}
                >
                  ตกลง
                </Button>
              </Col>
            </Row>
          </Card>
        </Layout.Content>
      </Layout>

    </div>
  )
}
