/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Modal,
  Radio,
  Select,
} from "antd";
import Spinner from "../../component/Spinner/Spinner";
import Swal from "sweetalert2";
import Modal_PDPA from "../../component/Modal/Modal_PDPA";
import Logo from "../../component/Logo/Logo";
import {
  onSelectMonth,
  dayOfMonth,
  monthOfYear,
  yearOfAll,
  ValidateEmail,
  GetLineProfile,
  ConvertYear,
  ValidateNumeric,
} from "../../Helper/Helper";
import DDL_Province from "../../component/Dropdown/DDL_Province";
import DDL_District from "../../component/Dropdown/DDL_District";
import DDL_SubDistrict from "../../component/Dropdown/DDL_SubDistrict";

import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { api } from "../../api/axios";

const { Option } = Select;

export default function Register({ match, location }) {
  const [spinding, setSpinding] = useState(false);
  const [mobile, setMobile] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [provinces, setProvinces] = useState("");
  const [amphures, setAmphures] = useState("");
  const [districts, setDistricts] = useState("");
  const [zipCode, setZipCode] = useState();
  const [chkPdpa, setChkPdpa] = useState(undefined);
  const [profile, setProfile] = useState();
  const [brand, setBrand] = useState("");

  //from Register
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [sex, setSex] = useState("N");
  const [homeNo, setHomeNo] = useState("");
  const [street, setStreet] = useState("");
  const [day, setDay] = useState(undefined);
  const [month, setMonth] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [action, setAction] = useState("");
  const [member_code, setMember_code] = useState("");
  const [mstatus_code, setMstatus_code] = useState("");

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      let action = params.get("action");
      let migration = params.get("migration");
      setAction(action);
      setMstatus_code(migration);
      if (action === "edit") {
        onGetProfile();
      }
    }

    let mobile = localStorage.getItem("mobile");
    let brand = localStorage.getItem("brand");

    if (mobile && brand) {
      setMobile(mobile);
      setBrand(brand);
    } else {
      window.location = `/#/`;
    }

    return () => {
      localStorage.removeItem("action");
      localStorage.removeItem("referenceCode");
    };
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: "300px",
  });

  const onCheckValidate = () => {
    setSpinding(true);

    if (!firstName) {
      onErrorField("ยังไม่ได้กรอกชื่อ");
      return;
    }

    if (!lastName) {
      onErrorField("ยังไม่ได้กรอกนามสกุล");
      return;
    }

    if (!mobile) {
      onErrorField("ยังไม่ได้กรอกเบอร์โทร");
      return;
    }

    if (email) {
      if (!ValidateEmail(email)) {
        onErrorField("รูปแบบอีเมลไม่ถูกต้อง");
        return;
      }
    }

    if (!sex) {
      onErrorField("ยังไม่ได้เลือกเพศ");
      return;
    }

    let bridthDay = moment(`${ConvertYear(year)}-${month}-${day}`).format("DD/MM/YYYY");
    if (bridthDay === "Invalid date") {
      onErrorField("วันเกิดไม่ถูกต้อง ไม่ถูกต้อง");
      return;
    }

    if (!provinces) {
      onErrorField("ยังไม่ได้กรอกจังหวัด");
      return;
    }

    if (!amphures) {
      onErrorField("ยังไม่ได้กรอกอำเภอ");
      return;
    }

    if (!districts) {
      onErrorField("ยังไม่ได้กรอกตำบล");
      return;
    }

    if (!zipCode) {
      onErrorField("ยังไม่ได้กรอกรหัสไปรษณี");
      return;
    } else if (zipCode.length !== 5) {
      onErrorField("กรอกรหัสไปรษณีไม่ถูกต้อง");
      return;
    }

    if (!chkPdpa) {
      onErrorField("คุณยังไม่ได้กดยอมรับ PDPA");
      return;
    }

    if (action === "edit") {
      onEditMember();
    } else {
      onRegister();
    }
  };

  const onErrorField = (message) => {
    Toast.fire({
      icon: "error",
      title: message,
    });

    setSpinding(false);
  };

  const onRegister = () => {
    var LineProfile = GetLineProfile();

    const json = {
      mobile: mobile,
      fname: firstName,
      lname: lastName,
      email: email,
      sex: sex,
      birthdateString: moment(`${parseInt(year) - 543}-${month}-${day}`).format(
        "DD/MM/YYYY"
      ),
      h_no: homeNo ? homeNo : "",
      street: street ? street : "",
      province_code: provinces,
      district_code: amphures,
      subdistrict_code: districts,
      zip_code: zipCode,
      brand_code: brand,
      social_app_data: LineProfile ? LineProfile.userId : null,
      reg_from: localStorage.getItem("channal"),
      consent: chkPdpa,
      promotion_code: localStorage.getItem("promotion_code"),
    };

    api
      .post("api/members/Register", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });

          setTimeout(() => {
            window.location = `/#/memberDetial`;
          }, 1500);
        } else {
          onErrorField(data.data ? data.data : data.message);
        }

        setSpinding(false);
      })
      .catch((err) => {
        setTimeout(() => {
          window.location = `/#/memberDetial`;
        }, 1500);

        setSpinding(false);
        
      });
  };

  const onEditMember = () => {
    var LineProfile = GetLineProfile();

    const json = {
      member_code: member_code,
      brand_code: brand,
      mobile: mobile,
      fname: firstName,
      lname: lastName,
      email: email,
      sex: sex,
      birthdateString: moment(`${parseInt(year) - 543}-${month}-${day}`).format(
        "DD/MM/YYYY"
      ),
      h_no: homeNo ? homeNo : "",
      street: street ? street : "",
      province_code: provinces,
      district_code: amphures,
      subdistrict_code: districts,
      zip_code: zipCode,
      social_app_data: LineProfile ? LineProfile.userId : null,
      consent: chkPdpa,
      x_status: 'Y',
      mstatus_code: mstatus_code,
      promotion_code: localStorage.getItem("promotion_code"),
    };

    api
      .post("api/members/edit", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });

          setTimeout(() => {
            window.location = `/#/memberDetial`;
          }, 1500);
        } else {
          onErrorField(data.data ? data.data : data.message);
        }

        setSpinding(false);
      })
      .catch((err) => {
        setTimeout(() => {
          window.location = `/#/memberDetial`;
        }, 1500);

        setSpinding(false);

      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setChkPdpa(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setChkPdpa(false);
  }

  const onSelectProvinces = (e) => {
    setAmphures("");
    setDistricts("");
    setZipCode("");

    setProvinces(e);
  };

  const onSelectAmphures = (e) => {
    setDistricts("");
    setZipCode("");

    setAmphures(e);
  };

  const onSelectDistricts = (district, { postcode }) => {
    setDistricts(district);
    setZipCode(postcode);
  };

  const onGetProfile = () => {
    setSpinding(true);

    let mobile = localStorage.getItem("mobile");
    let brand = localStorage.getItem("brand");
    let profileLine = localStorage.getItem("profile");

    const json = {
      Mobile: mobile,
      brand_code: brand,
      social_app_data: JSON.parse(profileLine).userId,
    };
    api
      .post("api/members/GetProfile", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          var profile = data.data;

          setFirstName(profile.fname);
          setLastName(profile.lname);
          setEmail(profile.email);
          setSex(profile.sex);
          if (profile.birthdate != null) {
            setDay(moment(profile.birthdate).format("DD"));
            setMonth(moment(profile.birthdate).format("MM"));
            setYear(parseInt(moment(profile.birthdate).format("YYYY")) + 543);
          }
          setHomeNo(profile.h_no);
          setStreet(profile.street);
          setProvinces(profile.province_code);
          setAmphures(profile.district_code);
          setDistricts(profile.subdistrict_code);
          setZipCode(profile.zip_code);
          setChkPdpa(profile.consent_status === "Y");
          setMember_code(profile.member_code);
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onCancelMember = () => {
    Modal.confirm({
      title: "คุณต้องการที่จะยกเลิกการเป็นสมาชิก",
      icon: <InfoCircleOutlined />,
      content:
        "การยกเลิกการเป็นสมาชิกจะมีผลทำให้ข้อมูลส่วนตัวที่จัดเก็บในระบบถูกลบและไม่สามารถกู้คืนได้",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk: () => callApiCancelMember(),
    });
  };

  const callApiCancelMember = () => {
    setSpinding(true);

    let mobile = localStorage.getItem("mobile");
    let brand = localStorage.getItem("brand");

    const json = {
      Mobile: mobile,
      brand_code: brand,
    };
    api
      .post("api/members/DeleteMember", json)
      .then((response) => {
        let data = response.data;

        setProfile(data.data);
        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ",
          });
          setTimeout(() => {
            window.location = `/#/logout`;
          }, 1300);
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const styleFormInput = { marginTop: 16 };

  return (
    <div>
      <Row gutter={0} justify="center">
        <Col xs={22}>
          <Spinner visible={spinding} />

          <Modal_PDPA isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />

          <Logo />
          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14} lg={8}>
              <label className="title-header">ข้อมูลส่วนตัว</label>
              <Row
                gutter={16}
                justify="center"
                style={{ marginBottom: 16, marginTop: 16 }}
              >
                <Col xs={24} style={styleFormInput}>
                  <label>
                    ชื่อ<span className="red">*</span>
                  </label>
                  <Input
                    maxLength="20"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value.trim())}
                  />
                </Col>
                <Col xs={24} style={styleFormInput}>
                  <label>
                    นามสกุล<span className="red">*</span>
                  </label>
                  <Input
                    maxLength="25"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value.trim())}
                  />
                </Col>
                <Col xs={24} style={styleFormInput}>
                  <label>
                    เบอร์โทรศัพท์<span className="red">*</span>
                  </label>
                  <Input maxLength="10" value={mobile} disabled />
                </Col>
                <Col xs={24} style={styleFormInput}>
                  <label>
                    Email<span className="red"></span>
                  </label>
                  <Input
                    maxLength="100"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label>
                    เพศ<span className="red">*</span>
                  </label>
                  <Radio.Group
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    <Radio value="M">ชาย</Radio>
                    <Radio value="F">หญิง</Radio>
                    <Radio value="N">ไม่ระบุ</Radio>
                  </Radio.Group>
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <Input.Group>
                    <label>
                      วันเกิด<span className="red">*</span>
                    </label>
                    <Row
                      gutter={16}
                      justify="center"
                      style={{ marginBottom: 16 }}
                    >
                      <Col xs={8}>
                        <Select
                          showSearch
                          placeholder="ปี"
                          style={{ width: "100%" }}
                          value={year}
                          onChange={(e) => {
                            setYear(e);
                            setMonth(undefined);
                            setDay(undefined);
                          }}
                        >
                          {yearOfAll().map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={8}>
                        <Select
                          showSearch
                          placeholder="เดือน"
                          style={{ width: "100%" }}
                          value={month}
                          onChange={(e) => {
                            setMonth(e);
                            onSelectMonth(year, e);
                            setDay(undefined);
                          }}
                          disabled={!year}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {monthOfYear(year).map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={8}>
                        <Select
                          showSearch
                          placeholder="วัน"
                          style={{ width: "100%" }}
                          value={day}
                          onChange={(e) => {
                            setDay(e);
                          }}
                          disabled={!month}
                        >
                          {dayOfMonth().map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Input.Group>
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label className="title-header" style={{ marginBottom: 16 }}>
                    ข้อมูลที่อยู่
                  </label>
                </Col>
                <Col xs={24} style={styleFormInput}>
                  <label>
                    เลขที่
                  </label>
                  <Input
                    maxLength="20"
                    value={homeNo}
                    onChange={(e) => setHomeNo(e.target.value)}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label>
                    ถนน
                  </label>
                  <Input
                    maxLength="50"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label>
                    จังหวัด<span className="red">*</span>
                  </label>
                  <DDL_Province
                    onChange={onSelectProvinces}
                    value={provinces}
                    type={"member"}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label>
                    อำเภอ/เขต<span className="red">*</span>
                  </label>
                  <DDL_District
                    onChange={onSelectAmphures}
                    value={amphures}
                    provinceCode={provinces}
                    type={"member"}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label>
                    ตำบล/แขวง<span className="red">*</span>
                  </label>
                  <DDL_SubDistrict
                    onChange={onSelectDistricts}
                    value={districts}
                    districtCode={amphures}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <label>
                    รหัสไปรษณีย์<span className="red">*</span>
                  </label>
                  <Input
                    maxLength="5"
                    value={zipCode}
                    onChange={(e) => ValidateNumeric(e.target.value) ? setZipCode(e.target.value) : null}
                  />
                </Col>

                <Col xs={24} style={styleFormInput}>
                  <Checkbox
                    checked={chkPdpa}
                    onChange={(e) => action === "edit" && mstatus_code !== "MIG" ? null : setChkPdpa(e.target.checked)}
                  >
                    ยอมรับ
                  </Checkbox>
                  <span
                    className="text-pdpa"
                    onClick={() => setIsModalVisible(true)}
                  >
                    พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA)
                  </span>
                </Col>

                <Col xs={24} style={{ marginTop: 16, textAlign: "center" }}>
                  <Button
                    type="primary"
                    className="buttom-form"
                    onClick={onCheckValidate}
                  >
                    บันทึก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          {action == "edit" && mstatus_code !== "MIG" ? (
            <Row gutter={16} justify="center">
              <Col xs={20} md={14} lg={8} style={{ textAlign: "center" }}>
                <Button type="link" onClick={onCancelMember}>
                  ยกเลิกการเป็นสมาชิก
                </Button>
                <br /> <br /> <br />
              </Col>
            </Row>
          ) : null}

        </Col>
      </Row>
      <div className="footer" style={{ textAlign: "center", fontSize: 10 }}><span>{window.software_version}</span></div>
    </div>
  );
}
