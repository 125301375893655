import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Modal } from "antd";
import Logo from "../../component/Logo/Logo";
import Spinner from "../../component/Spinner/Spinner";
import Swal from "sweetalert2";
import { api } from "../../api/axios";
import {
  InfoCircleOutlined,
} from "@ant-design/icons";
import liff from "@line/liff";
import {
  isInClient,
} from "../../component/LineLiff/LineLiff";

export default function CheckMobilNumber({ match, location }) {
  const [mobile, setMobile] = useState("");
  const [brand, setBrand] = useState("");
  const [validateMobile, setValidateMobile] = useState(false);
  const [spinding, setSpinding] = useState(false);
  const { default_brand } = window;

  useEffect(() => {
    setSpinding(true);

    setTimeout(() => {
      setSpinding(false);
    }, 500);

    if (location) {
      const params = new URLSearchParams(location.search);
      let brand = params.get("brand");
      
      if (brand) {
        localStorage.setItem("brand", brand);
        setBrand(brand);
      } 
    }
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: '300px'
  });

  const onCheckRegister = () => {
    if (mobile.length === 10) {
      localStorage.setItem("mobile", mobile);
      onCheckMember();
    } else {
      setValidateMobile(true);
    }
  };

  const RequestOTP = (otp_action) => {
    setSpinding(true);

    const json = {
      mobile: mobile,
      brand: localStorage.getItem("brand"),
      type:"R"
    };
    api
      .post("api/OTP/RequestOTP", json)
      .then((response) => {
        let data = response.data;
        if (data.success) {
            Toast.fire({
              icon: "success",
              title: "ระบบกำลังจัดส่งรหัส OTP ไปยังเบอร์โทรศัพท์ของท่าน",
            });

            localStorage.setItem("mobile",mobile);
            localStorage.setItem("referenceCode",data.data.referenceCode);
            localStorage.setItem("otp_action",otp_action);
          setTimeout(() => {
            window.location = `/#/verifyotp`;
          }, 1000);
        }else{
          Toast.fire({
            icon: "error",
            title: "พบปัญหาในการเชื่อมต่อ กรุณาลองใหม่หรือแจ้งเจ้าหน้าที่",
          });
        }

        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onCheckMember = () => {
    setSpinding(true);

    const json = {
      mobile: mobile,
      brand_code: localStorage.getItem("brand")
    };
    api
      .post("api/Members/GetProfile", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {

          let onLiff = isInClient();
          if(onLiff){
            Modal.confirm({
              title: "ผูกบัญชีกับไลน์ ?",
              icon: <InfoCircleOutlined />,
              content: <span>คุณต้องการใช้หมายเลข {mobile} <br/> สำหรับสมัครสมาชิกหรือไม่</span>,
              okText: "ตกลง",
              cancelText: "ยกเลิก",
              onOk: () => RequestOTP("merge")
            });
            setSpinding(false);
          }else{
            if(data.data.mstatus_code === "MIG"){
              Modal.confirm({
                title: "เคยสมัครสมาชิกแล้ว ต้องการยืนยันตัวตน",
                icon: <InfoCircleOutlined />,
                content:<span>หมายเลข {mobile} <br/> เคยสมัครสมาชิกแล้ว</span>,
                okText: "ยืนยัน",
                cancelText: "ปิด",
                onOk: () => RequestOTP("detial")
              });
            }else{
              Modal.confirm({
                title: "เคยสมัครสมาชิกแล้ว",
                icon: <InfoCircleOutlined />,
                content:<span>หมายเลข {mobile} <br/> เคยสมัครสมาชิกแล้ว</span>,
                okText: "ปิด",
                okButtonProps:{ style: { display: 'none' } },
                cancelText: "ปิด",
                //onOk: () => RequestOTP("detial")
              });
            }
           
            setSpinding(false);
          }
        }else{
          RequestOTP("register");
        }
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onChangeMobile = (e) => {
    if (e.target.value.length < 11) {
      setMobile(e.target.value);
      setValidateMobile(false);
    }
  };

  const errorMessage = "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง";
  return (
    <div>
      <Row gutter={0} justify="center">
        <Col xs={22}>
          <Spinner visible={spinding} />
          <Logo />
          
          <div>
          </div>
        
          <div>
            <div className="title-header">ตรวจสอบเบอร์โทรศัพท์มือถือ</div>
            <div className="title-detail">
              กรุณากรอกเบอร์โทรศัพท์มือถือเพื่อรับรหัส OTP
            </div>

            <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
              <Col xs={22} md={14}>
                <label className="label-form">เบอร์โทรศัพท์มือถือ</label>
              </Col>
              <Col xs={22} md={14}>
                <Input
                  type="number"
                  className="mobile-input"
                  value={mobile}
                  onChange={onChangeMobile}
                />
              </Col>
              <Col xs={22} md={14} style={{ marginTop: 16 }}>
                {validateMobile ? (
                  <label className="label-error">{errorMessage}</label>
                ) : null}
              </Col>
              <Col
                xs={22}
                md={14}
                style={{ textAlign: "center", marginTop: 8 }}
              >
                <Button
                  type="primary"
                  className="buttom-form"
                  onClick={onCheckRegister}
                >
                  รับรหัส OTP
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="footer" style={{textAlign:"center", fontSize: 10}}><span>{window.software_version}</span></div>
    </div>
  );
}
