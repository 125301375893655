import React from "react";
import { Table, Button, Row, Col, Select } from "antd";
import { EditOutlined } from '@ant-design/icons';
import numeral from "numeral";
import "../../Manage/component/Table.css";


export default function TableCoupon({ data, page, onChangePage, onClick, loading, pageCount, pageResult, handleChangePage }) {
  const { Option } = Select;
  const columns = [
    {
      title: "รหัสคูปอง",
      dataIndex: "promotion_code",
      key: "promotion_code",
      align: "center",
      width: 260,
      render: (text, record, index) => {
        return <div style={{
          width: 140, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em',
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left' }}>{text}</span>
        </div>
      }
    },
    {
      title: "ชื่อคูปอง",
      dataIndex: "promotion_desc",
      key: "promotion_desc",
      align: "center",
      render: (text, record, index) => {
        return <div style={{
          width: 280, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left' }}>{text}</span>
        </div>
      }
    },
    {
      title: "วันที่ออกคูปอง",
      dataIndex: "active_date",
      key: "active_date",
      align: "center",
    },
    {
      title: "วันที่หมดอายุ",
      dataIndex: "coupon_expired_date",
      key: "coupon_expired_date",
      align: "center",
    },
    {
      title: "เลือก",
      key: "key",
      align: "center",
      render: (text, item) => (
        <Button type="primary" className="btn btn-secondary" onClick={() => {
          onClick(item)
        }
        }>
          <EditOutlined /> เลือก
        </Button>
      ),
    },
  ];


  function changeSizePage(value) {
    handleChangePage(value)
  }

  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={20} style={{ textAlign: "start" }}>

          <span style={{ fontWeight: 'bold', marginLeft: 8, paddingTop: 24 }}>ผลการค้นหา  {pageCount} รายการ</span>
        </Col>
        <Col xs={4} style={{ justifyContent: 'end' }}>

          <span style={{ fontWeight: 'bold', marginLeft: 24 }}>เเสดง</span>
          <Select
            defaultValue="10"
            style={{ marginLeft: 10, marginRight: 10 }}
            className="mr-2 ml-2 mb-3"
            onChange={changeSizePage}>
            <Option value="10">10</Option>
            <Option value="25">25</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </Select>
          รายการ
        </Col>

      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rowKey="promotion_code"
            columns={columns}
            dataSource={data}
            className="table-member"
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageResult,
              defaultPageSize: "10",
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: false,
              total: data.length > 0 ? data[0].totalrow : 0,
              onChange: onChangePage,
              current: page,
              showTotal: (total, range) =>
                `แสดงรายการที่ ${numeral(range[0]).format("0,0")} 
          ถึง ${numeral(range[1]).format("0,0")} 
          จาก ${numeral(data.length > 0 ? data.length : 0).format("0,0")} รายการ`,
            }}
          />
        </Col>

      </Row>
    </>
  );
}
