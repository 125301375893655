import React, { useState, useEffect } from "react";
import { api } from "../../api/axios";
import { Select } from "antd";

const { Option } = Select;

export default function DDL_District({onChange, value, provinceCode , type}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [provinceCode]);

  const getData = () => {
    setLoading(true);
    const json = {
      ProvinceCode: provinceCode,
    };
    api
      .post("api/DDl/District", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setData(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Select showSearch style={ type == "member" ? { width: "100%" } : { width: "100%" , marginTop : 8}} loading={loading} onChange={onChange} value={value} disabled={!provinceCode}
    filterOption={(input, option) =>  
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
    }
    >
      {data
        ? data.map((item) => (
            <Option key={item.districtCode} value={item.districtCode} name="district">
              {item.districtNameTH}
            </Option>
          ))
        : null}
    </Select>
  );
}
