import React, { useState, useEffect, useRef } from 'react'
import NavBar from "../../../component/NavBar/NavBar";
import { Layout, Card, Affix, Col, Row, Radio, Typography, Input, Button, Select, Form, Modal } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import TableCustomer from "../table/TableCustomer";
import TableCustomerSuccess from "../table/TableCustomerSuccess";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    SearchOutlined,
    DeleteOutlined,
    UploadOutlined,
    ImportOutlined,
    CloseCircleFilled,
    CheckCircleFilled,
    PieChartOutlined,
    PlusSquareOutlined,
    InfoCircleOutlined,
    FundViewOutlined,
    UserAddOutlined,
    BranchesOutlined
} from "@ant-design/icons";
import DDL_Brand from "../../../component/Dropdown/DDL_Brand";
import {
    monthOfYear,
} from "../../../Helper/Helper";
import DDL_Province from "../../../component/Dropdown/DDL_Province";
import DDL_District from "../../../component/Dropdown/DDL_District";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import * as XLSX from "xlsx";
import { api } from "../../../api/axios";
import SideBar from "../../../component/SideBar/SideBar";
import TopicMenu from "../../../component/TopicMenu";
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

export default function Customer() {
    const [value, setValue] = useState(3);
    const [brand, setBrand] = useState("");
    const [hide, setHide] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [confirm, setConfirm] = useState(0);
    const [viewResult, setViewResult] = useState(false);
    const [viewClear, setViewClear] = useState(false);
    const [viewSearch, setViewSearch] = useState(false);
    const [promotion_desc, setPromotionDesc] = useState('');
    const [promotion_name, setPromotionName] = useState('');
    const [promotionCode, setPromotionCode] = useState("");
    const fileInput = useRef();
    const { Title } = Typography;
    const styleFormInput = { paddingTop: 30, paddingBottom: 30 };
    const styleFormtextAlign = { textAlign: 'center' };
    const stylePaddingAlign = { paddingRight: 12, paddingLeft: 12 };
    const marginBottom = { marginBottom: 36 };
    const marginTitle = { marginBottom: 16 };
    const [listData, setListData] = useState([]);
    const [listResult, setListResultData] = useState([]);
    const [listFinal, setListFinalData] = useState([]);
    const [listDelete, setListDeleteData] = useState([]);
    const [listAge, setListAge] = useState([]);
    const onChange = (e) => {
        setListData([])
        setListResultData([])
        setListFinalData([])
        setListDeleteData([])
        setViewResult(false)
        setViewSearch(false)
        setValue(e.target.value);
    };
    const [page, setPage] = useState(10);
    const [pageCustomerResult, setPageCustomerResult] = useState(1);
    const [form] = Form.useForm();
    const [path, setPath] = useState('');
    const [pageSuccess, setPageSuccess] = useState(10);
    const [pageResult, setPageResult] = useState(1);
    const [isError, setError] = useState(false);
    const [sex, setSex] = useState("A");
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(undefined);
    const [provinces, setProvinces] = useState("ทั้งหมด");
    const [amphures, setAmphures] = useState("ทั้งหมด");
    const [admin, setAdmin] = useState(
        localStorage.getItem("groupCode") == "000" ||
        localStorage.getItem("groupCode") == "100"
    );

    const history = useHistory();
    const location = useLocation();

    const { Option } = Select;
    const { broadcastMessage } = location;

    const onSelectBrand = (e) => {
        setBrand(e);
    };

    const onSelectProvinces = (e) => {
        setProvinces(e);
    };

    const onSelectAmphures = (e) => {
        setAmphures(e);
    }

    const onChandeHide = () => {
        setHide(!hide)
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    async function saveAsExcel() {
        var data = [
            { phone: "08xxxxxxxx", brand: "icare" }
        ];
        let header = ["Phone", "Brand"];

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "example.xlsx");
            });
        });
    }

    useEffect(() => {
        const promotion_code = localStorage.getItem("promotion_code");
        const promotion_desc = localStorage.getItem('promotion_desc');
        const promotion_name = localStorage.getItem("promotion_name");

        const indexOfFirst = history.location.pathname.substring(1, location.length).indexOf('/');
        const indexCondiftion = history.location.pathname.substring(1, history.location.pathname.length).indexOf('/', indexOfFirst + 2);
        const path = history.location.pathname.substring(indexCondiftion + 2, history.location.pathname.length)
        setPromotionDesc(promotion_desc)
        setPromotionCode(promotion_code);
        setPromotionName(promotion_name);
        setPath(path)
        onInitAge()
    }, []);


    useEffect(() => {
        const customer_open = localStorage.getItem('customer_open')
        if (customer_open === "false") {
        } else {
            const customer_id_result = localStorage.getItem('customer_id_result')
            getDataListMapping(Number(customer_id_result))
        }
    }, []);



    const getDataListMapping = (id) => {
        let json = {
            "id": id
        }
        setLoading(true)
        api
            .post("Broadcast/GetBroadcastMapping", json, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                setLoading(false)
                let data = response.data;

                if (data.success) {
                    var list = [];
                    var i = 1;
                    for (const element of data.data) {
                        var obj = {
                            key: String(i),
                            name: element.fname + " " + element.lname,
                            sex: element.sex,
                            age: element.age,
                            mobile: element.mobile,
                            provinceNameTH: element.provinceNameTH,
                            brandNameEng: element.brandNameEng,
                            line: element.line == null || element.line === '' ? "ไม่มี" : "มี",
                            member_code: element.member_code,
                            line_id: element.line
                        }
                        i++;
                        list.push(obj)
                    }
                    setViewResult(true)
                    setViewSearch(false)
                    setViewClear(true)
                    setListFinalData(list)
                } else {
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.response) {
                    if (err.response.status === 401) {
                        window.location = `/#/logout`;
                    }
                }
            });
    };


    const onImportExcel = event => {
        const { files } = event.target;
        const file = files[0];
        setLoading(true)
        ExcelRenderer(file, (err, resp) => {
            var i = 0;

            for (const element of resp.rows) {
                if (element.length === 2 || element.length === 1) {
                    i += 1;

                }
            }

            if (i > 10001) {
                Modal.error({
                    title: 'ตรวจพบรายการที่นำเข้าข้อมูลมีจำนวนมากกว่า 10,000 รายการ',
                    icon: <ExclamationCircleOutlined />,
                    content: 'จำกัดครั้งละไม่เกิน 10,000 รายการ กรุณาตรวจสอบรายการนำเข้าข้อมูล',
                    okText: 'ตกลง',
                });
            } else {
                const data = new FormData();
                data.append("files", file);
                data.append("id", Number(path));
                data.append("brand_code", "IC000")
                api.post("Broadcast/BroadcastImportCustomer", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        "Content-type": "multipart/form-data; charset=UTF-8",
                    },
                }).then((response) => {
                    setLoading(false)
                    setListData([])
                    let data = response.data;
                    var count_error = 0;
                    if (data.success) {
                        var list = [];
                        var i = 1;
                        var name = '';
                        for (const element of data.data) {

                            if (element.fname === null && element.lname === null) {
                                name = ""
                            } else if (element.fname !== null && element.lname === null) {
                                name = element.fname
                            } else if (element.fname === null && element.lname !== null) {
                                name = element.lname
                            } else {
                                name = element.fname + " " + element.lname
                            }
                            var obj = {
                                key: String(i),
                                name: name,
                                sex: element.sex,
                                age: element.age,
                                mobile: element.mobile,
                                provinceNameTH: element.provinceNameTH,
                                brandNameEng: element.brandNameEng,
                                line: element.line == null || element.line === '' ? "ไม่มี" : "มี",
                                member_code: element.member_code,
                                line_id: element.line
                            }

                            i++;
                            list.push(obj)
                        }

                        if (count_error > 0) {
                            setError(true)
                        } else {
                            setError(false)
                        }
                        setListData(list)
                        setViewSearch(true)
                        setViewClear(true)
                    } else {
                    }
                }).catch((err) => {
                    setLoading(false)
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = `/#/logout`;
                        }
                    }
                });
            }

        });

    };
    const onConfirmResult = () => {
        if (listResult.length !== 0) {
            setViewResult(true)
        }

        const list = listData.filter(item => {
            return !listResult.includes(item)
        })
        setListData(list)

        const listIncrease = [
            ...listFinal,
            ...listResult
        ]

        let listIncreaseResult = listIncrease.filter((ele, ind) => ind === listIncrease.findIndex(elem => elem.member_code === ele.member_code));
        setListFinalData(listIncreaseResult);

        var count = confirm + 1;
        setConfirm(count)
    }


    const onDeleteResult = () => {
        listDelete[0] === undefined && listDelete.shift();
        const list = listFinal.filter(item => !listDelete.includes(item));
        setListFinalData(list)

        const listDecrease = [
            ...listData,
            ...listDelete
        ]

        // let listDecreaseResult = listDecrease.filter((ele, ind) => ind === listDecrease.findIndex(elem => elem.member_code === ele.member_code));
        setListData(listDecrease);
        setReload(true);
        setListDeleteData([]);
    }

    const getDataList = (brand_code, province_code, district_code, sex, start_age, end_age, month, mobile) => {
        let json = {
            "brand_code": brand_code,
            "province_code": province_code,
            "district_code": district_code,
            "sex": sex,
            "start_age": start_age,
            "end_age": end_age,
            "month": month,
            "mobile": mobile
        }
        setLoading(true)
        api
            .post("Broadcast/BroadcastSearchCustomer", json, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                setLoading(false)
                setListData([])
                let data = response.data;
                if (data.success) {
                    var list = [];
                    var i = 1;
                    for (const element of data.data) {
                        var name = ''

                        if (element.fname === null && element.lname === null) {
                            name = ""
                        } else if (element.fname !== null && element.lname === null) {
                            name = element.fname
                        } else if (element.fname === null && element.lname !== null) {
                            name = element.lname
                        } else {
                            name = element.fname + " " + element.lname
                        }
                        var obj = {
                            key: String(i),
                            name: name,
                            sex: element.sex,
                            age: element.age,
                            mobile: element.mobile,
                            provinceNameTH: element.provinceNameTH,
                            brandNameEng: element.brandNameEng,
                            line: element.line == null || element.line === '' ? "ไม่มี" : "มี",
                            member_code: element.member_code,
                            line_id: element.line
                        }
                        i++;
                        list.push(obj)
                    }
                    setListData(list)
                } else {
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.response) {
                    if (err.response.status === 401) {
                        window.location = `/#/logout`;
                    }
                }
            });
    };

    const onChangePage = (value) => {
        setPageCustomerResult(value);
    };

    const onHandleChangePage = (value) => {
        setPage(value);
    };

    const onHandleChangePageResult = (value) => {
        setPageSuccess(value);
    };

    const onChangePageResult = (value) => {
        setPageResult(value);
    };

    const onListResult = (list) => {
        if (list.length > 1) {
            setListResultData(list);
        } else {
            setListResultData(() => [...listResult, list[0]]);
        }
    }
    
    const onListFinal = (list) => {
        if (list.length > 1) {
            setListDeleteData(list);
        } else {
            setListDeleteData( [...listDelete, list[0]]);
        }
    };

    const onInitAge = () => {
        var list = []
        for (var i = 1; i <= 150; i++) {
            var obj = {
                label: i.toString(),
                value: i
            }
            list.push(obj)
            setListAge(list)
        }
    }


    function onChangeStart(date) {
        form.setFieldsValue({ dateStart: date });
    }

    function onChangeEnd(date) {
        form.setFieldsValue({ dateFinish: date });
    }

    const onFinish = (values) => {
        var brand = ""
        var province = ""
        var amphures = ""
        var gender = ""
        var start_age = null
        var end_age = null
        var month = null
        var mobile = ""

        if (values.brand == undefined) {
            brand = ""
        } else {
            brand = values.brand
        }

        if (values.province == undefined) {
            province = ""
        } else {
            province = values.province
        }


        if (values.amphures == undefined) {
            amphures = ""
        } else {
            amphures = values.amphures
        }


        if (values.gender == undefined) {
            gender = ""
        } else {
            gender = values.gender
        }


        if (values.dateStart == undefined) {
            start_age = null
        } else {
            start_age = Number(values.dateStart)
        }


        if (values.dateFinish == undefined) {
            end_age = null
        } else {
            end_age = Number(values.dateFinish)
        }


        if (values.month == undefined) {
            month = null
        } else {
            month = Number(values.month)
        }


        if (values.mobile == undefined) {
            mobile = ""
        } else {
            mobile = values.mobile
        }

        getDataList(brand, province, amphures, gender,
            start_age, end_age, month, mobile)
        setViewSearch(true)
        setViewClear(true)
    };

    const onClear = () => {
        setViewClear(true)
        setListData([]);
        form.resetFields();
    }

    function isInputNumber(evt) {

        const ch = String.fromCharCode(evt.which);
        if (!/[0-9]/.test(ch)) {
            evt.preventDefault();
        }
    }


    function checkInputNumber(evt) {
        let isnum = /^\d+$/.test(evt.target.value);

        if (isnum) {
            evt.preventDefault();
        } else {
            form.setFieldsValue({
                "mobile": ""
            });
        }

    }


    const onConfirm = () => {
        if (value === 1) {
            let json = {
                "id": Number(path),
            }

            api.post("Broadcast/SaveBroadcastAllCustomer", json, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then((response) => {
                let result = response.data;

                if (result.success) {
                    window.location = "/#/broadcast/customer-confirm/" + path;
                }
            }).catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        window.location = `/#/logout`;
                    }
                }
            });
        } else {
            if (listFinal.length == 0) {
                Modal.confirm({
                    title: "ข้อความ",
                    icon: <InfoCircleOutlined />,
                    content:
                        "กรุณาเลือกลูกค้า",
                    okText: "ตกลง",
                    cancelText: "ยกเลิก",
                });
            } else {
                var list = []
                for (const element of listFinal) {
                    var obj = {
                        member_code: element.member_code,
                        line: element.line_id
                    }
                    list.push(obj)
                }

                let json = {
                    "id": Number(path),
                    "data": list
                }

                api.post("Broadcast/SaveBroadcastMapping", json, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        "Content-type": "application/json; charset=UTF-8",
                    },
                }).then((response) => {
                    let result = response.data;

                    if (result.success) {
                        window.location = "/#/broadcast/customer-confirm/" + path;
                    } else {

                    }
                }).catch((err) => {
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = `/#/logout`;
                        }
                    }
                });
            }

        }

        localStorage.setItem("customer_open", false);

    }


    useEffect(() => {
        let access_token = localStorage.getItem("access_token");
        if (!access_token) window.location = `/#/login`;

        var menu = JSON.parse(localStorage.getItem("menu"));
        // var obj = {
        //     "group_code": "100",
        //     "module_code": "200",
        //     "module_en": "Broadcast Management",
        //     "module_th": null,
        //     "menu_code": "400",
        //     "menu_en": "Search Member",
        //     "menu_th": "การจัดส่งคูปอง/ข้อความ",
        //     "object_path": "List Broadcast",
        //     "objectState": 2

        // }
        // var menuResult = [
        //     ...menu,
        //     obj
        // ]
        setMenuApi(menu);
    }, []);



    const [selectedKey, setSelectedKey] = useState("2");
    const [menuApi, setMenuApi] = useState([]);

    const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

    const changeSelectedKey = (event) => {
        const key = event.key
        window.location = `/#/management?key=${key}`;
    };

    const Menu = (
        <TopicMenu
            icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />, <UserAddOutlined />, <BranchesOutlined />]}
            topics={topics}
            selectedKey={selectedKey}
            changeSelectedKey={changeSelectedKey}
        />
    );

    return (
        <div>
            <Affix>
                <NavBar menu={null} name={"PHARMAX USER"} />
            </Affix>
            <Layout>
                <Affix offsetTop={60}>
                    <SideBar menu={Menu} />
                </Affix>
                <Layout.Content style={{ marginLeft: 48, marginRight: 48, marginTop: 24 }}>
                    <Card style={{ paddingRight: 24, paddingLeft: 24 }}>



                        <Title level={4} style={{ marginTop: 36 }}>
                            เลือกลูกค้า
                        </Title>
                        <hr />

                        <Title level={5} style={{ marginTop: 36 }}>
                            รหัสคูปอง/ชื่อหัวเรื่อง : {[broadcastMessage ? promotion_name : promotionCode ]}
                        </Title>

                        <Title level={5} style={marginTitle}>
                            ชื่อคูปอง/ข้อความ : {promotion_desc}
                        </Title>



                        <Row gutter={16} justify="center" style={styleFormInput}>
                            <Col xs={8} md={4}></Col>
                            <Col xs={8} md={16} style={styleFormtextAlign}>
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio style={stylePaddingAlign} value={1}>ลูกค้าทั้งหมด</Radio>
                                    <Radio style={stylePaddingAlign} value={2}>นำเข้าข้อมูล</Radio>
                                    <Radio style={stylePaddingAlign} value={3}>ค้นหาข้อมูล</Radio>
                                </Radio.Group>
                            </Col>
                            <Col xs={8} md={4}></Col>
                        </Row>

                        {value == 1 ?
                            <></>
                            :
                            value == 2 ?
                                <>
                                    <Row justify="end" style={marginBottom} >
                                        <Col flex="80px" style={{ marginRight: 24 }}>
                                            <Button icon={<UploadOutlined />} type="primary" style={{ width: "100%" }} onClick={saveAsExcel}>
                                                Download รูปเเบบข้อมูล
                                            </Button>
                                        </Col>
                                        <Col flex="80px">
                                            <div>
                                                <Button icon={<ImportOutlined />} type="primary" style={{ width: "100%" }}
                                                    onClick={() => fileInput.current.click()}>
                                                    Import นำเข้าข้อมูล
                                                </Button>
                                                <input ref={fileInput} type="file" accept=".xlsx, .xls" onChange={onImportExcel} style={{ display: 'none' }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                </>
                        }

                        <Row style={{ marginTop: 48, height: 30 }}>
                            <Col xs={24} md={23}>
                                <Title level={4} style={{ marginTop: 12 }} >
                                    ค้นหา
                                </Title>
                            </Col>
                            <Col xs={24} md={1}>

                                {value == 1 ?
                                    null
                                    :
                                    hide ?
                                        <img src={"https://ucarecdn.com/2c2e4a31-b339-44fa-a502-38ec7d0ac23d/add1.png"} alt="Plus" width="30" height="30" onClick={onChandeHide} style={{ cursor: 'pointer', marginTop: 6 }}></img> :
                                        <img src={" https://ucarecdn.com/19560fb1-d410-4779-a1b1-ae64e8927314/minusbutton.png"} alt="Minus" width="30" height="30" onClick={onChandeHide} style={{ cursor: 'pointer', marginTop: 6 }}></img>

                                }

                            </Col>
                        </Row>
                        <hr style={marginBottom} />
                        {hide === false && value !== 1 ?
                            <>
                                <Form form={form} name="control-hooks" onFinish={onFinish}>
                                    <Row gutter={16} justify="center" >
                                        <Col xs={12} md={6}>
                                            แบรนด์
                                        </Col>
                                        <Col xs={12} md={6}>
                                            จังหวัด
                                        </Col>
                                        <Col xs={12} md={6}>
                                            เขต/อำเภอ
                                        </Col>
                                        <Col xs={12} md={6}>
                                            เพศ
                                        </Col>
                                    </Row>
                                    <Row gutter={16} justify="center" style={{ marginTop: 6 }}>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="brand"
                                            >
                                                <DDL_Brand
                                                    placeholder={"แบรนด์"}
                                                    onChange={onSelectBrand}
                                                    type={"member"}
                                                    value={brand}
                                                    optionAll={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="province"
                                            >
                                                <DDL_Province onChange={onSelectProvinces} value={provinces} type={"member"} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="amphures"
                                            >
                                                <DDL_District
                                                    onChange={onSelectAmphures}
                                                    value={amphures}
                                                    provinceCode={provinces}
                                                    type={"member"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="gender"
                                            >
                                                <Select
                                                    placeholder="เพศ"
                                                    style={{ width: "100%" }}
                                                    onChange={(e) => setSex(e)}
                                                >
                                                    <Option value={null}>ทั้งหมด</Option>
                                                    <Option value="M">ชาย</Option>
                                                    <Option value="F">หญิง</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} justify="center" style={{ marginTop: 12 }}>
                                        <Col xs={12} md={6}>
                                            ตั้งเเต่ ช่วงอายุ (ปี)
                                        </Col>
                                        <Col xs={12} md={6}>
                                            ถึง ช่วงอายุ (ปี)
                                        </Col>
                                        <Col xs={12} md={6}>
                                            เดือนเกิด
                                        </Col>
                                        <Col xs={12} md={6}>
                                            เบอร์โทรศัพท์
                                            {/* หมายเลขโทรศัพท์ */}
                                        </Col>
                                    </Row>
                                    <Row gutter={16} justify="center" style={{ marginTop: 6, marginBottom: 12 }}>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="dateStart"
                                            // label={"ตั้งเเต่ ช่วงอายุ (ปี)"}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="ปี"
                                                    style={{ width: "100%" }}
                                                    onChange={onChangeStart}
                                                >
                                                    {listAge.map((item) => (
                                                        <Option key={item.value}>
                                                            {item.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="dateFinish"
                                            // label={"ถึง ช่วงอายุ (ปี)"}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="ปี"
                                                    style={{ width: "100%" }}
                                                    onChange={onChangeEnd}
                                                >
                                                    {listAge.map((item) => (
                                                        <Option key={item.value}>
                                                            {item.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="month"
                                            // label={"เดือนเกิด"}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="เดือน"
                                                    style={{ width: "100%" }}
                                                    value={month}
                                                    onChange={(e) => {
                                                        setMonth(e);
                                                    }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {monthOfYear(year).map((item) => (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name="mobile"
                                            // label={"หมายเลขโทรศัพท์"}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                    placeholder=""
                                                    onKeyPress={(event) => {
                                                        isInputNumber(event);
                                                    }}
                                                    onChange={(event) => {
                                                        checkInputNumber(event);
                                                    }}
                                                    maxLength={10}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} justify="center" style={marginBottom}>
                                        <Col xs={0} md={6}></Col>
                                        <Col xs={12} md={6} style={{ textAlign: "right" }}>
                                            <Button type="danger" onClick={onClear}>
                                                <DeleteOutlined />
                                                ล้าง
                                            </Button>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Button type="primary" htmlType="submit">
                                                <SearchOutlined />
                                                ค้นหา
                                            </Button>
                                        </Col>
                                        <Col xs={0} md={6}>

                                        </Col>
                                    </Row>
                                </Form>
                            </>
                            :
                            null
                        }


                        {viewSearch ?
                            <>
                                {viewClear ?
                                    <Typography.Text style={{ marginTop: 36 }}>
                                        ผลการค้นหา {listData.length} รายการ
                                    </Typography.Text>
                                    :
                                    null
                                }

                                <TableCustomer
                                    data={listData}
                                    page={page}
                                    pageData={pageCustomerResult}
                                    loading={loading}
                                    onChangePage={onChangePage}
                                    handleChangePage={onHandleChangePage}
                                    onListResult={onListResult}
                                    reload={reload}
                                />

                                <Row gutter={16} justify="center" style={{ marginTop: 12, marginBottom: 36 }}>
                                    <Col xs={0} md={6}></Col>
                                    <Col xs={12} md={6} style={{ textAlign: "right" }}>
                                        <Button disabled={isError} type="danger" onClick={() => { onDeleteResult() }}>
                                            <CloseCircleFilled />
                                            ยกเลิกรายชื่อ
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Button disabled={isError} type="primary" style={{ backgroundColor: "#73d13d", borderColor: "#73d13d" }} onClick={() => { onConfirmResult() }}>
                                            <CheckCircleFilled />
                                            เพิ่มรายชื่อ
                                        </Button>
                                    </Col>
                                    <Col xs={0} md={6}></Col>
                                </Row>
                            </>
                            :
                            null
                        }

                        {viewResult && viewClear ?
                            <Typography.Text level={6} style={{ marginTop: 36 }}>
                                รายการลูกค้าที่เลือก {listFinal.length} รายการ
                            </Typography.Text>
                            :
                            null
                        }

                        {viewResult ?
                            <TableCustomerSuccess
                                data={listFinal}
                                page={pageResult}
                                pageData={pageSuccess}
                                loading={loading}
                                onChangePage={onChangePageResult}
                                onListFinal={onListFinal}
                                handleChangePage={onHandleChangePageResult}
                                confirm={confirm}
                            />
                            :
                            null
                        }




                        <Row justify="end" style={{ marginTop: 24 }}>

                            <Col flex="60px" style={{ marginRight: 24 }}>
                                <Button type="danger" style={{ width: '100%' }}
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                            </Col>
                            <Col flex="60px">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        onConfirm()
                                        // window.location = "/#/broadcast/customer-confirm/10";
                                    }}
                                >
                                    ตกลง
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Layout.Content>
            </Layout>

        </div>
    )
}
