import { HashRouter, Route, Switch } from "react-router-dom";

import Full from "./containers/Full/Full";

import Login from "./view/Login/Login";
import Logout from "./view/Logout/Logout";

import Landing from "./view/Register/Landing";
import CheckMobilNumber from "./view/Register/CheckMobilNumber";
import VerifyOTP from "./view/Register/VerifyOTP";
import Register from "./view/Register/Register";
import MemberDetial from "./view/Register/MemberDetial";
import Message from "./view/Broadcast/Message";
import Coupon from "./view/Broadcast/Coupon";
import Customer from "./view/Broadcast/post/Customer";
import ConfirmCustomer from "./view/Broadcast/post/ConfirmCustomer";
import SuccessCustomer from "./view/Broadcast/post/SuccessCustomer";
import DeliveryDetail from "./view/Delivery/Detail";

export default function App() {
  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path="/management/"
          name="Management Page"
          component={Full}
        />

        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/logout" name="Logout Page" component={Logout} />

        <Route
          exact
          path="/landing"
          name="Landing Page"
          component={Landing}
        />

        <Route
          exact
          path="/checkMobile"
          name="Check Mobil Number Page"
          component={CheckMobilNumber}
        />
        <Route
          exact
          path="/verifyOtp"
          name="Verify Otp Page"
          component={VerifyOTP}
        />
        <Route
          exact
          path="/register"
          name="Register Otp Page"
          component={Register}
        />
        <Route
          exact
          path="/memberDetial"
          name="MemberDetial Otp Page"
          component={MemberDetial}
        />


        <Route
          exact
          path="/broadcast/messages"
          name="Message"
          component={Message}
        />


        <Route
          exact
          path="/broadcast/coupon"
          name="Coupon"
          component={Coupon}
        />


        <Route
          exact
          path="/broadcast/customer/:id"
          name="Customer"
          component={Customer}
        />


        <Route
          exact
          path="/broadcast/customer-confirm/:id"
          name="Customer Confirm"
          component={ConfirmCustomer}
        />


        <Route
          exact
          path="/broadcast/customer-success"
          name="Customer Success"
          component={SuccessCustomer}
        />

        <Route
          exact
          path="/broadcast/report-boardcast/:id"
          name="Report BoardCast"
          component={DeliveryDetail}
        />

        <Route path="/" name="Landing Page" component={Landing} />

      </Switch>

    </HashRouter>
  );
}
