import React from "react";
import ReactDOM from "react-dom";

import App from './App';

import "antd/dist/antd.css";

import "../src/assets/css/style.css";

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
