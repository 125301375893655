import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

import Spinner from "../../component/Spinner/Spinner";
import { getOS, isInClient } from "../../component/LineLiff/LineLiff";
import { api } from "../../api/axios";

const liff = window.liff;

export default function Landing() {
  const [spinding, setSpinding] = useState(true);
  const { default_brand, config_brand } = window;
  const { CancelToken } = axios;
  const mountedRef = useRef(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1500,
    width: "300px",
  });

  useEffect(() => {
    setSpinding(true);

    if (window.location) {
      const params = new URLSearchParams(window.location.search);
      let brand = params.get("brand");
      let promotion = params.get("promotion");

      if (promotion !== null) {
        localStorage.setItem("promotion_code", promotion);
      }

      if (brand) {
        getLineLiff(brand);
        localStorage.setItem("brand", brand);
      } else {
        let brand = localStorage.getItem("brand");
        if (!brand) {
          let url = `${window.location.origin}?brand=${default_brand}`;
          window.location.replace(url);
          localStorage.setItem("brand", default_brand);
          getLineLiff(default_brand);
        } else {
          getLineLiff(brand);
        }
      }
    }

    let onLiff = isInClient();

    if (onLiff) {
      localStorage.setItem("channal", "LINE");

      setTimeout(() => {
        onGetProfile();
      }, 2000);
    } else {
      localStorage.setItem("channal", "WEB");

      let getos = getOS();
      if (getos === "web") {
        window.location = `/#/login`;
      } else {
        window.location = `/#/checkmobile`;
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, [mountedRef]);

  const getLineLiff = (brand) => {
    let profile = localStorage.getItem("profile");
    if (!profile || profile == null) {
      getProfile(brand);
    }
  };



  const getProfile = async (brand) => {
    var data_brand = config_brand.filter((item) => item.code === brand)[0];
    data_brand = data_brand
      ? data_brand
      : config_brand.filter((item) => item.code === default_brand)[0];
    var liffId = data_brand.liffId;

    await liff
      .init({ liffId })
      .then(() => {
        liff.getProfile().then((dataInfo) => {
          localStorage.setItem("profile", JSON.stringify(dataInfo));
        });
      })
      .catch(async (err) => {
        Toast.fire(
          "เกิดข้อผิดพลาด \nไม่สามารถสมัครสมาชิกได้ \nกรุณาลองใหม่อีกครั้ง",
          "Error" + err,
          "error"
        );

        console.log("Error", err);
        setSpinding(false);
      });
  };

  const onGetProfile = () => {
    let brand = localStorage.getItem("brand");
    let profileLine = localStorage.getItem("profile");

    const json = {
      social_app_data: JSON.parse(profileLine).userId,
      brand_code: brand,
    };

    api
      .post("api/members/GetProfile", json, {
        cancelToken: CancelToken.source().token,
      })
      .then((response) => {
        const data = response.data;
        if (!mountedRef.current) return;

        if (data.success) {
          localStorage.setItem("mobile", data.data.mobile);
          window.location = `/#/memberDetial`;
        } else {
          window.location = `/#/checkmobile`;
        }
        setSpinding(false);
      })
      .catch(async (err) => {
        if (axios.isCancel(err)) {
          CancelToken.source().cancel("Operation canceled by the user.");
        }

        Toast.fire(
          "เกิดข้อผิดพลาด \nไม่สามารถดึงข้อมูลสมาชิกได้ \nกรุณาลองใหม่อีกครั้ง",
          "Error " + err,
          "error"
        );

        console.log("Error", err);
        setSpinding(false);
      });
  };

  return (
    <div>
      <Spinner visible={spinding} />
    </div>
  );
}
