import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Modal,
  Radio,
  Select,
} from "antd";
import PDPA from "../PDPA/PDPA";
import { ExclamationCircleOutlined } from '@ant-design/icons';


export default function Modal_PDPA({ isModalVisible, handleOk, handleCancel }) {

  const [IsModalConfirmVisible, setIsModalConfirmVisible] = useState(false)

  function showConfirm() {
    setIsModalConfirmVisible(true);
  }

  function handleConfirmOk() {
    setIsModalConfirmVisible(false);
  }

  function handleConfirmCancel() {
    setIsModalConfirmVisible(false);
  }

  return (
    <div>
      <Modal
        title="นโยบายความเป็นส่วนตัว"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
        // closable={null}
        style={{ top: 8 }}
        width={650}
      >
        <div>
          <PDPA />
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              style={{ marginRight: 10, border: "1px solid #1890ff" }}
              className="buttom-form"
              onClick={showConfirm}
              ghost
            >
              ไม่ยอมรับ
                </Button>

            <Button
              type="primary"
              className="buttom-form"
              onClick={handleOk}
            >
              ยอมรับ
                </Button>
          </div>
        </div>
      </Modal>

      {/* popup ไม่ยอมรับ pdpa */}
      <Modal
        title={null}
        visible={IsModalConfirmVisible}
        footer={null}
        // closable={null}
        style={{ top: 8 }}
        closable={false}
        width={350}
      >
        <div>


          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ textAlign: "center" }}>
                <h2>คุณจะพลาดโอกาสในการได้รับสิทธิพิเศษของสมาชิก</h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ textAlign: "center" }}>
                <p>คุณยืนยันจะไม่ยอมรับ?</p>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 10 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  className="buttom-form"
                  onClick={handleConfirmCancel}
                >
                  แก้ไข
                </Button>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 10 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{ marginRight: 10, border: "1px solid #1890ff" }}
                  className="buttom-form"
                  onClick={() => { handleConfirmOk(); handleCancel(); }}
                  ghost
                >
                  ยืนยัน
                </Button>
              </div>
            </Col>

          </Row>

        </div>
      </Modal>
    </div>



  )
}
