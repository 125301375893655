const liff = window.liff;

export const getOS = () => {
  return liff.getOS();
};

export const isInClient = () => {
  return liff.isInClient();
};

export const closeWindows = () => {
  liff.closeWindow();
};
