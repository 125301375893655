import React, { useState, useEffect, memo } from "react";
import { api } from "../../api/axios";
import { Select } from "antd";

const { Option } = Select;

const DDL_Province = ({placeholder , onChange, value , type}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    const json = {};
    api
      .post("api/DDl/Province", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setData(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Select showSearch style={type === "member" ? { width: "100%" } : { width: "100%" }} placeholder={placeholder} loading={loading} onChange={onChange} value={value}
      filterOption={(input, option) =>  
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
      }
    >
      {data
        ? data.map((item) => (
            <Option key={item.provinceCode} value={item.provinceCode} name="province">
              {item.provinceNameTH}
            </Option>
          ))
        : null}
    </Select>
  );
}

export default memo(DDL_Province);