import React, { useState, useEffect } from 'react'
import NavBar from "../../../component/NavBar/NavBar";
import { Layout, Card, Affix, Col, Row, Radio, Typography, Select, Button, Space, DatePicker, Image, Modal, TimePicker } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import times from '../../../json/time.json';
import { api } from "../../../api/axios";
import TableConfirmCustomer from "../table/TableConfirmCustomer";
import moment from "moment";
import SideBar from "../../../component/SideBar/SideBar";
import TopicMenu from "../../../component/TopicMenu";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  SearchOutlined,
  PieChartOutlined,
  PlusSquareOutlined,
  FundViewOutlined,
  UserAddOutlined,
  BranchesOutlined
} from "@ant-design/icons";
export default function ConfirmCustomer() {
  const [value, setValue] = useState(1);
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState("");
  const [message, setMessage] = useState('');
  const [couponMessage, setCouponMessage] = useState(null);
  const [count, setCount] = useState('');
  const [image, setImage] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [id, setId] = useState(0);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageResult, setPageResult] = useState(10);
  const [visible, setVisible] = useState(false);
  const marginBottom = { marginBottom: 36 };
  const marginTop = { marginTop: 12 };
  const margin = { marginTop: 36, marginBottom: 36 };
  const marginTitle = { marginBottom: 16 };
  const stylePaddingAlign = { paddingTop: 12 };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onChangeDate = (dateString) => {
    const date = moment(dateString, "YYYY-MM-DD").format("YYYY/MM/DD");
    setDate(date)
  }


  function disabledDate(current) {
    let customDate = moment(new Date()).format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  }


  function getDisabledHours() {
    const dateCurrent = moment(new Date(), "YYYY-MM-DD").format("YYYY/MM/DD");

    if (date === dateCurrent) {
      var hours = [];
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
      return hours;
    } else {
      return null;
    }

  }

  function getDisabledMinutes(selectedHour) {
    var minutes = [];
    const dateCurrent = moment(new Date(), "YYYY-MM-DD").format("YYYY/MM/DD");
    if (date === dateCurrent) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          if (i === 0) {
            minutes.push(0);
          } else if (i === 30) {
            minutes.push(0);
            minutes.push(30);
          }
        }
      }
      return minutes;
    } else {
      return null;
    }

  }

  const history = useHistory();
  const location = useLocation();
  const dateFormat = 'DD/MM/YYYY';

  const handleFilterChange = (time, timeString) => {
    setTime(timeString)
  };

  const format = 'HH:mm';


  useEffect(() => {
    const indexOfFirst = history.location.pathname.substring(1, location.length).indexOf('/');
    const indexCondiftion = history.location.pathname.substring(1, history.location.pathname.length).indexOf('/', indexOfFirst + 2);
    const path = history.location.pathname.substring(indexCondiftion + 2, history.location.pathname.length)
    getData(Number(path))
    setId(Number(path))
  }, []);

  const getData = (id) => {
    let json = {
      "id": id
    }
    setLoading(true)
    api
      .post("Broadcast/GetBroadcastImage", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response.data;

        if (data.success) {
          const code = "";
          var name = ''
          var image = ''
          var message = ''
          if (data.data.broadcast_type === "M") {
            name = data.data.promotion_name
            message = data.data.promotion_message
          } else {
            name = data.data.promotion_name
            message = data.data.promotion_desc
          }

          if (data.data.coupon_image == null) {
            image = data.data.message_image
          } else {
            image = data.data.coupon_image
          }

          localStorage.setItem("success_customer_code", data.data.promotion_code)
          localStorage.setItem("success_customer_name", name)
          localStorage.setItem("success_customer_message", message)
          localStorage.setItem("success_customer_count", data.data.coupon_total)
          localStorage.setItem("success_customer_image", image)
          setCode(data.data.promotion_code);
          setName(name)
          setMessage(message)
          setCount(data.data.coupon_total)
          setImage(image)
          setCouponMessage(data.data.coupon_message)
          getDataList(id)
        } else {
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
  };


  const getDataList = (id) => {
    let json = {
      "id": id
    }
    setLoading(true)
    api
      .post("Broadcast/GetBroadcastMapping", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response.data;

        if (data.success) {
          var list = [];

          for (const element of data.data) {
            var obj = {
              name: element.fname + " " + element.lname,
              sex: element.sex,
              age: element.age,
              mobile: element.mobile,
              province: element.provinceNameTH,
              brand: element.brandNameEng,
              memberCode: element.member_code
            }
            list.push(obj)
          }
          setListData(list);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
  };

  const onChangePage = (value) => {
    setPage(value);
  };


  const onComfirm = () => {
    var sendResult = "";
    var timeResult = "";
    var dateResult = "";
    if (value === 1) {
      sendResult = "I"
      timeResult = ""
      dateResult = ""
    } else {
      sendResult = "S"
      timeResult = time
      dateResult = date
    }

    if (value === 1) {
      var obj = {
        "id": id,
        "send_type": sendResult,
        "schedule_date": dateResult,
        "schedule_time": timeResult
      }
      api.post("Broadcast/SaveConfirmCustomer", obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then((response) => {
        let result = response.data;

        if (result.success) {
          setVisible(true)
        } else {

        }
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
    } else {
      if (date === '' && time !== '') {
        Modal.error({
          title: '',
          icon: <ExclamationCircleOutlined />,
          content: 'กรุณาระบุวันจัดส่งคูปอง',
          okText: 'ตกลง',
        });
      } else if (date !== '' && time === '') {
        Modal.error({
          title: '',
          icon: <ExclamationCircleOutlined />,
          content: 'กรุณาระบุเวลาจัดส่งคูปอง',
          okText: 'ตกลง',
        });
      } else if (date === '' && time === '') {
        Modal.error({
          title: '',
          icon: <ExclamationCircleOutlined />,
          content: 'กรุณาระบุวันเเละเวลาจัดส่งคูปอง',
          okText: 'ตกลง',
        });
      } else {
        var obj = {
          "id": id,
          "send_type": sendResult,
          "schedule_date": dateResult,
          "schedule_time": timeResult
        }
        api.post("Broadcast/SaveConfirmCustomer", obj, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }).then((response) => {
          let result = response.data;

          if (result.success) {
            setVisible(true)
          } else {

          }
        }).catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              window.location = `/#/logout`;
            }
          }
        });
      }
    }
  }


  useEffect(() => {
    let access_token = localStorage.getItem("access_token");
    if (!access_token) window.location = `/#/login`;

    var menu = JSON.parse(localStorage.getItem("menu"));

    setMenuApi(menu);
  }, []);



  const [selectedKey, setSelectedKey] = useState("2");
  const [menuApi, setMenuApi] = useState([]);

  const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

  const changeSelectedKey = (event) => {
    const key = event.key
    window.location = `/#/management?key=${key}`;
  };

  const onHandleChangePageResult = (value) => {
    setPageResult(value);
  };

  const Menu = (
    <TopicMenu
      icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />, <UserAddOutlined />, <BranchesOutlined />]}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <div>
      <Affix>
        <NavBar menu={null} name={"PHARMAX USER"} />
      </Affix>
      <Layout>
        <Affix offsetTop={60}>
          <SideBar menu={Menu} />
        </Affix>
        <Layout.Content style={{ marginLeft: 48, marginRight: 48, marginTop: 24 }}>
          <Card style={{ paddingRight: 24, paddingLeft: 24 }}>



            <Title level={4} style={{ marginTop: 36 }}>
              ยืนยันข้อมูลลูกค้า
            </Title>
            <hr style={marginBottom} />

            <Row style={{ marginTop: 12, marginBottom: 36 }}>
              <Col xs={{ span: 24 }} lg={{ span: 8 }} style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  width={"70%"}
                  src={image}
                />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 16 }} style={{ marginTop: 24 }} >
                <Title level={5} style={marginTitle}>
                  รหัสคูปอง/หัวข้อเรื่อง : {code !== null ? code : name}
                </Title>
                <Title level={5} style={marginTitle}>
                  ชื่อคูปอง/ข้อความ : {message}
                </Title>
                <Title level={5} style={marginTitle}>
                  จำนวนคงเหลือ {count} คูปอง
                </Title>
                <Title level={5} style={{ marginTop: 24, marginBottom: 16, color: "#FF0000" }}>
                  {couponMessage}
                </Title>
              </Col>
            </Row>

            <Typography.Text>
              รายชื่อลูกค้าที่จะได้รับทั้งหมด {listData.length} รายการ
            </Typography.Text>

            <TableConfirmCustomer
              data={listData}
              page={page}
              pageData={pageResult}
              loading={loading}
              onChangePage={onChangePage}
              handleChangePage={onHandleChangePageResult}
            />

            <Title level={5} style={marginTop}>
              การจัดส่งคูปอง
            </Title>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio style={stylePaddingAlign} value={1}>ส่งทันที</Radio>
                <Radio style={stylePaddingAlign} value={2}>ระบุวันเเละเวลา</Radio>
              </Space>
            </Radio.Group>

            <Row style={marginTop}>
              <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                <DatePicker placeholder="เลือกวันที่" style={{ width: "100%" }} onChange={onChangeDate} disabledDate={disabledDate} format={dateFormat} />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                <TimePicker placeholder="เวลา" style={{ width: "80%", marginLeft: 24 }} onChange={handleFilterChange} minuteStep={30} format={format}
                  disabledHours={getDisabledHours}
                  disabledMinutes={getDisabledMinutes}
                  showNow={false}
                />
              </Col>
            </Row>

            <Row justify="end" style={margin}>
              <Col flex="60px" style={{ marginRight: 24 }}>
                <Button type="danger" style={{ width: '100%' }}
                  onClick={() => {
                    localStorage.setItem("customer_open", true);
                    localStorage.setItem("customer_id_result", id);
                    history.goBack()
                  }}>
                  ยกเลิก
                </Button>
              </Col>
              <Col flex="60px">
                <Button
                  type="primary"
                  disabled={listData.length === 0 ||
                    couponMessage !== null
                    ? true : false}
                  onClick={() => {
                    onComfirm()
                  }}
                >
                  ตกลง
                </Button>
              </Col>
            </Row>


          </Card>
        </Layout.Content>
      </Layout>
      <Modal
        title="ยืนยันข้อมูลลูกค้า"
        centered
        visible={visible}
        onOk={() => {
          // setVisible(false)
          window.location = `/#/management?key=2`
        }}
        onCancel={() => {
          window.location = `/#/management?key=2`
        }}
        width={1000}
        okText={"ตกลง"}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}

      >
        <Row style={{ marginTop: 12, marginBottom: 36 }}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }} style={{ display: "flex", justifyContent: "center" }}>
            <Image
              width={"70%"}
              src={image}
            />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 16 }} style={{ marginTop: 24 }} >
            <Title level={5} style={marginTitle}>
              รหัสคูปอง/หัวข้อเรื่อง : {code}
            </Title>
            <Title level={5} style={marginTitle}>
              ชื่อคูปอง/ข้อความ : {message}
            </Title>
            <Title level={5} style={marginTitle}>
              จำนวนคงเหลือ {count} คูปอง
            </Title>
            <Title level={5} style={{ marginTop: 24, marginBottom: 16, color: "#A5CA7F" }}>
              ดำเนินการส่งคูปอง/ข้อความสำเร็จ
            </Title>
          </Col>
        </Row>
      </Modal>

    </div>
  )
}
