import React, { useState, useEffect } from "react";
import Logo from "../../component/Logo/Logo";
import LockImg from "../../assets/img/lock.png";
import ProfileImg from "../../assets/img/profile-user.png";
import PhoneImg from "../../assets/img/cell-phone.png";
import CakeImg from "../../assets/img/cake.png";
import Spinner from "../../component/Spinner/Spinner";
import { api } from "../../api/axios";
import moment from "moment";
import Swal from "sweetalert2";

import pharmax_card from "../../assets/img/pharmax_card.png";
import icare_card from "../../assets/img/icare_card.png";
import superdrug_card from "../../assets/img/superdrug_card.png";
import back_card from "../../assets/img/back_card.png";

import { Input, Button, Row, Col, Modal, Select, Card } from "antd";
import ReactCardFlip from "react-card-flip";
import {
  MobileOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Barcode from "react-barcode";

import { isInClient } from "../../component/LineLiff/LineLiff";
import Coupon from "../../component/Coupon/Coupon";

const styleBottom = { marginBottom: 8 };

export default function CouponMember() {
  const [spinding, setSpinding] = useState(false);
  const [flipCard, setFipCard] = useState(false);
  const [profile, setProfile] = useState({});
  const [couponData, setCouponData] = useState([]);

  const styleLogo = { height: 36, width: "auto" };
  const styleImg = { height: 32, width: "auto", marginRight: 16 };

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1500,
    width: "300px",
  });

  const [data, setData] = React.useState("Not Found");

  useEffect(() => {
    onListMemberCoupon(localStorage.getItem("member_code"));
  }, []);

  const onGetProfile = () => {
    setSpinding(true);

    let mobile = localStorage.getItem("mobile");
    let brand = localStorage.getItem("brand");
    let profileLine = localStorage.getItem("profile");

    const json = {
      Mobile: mobile,
      brand_code: brand,
    };
    api
      .post("api/members/GetProfile", json)
      .then((response) => {
        let data = response.data;
        if (data.success) {
          setProfile(data.data);

          onListMemberCoupon(data.data.member_code);
        } else {
          window.location = `/#/logout`;
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onListMemberCoupon = (member_code) => {
    setSpinding(true);
    setCouponData([]);

    const json = {
      member_code: member_code,
    };
    api
      .post("api/coupon/ListMemberCoupon", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setCouponData(data.data);
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onRedeemCoupon = (coupon_serial, member_code) => {
    setSpinding(true);

    const json = {
      member_code: member_code,
      coupon_serial: coupon_serial,
      redeem_value: 1,
    };
    api
      .post("api/coupon/RedeemCoupon", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "ใช้งานคูปองเรียบร้อยแล้ว",
          });

          onListMemberCoupon(member_code);
        } else {
          Modal.confirm({
            title: "Confirm",
            icon: <InfoCircleOutlined />,
            content: data.message,
            okButtonProps: { style: { display: "none" } },
            okText: "ตกลง",
            cancelText: "ปิด",
          });
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onUseCoupon = (coupon_serial, member_code) => {
    Modal.confirm({
      title: "Confirm",
      icon: <InfoCircleOutlined />,
      content: (
        <div>
          <p>คุณต้องการใช้คูปองใช่หรือไม่?</p>{" "}
          <p>(กรุณา​แสดงคูปองนี้ และกดใช้คูปองต่อหน้าพนักงานที่สาขา)</p>
          <div style={{ textAlign: 'center'}}>
            <Barcode
              value={coupon_serial}
              fontSize={10}
              width={2}
              height={50}
              displayValue={false}
            />
            <text style={{ fontSize: 20, display: 'block' }}>{coupon_serial}</text>
          </div>
        </div>
      ),
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      width: 600,
      onOk: () => onRedeemCoupon(coupon_serial, member_code),
    });
  };

  return (
    <>
      <Spinner visible={spinding} />

      <Row gutter={0} justify="center">
        <Col xs={24}>
          {couponData.length > 0
            ? couponData.map((item) => (
                <Coupon
                  title={item.promotion_name}
                  expDate={item.expire_date}
                  image={item.coupon_image}
                  serial={item.coupon_serial}
                  onClick={() =>
                    onUseCoupon(item.coupon_serial, item.member_code)
                  }
                />
              ))
            : null}
        </Col>
      </Row>
    </>
  );
}
