import React, { useState, useEffect } from "react";
import Spinner from "../../component/Spinner/Spinner";
import { closeWindows } from "../../component/LineLiff/LineLiff";

export default function Logout() {
  useEffect(() => {
    //  localStorage.clear();

    // closeWindows();

    setTimeout(() => {
      window.location = "/";
    }, 500);
  }, []);

  return <Spinner visible={true} /> ;
}
