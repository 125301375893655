/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Modal, Radio, Select } from "antd";
import {
  ExclamationCircleOutlined,
  MessageOutlined,
  EditOutlined,
  PlusSquareOutlined,
  UserOutlined,
  HomeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";
import {
  onSelectMonth,
  dayOfMonth,
  monthOfYear,
  yearOfAll,
  ValidateEmail,
  parseJwt,
  ConvertYear,
  ValidateNumeric,
} from "../../../Helper/Helper";
import DDL_Province from "../../../component/Dropdown/DDL_Province";
import DDL_District from "../../../component/Dropdown/DDL_District";
import DDL_SubDistrict from "../../../component/Dropdown/DDL_SubDistrict";
import Modal_PDPA from "../../../component/Modal/Modal_PDPA";
import CouponMember from "../../Register/CouponMember";
import { api } from "../../../api/axios";

import "moment/locale/th";

const { Option } = Select;
const { confirm } = Modal;

const initialFormRegister = {
  mobile: "",
  provinces: "",
  amphures: "",
  districts: "",
  zipCode: undefined,
  firstName: "",
  lastName: "",
  email: "",
  sex: "",
  homeNo: "",
  street: "",
  day: undefined,
  month: undefined,
  year: undefined,
  memberCode: "",
  otpPin: "",
  brand: "",
  verifyOTP: false,
  consentStatus: false,
  consentDate: undefined,
  regFrom: "WEB",
  status: "Y",
  sentStatus: "E",
  sentDate: undefined,
};

export default function FromRegister({
  onSpinding,
  profile,
  onAction,
  onStatus,
  onClearSearchMember,
  _mobile,
  _brand
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [otpRef, setotpRef] = useState("");
  const [otpType, setOtpType] = useState("M");
  const at = parseJwt(localStorage.getItem("access_token"));

  const [isModalCoupon, setIsModalCoupon] = useState(false);

  const [formRegister, setFormRegister] = useState(initialFormRegister);

  useEffect(() => {
    const { fname, lname, email, sex, h_no, street, birthdate, province_code, district_code, subdistrict_code, zip_code, mobile, member_code, brand_code, consent_status, consent_date, reg_from, x_status, sent_status, sent_date } = profile;
    
    if (profile.fname || onAction === "register") {
      setFormRegister((prevFormRegister) => {
        return {
          ...prevFormRegister,
          firstName: fname,
          lastName: lname,
          email,
          sex,
          day: birthdate && moment(birthdate).format("DD"),
          month: birthdate && moment(birthdate).format("MM"),
          year: birthdate && parseInt(moment(birthdate).format("YYYY")) + 543,
          homeNo: h_no,
          street,
          provinces: province_code,
          amphures: district_code,
          districts: subdistrict_code,
          zipCode: zip_code,
          mobile,
          memberCode: member_code,
          brand: brand_code,
          consentStatus: consent_status === "Y",
          consentDate: consent_date,
          regFrom: reg_from,
          status: x_status,
          sentStatus: sent_status,
          sentDate: sent_date
        };
      });
    } else {
      setFormRegister((prevFormRegister) => {
        return {
          ...prevFormRegister,
          mobile: _mobile,
          brand: _brand
        }
      });
    }

    return () => {
      localStorage.removeItem("action");
      localStorage.removeItem("mobile");
      localStorage.removeItem("member_code");
    };
  }, [profile]);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: "300px",
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const onInputChange = (e) => {
    const { value, name } = e.target;
    
    setFormRegister((prevFormRegister) => {
      return {
        ...prevFormRegister,
        [name]: value,
      }
    });
  }

  const onSelectChange = (value, { name, postcode = undefined }) => {
    if (name === "province") {
      setFormRegister((prevFormRegister) => {
        return {
          ...prevFormRegister,
          amphures: "",
          districts: "",
          zipCode: "",
          provinces: value,
        };
      });
    } else if (name === "district") {
      setFormRegister((prevFormRegister) => {
        return {
          ...prevFormRegister,
          districts: "",
          zipCode: "",
          amphures: value,
        };
      });
    } else {
      setFormRegister((prevFormRegister) => {
        return {
          ...prevFormRegister,
          zipCode: postcode,
          districts: value,
        };
      });
    }
  };

  const onCheckValidate = (type) => {
    onSpinding(true);

    if (!formRegister.firstName) {
      onErrorField("ยังไม่ได้กรอกชื่อ");
      return;
    }

    if (!formRegister.lastName) {
      onErrorField("ยังไม่ได้กรอกนามสกุล");
      return;
    }

    if (!formRegister.mobile) {
      onErrorField("ยังไม่ได้กรอกเบอร์โทร");
      return;
    }

    if (formRegister.email) {
      if (!ValidateEmail(formRegister.email)) {
        onErrorField("รูปแบบอีเมลไม่ถูกต้อง");
        return;
      }
    }

    if (!formRegister.sex) {
      onErrorField("ยังไม่ได้เลือกเพศ");
      return;
    }

    let bridthDay = moment(`${ConvertYear(formRegister.year)}-${formRegister.month}-${formRegister.day}`).format(
      "DD/MM/YYYY"
    );
    if (bridthDay === "Invalid date") {
      onErrorField("วันเกิดไม่ถูกต้อง ไม่ถูกต้อง");
      return;
    }

    if (!formRegister.provinces) {
      onErrorField("ยังไม่ได้กรอกจังหวัด");
      return;
    }

    if (!formRegister.amphures) {
      onErrorField("ยังไม่ได้กรอกอำเภอ");
      return;
    }

    if (!formRegister.districts) {
      onErrorField("ยังไม่ได้กรอกตำบล");
      return;
    }

    if (!formRegister.zipCode) {
      onErrorField("ยังไม่ได้กรอกรหัสไปรษณี");
      return;
    } else if (formRegister.zipCode.length !== 5) {
      onErrorField("กรอกรหัสไปรษณีไม่ถูกต้อง");
      return;
    }

    if (type === "") {
      if (at.brand === "ADMIN") {
        switch (onAction) {
          case "edit":
            onEditMember(true);
            break;
          case "register":
            onVerfyOTP(onAction);
            break;
          default:
            onCancelMember(true);
        }
      } else {
        onVerfyOTP(onAction);
      }
    } else {
      showConfirmSendOTP(type);
    }
  };

  const onCancelMember = (consent) => {
    onSpinding(true);

    const json = {
      brand_code: formRegister.brand,
      mobile: formRegister.mobile,
      consent: consent,
    };

    api
      .post("api/Management/DeleteMember", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ",
          });

          setTimeout(() => {
            onClearSearchMember();
          }, 1000);
        } else {
          onErrorField(data.data ? data.data : data.message);
        }

        onSpinding(false);
      })
      .catch((err) => {
        onSpinding(false);
      });
  };

  const onErrorField = (message) => {
    if (message) {
      Toast.fire({
        icon: "error",
        title: message,
      });
    }

    onSpinding(false);
  };

  const showConfirmSendOTP = (type) => {
    onSpinding(false);
    setotpRef("");
    setOtpType(type);

    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "การส่งรหัส OTP ไปยังโทรศัพท์มือถือลูกค้าเพื่อยืนยันตัวตนและยอมรับข้อตกลง",
      onOk() {
        onSentOTP(type);
      },
    });
  };

  const showCancelMember = () => {
    Modal.confirm({
      title: "คุณต้องการที่จะยกเลิกการเป็นสมาชิก",
      icon: <InfoCircleOutlined />,
      content:
        "การยกเลิกการเป็นสมาชิกจะมีผลทำให้ข้อมูลส่วนตัวที่จัดเก็บในระบบถูกลบและไม่สามารถกู้คืนได้",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk: () =>
        at.brand === "ADMIN" ? onCancelMember(true) : onVerfyOTP("cancel"),
    });
  };

  const onSentOTP = (type) => {
    onSpinding(true);

    const json = {
      mobile: formRegister.mobile,
      brand: formRegister.brand,
      type: type,
    };
    api
      .post("api/OTP/RequestOTP", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setotpRef(data.data.referenceCode);
          startTimer(60);
        }

        onSpinding(false);
      })
      .catch((err) => {
        onSpinding(false);
      });
  };

  const onVerfyOTP = (value) => {
    onSpinding(true);

    const json = {
      mobile: formRegister.mobile,
      otpCode: formRegister.otpPin,
      refCode: otpRef,
    };
    
    api
      .post("api/OTP/VerifyOTP", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          onSpinding(false);

          setFormRegister((prevFormRegister) => {
            return {
              ...prevFormRegister,
              verifyOTP: true,
            }
          })
          // setVerfyOTP(true);
          setotpRef("");

          switch (value) {
            case "edit":
              onEditMember(data.success);
              break;
            case "register":
              onRegister(data.success);
              break;
            default:
              onCancelMember(data.success);
          }
        } else {
          onSpinding(false);
          onErrorField(data.message);
          setFormRegister((prevFormRegister) => {
            return {
              ...prevFormRegister,
              verifyOTP: false
            }
          })
        }
      })
      .catch((err) => {
        onSpinding(false);
        setFormRegister((prevFormRegister) => {
          return {
            ...prevFormRegister,
            verifyOTP: false
          }
        });
      });
  };

  const onRegister = (consent) => {
    onSpinding(true);

    const json = {
      mobile: formRegister.mobile,
      fname: formRegister.firstName,
      lname: formRegister.lastName,
      email: formRegister.email,
      sex: formRegister.sex,
      birthdateString: moment(`${parseInt(formRegister.year) - 543}-${formRegister.month}-${formRegister.day}`).format(
        "DD/MM/YYYY"
      ),
      h_no: formRegister.homeNo ? formRegister.homeNo : "",
      street: formRegister.street ? formRegister.street : "",
      province_code: formRegister.provinces,
      district_code: formRegister.amphures,
      subdistrict_code: formRegister.districts,
      zip_code: formRegister.zipCode,
      brand_code: formRegister.brand,
      reg_from: localStorage.getItem("channal")
        ? localStorage.getItem("channal")
        : formRegister.regFrom,
      consent,
    };
    api
      .post("api/Management/Register", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });

          setTimeout(() => {
            onClearSearchMember();
          }, 1000);
        } else {
          onErrorField(data.data ? data.data : data.message);
        }

        onSpinding(false);
      })
      .catch((err) => {
        setTimeout(() => {
          onClearSearchMember();
        }, 1000);

        onSpinding(false);
      });
  };

  const onEditMember = (consent) => {
    onSpinding(true);

    const json = {
      member_code: formRegister.memberCode,
      brand_code: formRegister.brand,
      mobile: formRegister.mobile,
      fname: formRegister.firstName,
      lname: formRegister.lastName,
      email: formRegister.email,
      sex: formRegister.sex,
      birthdateString: moment(`${parseInt(formRegister.year) - 543}-${formRegister.month}-${formRegister.day}`).format(
        "DD/MM/YYYY"
      ),
      h_no: formRegister.homeNo ? formRegister.homeNo : "",
      street: formRegister.street ? formRegister.street : "",
      province_code: formRegister.provinces,
      district_code: formRegister.amphures,
      subdistrict_code: formRegister.districts,
      zip_code: formRegister.zipCode,
      consent,
      x_status: formRegister.status,
      mstatus_code: onStatus,
    };
    api
      .post("api/Management/MemberEdit", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });

          setTimeout(() => {
            onClearSearchMember();
          }, 1000);
        } else {
          onErrorField(data.data ? data.data : data.message);
        }

        onSpinding(false);
      })
      .catch((err) => {
        setTimeout(() => {
          onClearSearchMember();
        }, 1000);

        onSpinding(false);
      });
  };

  const sendNAV = () => {
    onSpinding(true);

    const json = {
      member_code: formRegister.memberCode,
      brand_code: formRegister.brand,
      mobile: formRegister.mobile,
      fname: formRegister.firstName,
      lname: formRegister.lastName,
      email: formRegister.email,
      sex: formRegister.sex,
      birthdateString: moment(`${parseInt(formRegister.year) - 543}-${formRegister.month}-${formRegister.day}`).format(
        "DD/MM/YYYY"
      ),
      h_no: formRegister.homeNo,
      street: formRegister.street,
      province_code: formRegister.provinces,
      district_code: formRegister.amphures,
      subdistrict_code: formRegister.districts,
      zip_code: formRegister.zipCode,
      consent: true,
      x_status: formRegister.status,
    };
    
    api
      .post("api/Management/NavSend", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });

          formRegister((prevFormRegister) => {
            return {
              ...prevFormRegister,
              sentStatus: "S",
              sentDate: moment(moment()).format("YYYY-MM-DD HH:mm"),
            }
          });
        } else {
          onErrorField(data.data ? data.data : data.message);
        }

        onSpinding(false);
      })
      .catch((err) => {
        onSpinding(false);
      });
  };

  const startTimer = (number) => {
    var counter = number;
    var i = setInterval(() => {
      setCountdown(counter--);
      if (counter < 0) {
        clearInterval(i);
      }
    }, 1000);
  };

  const showModal = () => {
    setIsModalCoupon(true);
  };

  const handleCouponOk = () => {
    setIsModalCoupon(false);
  };

  const handleCouponCancel = () => {
    setIsModalCoupon(false);
  };

  const styleFormInput = { marginTop: 16 };

  const styleNoConsen = { display: formRegister.consentStatus ? "block" : "none" };

  return (
    <Row gutter={16} style={{ marginBottom: 16 }}>
      <Modal_PDPA isModalVisible={isModalVisible} handleOk={handleOk} />

      <Modal
        title="คูปอง"
        visible={isModalCoupon}
        onOk={handleCouponOk}
        onCancel={handleCouponCancel}
        footer={[]}
      >
        <CouponMember />
      </Modal>

      <Col xs={24}>
        <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
          <Col xs={24}>
            <label
              className="title-header"
              style={{ fontSize: 18, textAlign: "left" }}
            >
              {onAction === "edit" ? (
                <span>
                  <EditOutlined /> แก้ไข
                </span>
              ) : (
                <span>
                  <PlusSquareOutlined /> ลงทะเบียน
                </span>
              )}
            </label>
            <br />
            <br />
            <label className="title-header">
              <UserOutlined /> ข้อมูลส่วนตัว
            </label>
            <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  ชื่อ<span className="red">*</span>
                </label>
                <Input
                  maxLength="20"
                  value={formRegister.firstName}
                  name="firstName"
                  onChange={(e) => {
                    onInputChange(e)}
                  }
                />
              </Col>
              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  นามสกุล<span className="red">*</span>
                </label>
                <Input
                  maxLength="25"
                  value={formRegister.lastName}
                  name="lastName"
                  onChange={(e) => onInputChange(e)}
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <Input.Group>
                  <label>
                    วันเกิด<span className="red">*</span>
                  </label>
                  <Row gutter={8} justify="center">
                    <Col xs={8}>
                      <Select
                        showSearch
                        placeholder="ปี"
                        style={{ width: "100%" }}
                        value={formRegister.year}
                        onChange={(value) => {
                          setFormRegister((prevFormRegister) => {
                            return {
                              ...prevFormRegister,
                              year: value,
                              month: undefined,
                              day: undefined
                            }
                          })
                        }}
                      >
                        {yearOfAll().map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={8}>
                      <Select
                        showSearch
                        placeholder="เดือน"
                        style={{ width: "100%" }}
                        value={formRegister.month}
                        onChange={(value) => {
                          setFormRegister((prevFormRegister) => {
                            return {
                              ...prevFormRegister,
                              month: value,
                              day: undefined
                            }
                          })
                          onSelectMonth(formRegister.year, value);
                        }}
                        disabled={!formRegister.year}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {monthOfYear(formRegister.year).map((item) => (
                          <Option key={item.id} value={item.id} name="month">
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={8}>
                      <Select
                        showSearch
                        placeholder="วัน"
                        style={{ width: "100%" }}
                        value={formRegister.day}
                        onChange={(value) => {
                          setFormRegister((prevFormRegister) => {
                            return {
                              ...prevFormRegister,
                              day: value
                            }
                          })
                        }}
                        disabled={!formRegister.month}
                      >
                        {dayOfMonth().map((item) => (
                          <Option key={item} value={item} name="day">
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Input.Group>
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  เพศ<span className="red">*</span>
                </label>
                <Radio.Group
                  value={formRegister.sex}
                  name="sex"
                  onChange={(e) => onInputChange(e)}
                  style={{ width: "100%" }}
                >
                  <Radio value="M">ชาย</Radio>
                  <Radio value="F">หญิง</Radio>
                  <Radio value="N">ไม่ระบุ</Radio>
                </Radio.Group>
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  Email<span className="red"></span>
                </label>
                <Input
                  maxLength="100"
                  value={formRegister.email}
                  name="email"
                  onChange={(e) => onInputChange(e)}
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>ช่องทางการสมัคร</label>
                <Radio.Group
                  disabled
                  value={formRegister.regFrom}
                  name="regFrom"
                  onChange={(e) => onInputChange(e)}
                  style={{ width: "100%" }}
                >
                  <Radio value="WEB">WEB</Radio>
                  <Radio value="LINE">LINE</Radio>
                  <Radio value="Branch">Branch</Radio>
                </Radio.Group>
              </Col>

              <Col xs={24} style={styleFormInput}>
                <label className="title-header" style={{ marginBottom: 16 }}>
                  <HomeOutlined /> ข้อมูลที่อยู่
                </label>
              </Col>
              <Col xs={24} md={12} style={styleFormInput}>
                <label>เลขที่</label>
                <Input
                  maxLength="20"
                  value={formRegister.homeNo}
                  name="homeNo"
                  onChange={(e) => onInputChange(e)}
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>ถนน</label>
                <Input
                  maxLength="50"
                  value={formRegister.street}
                  name="street"
                  onChange={(e) => onInputChange(e)}
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  จังหวัด<span className="red">*</span>
                </label>
                <DDL_Province
                  onChange={onSelectChange}
                  value={formRegister.provinces}
                  type="member"
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  อำเภอ/เขต<span className="red">*</span>
                </label>
                <DDL_District
                  onChange={onSelectChange}
                  value={formRegister.amphures}
                  provinceCode={formRegister.provinces}
                  type="member"
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  ตำบล/แขวง<span className="red">*</span>
                </label>
                <DDL_SubDistrict
                  onChange={onSelectChange}
                  value={formRegister.districts}
                  districtCode={formRegister.amphures}
                />
              </Col>

              <Col xs={24} md={12} style={styleFormInput}>
                <label>
                  รหัสไปรษณีย์<span className="red">*</span>
                </label>
                <Input
                  maxLength="5"
                  value={formRegister.zipCode}
                  name="zipCode"
                  onChange={(e) =>
                    ValidateNumeric(e.target.value) && onInputChange(e)
                  }
                />
              </Col>

              <Col xs={24} style={{ ...styleFormInput }}>
                <label>สถานะ</label>
                <Radio.Group
                  value={formRegister.status}
                  name="status"
                  onChange={(e) => onInputChange(e)}
                  style={{ width: "100%" }}
                >
                  <Radio value="Y">ใช้งาน</Radio>
                  <Radio value="N">ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Col>

              <Col xs={24} style={{ ...styleFormInput, ...styleNoConsen }}>
                วันที่ลูกค้ายอมรับข้อตกลง พรบ.คุ้มครองข้อมูลส่วนบุคคล
                <Input
                  value={moment(formRegister.consentDate).format(
                    "วันที่ DD MMMM YYYY HH:mm น."
                  )}
                  disabled
                />
              </Col>

              <Col xs={24} style={{ ...styleNoConsen }}>
                <span>สถานะการส่งข้อมูลเข้าระบบ NAV : </span>{" "}
                <span style={{ colcor: "#b5b5b5" }}>
                  {formRegister.sentStatus === "S" ? "สำเร็จ" : "ไม่สำเร็จ"}
                </span>
              </Col>

              <Col xs={24} style={{ ...styleNoConsen }}>
                <span>วันที่ส่งข้อมูลเข้าระบบ NAV : </span>{" "}
                <span style={{ colcor: "#b5b5b5" }}>
                  {formRegister.sentDate
                    ? moment(formRegister.sentDate).format("วันที่ DD MMMM YYYY HH:mm น.")
                    : ""}
                </span>
              </Col>

              <Col xs={24} style={{ ...styleNoConsen }}>
                <Button
                  className="btn-info"
                  onClick={sendNAV}
                  style={{ marginRight: 16 }}
                >
                  ส่งข้อมูลเข้าระบบ NAV
                </Button>

                <Button className="btn-info" onClick={showModal}>
                  คูปอง
                </Button>
              </Col>

              <Col xs={24} style={{ marginBottom: 16, ...styleFormInput }}>
                <div>
                  ส่งรหัส OTP
                  ไปยังโทรศัพท์มือถือลูกค้าเพื่อยืนยันตัวตนและยอมรับข้อตกลง
                </div>
                <div
                  className="text-pdpa"
                  onClick={() => setIsModalVisible(true)}
                >
                  พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA)
                </div>

                <div>
                  *หมายเหตุ ก่อนการบันทึกจำเป็นต้องส่งรหัส OTP
                  ไปยังเบอร์โทรศัพท์ลูกค้าเพื่อยืนยันข้อมูล
                </div>
              </Col>

              <Col xs={24} style={{ ...styleFormInput }}>
                {onAction === "register" ||
                (onAction === "edit" && onStatus === "MIG") ? (
                  <Button
                    style={{ marginRight: 16, marginBottom: 16 }}
                    disabled={countdown > 0}
                    onClick={() => onCheckValidate("M")}
                  >
                    <MessageOutlined /> ขอรหัส OTP เพื่อลงทะเบียน
                  </Button>
                ) : (
                  <>
                    {at.brand === "ADMIN" ? null : (
                      <>
                        <Button
                          style={{ marginRight: 16, marginBottom: 16 }}
                          disabled={countdown > 0}
                          onClick={() => onCheckValidate("E")}
                        >
                          <MessageOutlined /> ขอรหัส OTP เพื่อแก้ไข
                        </Button>

                        <Button
                          style={{ marginRight: 16, marginBottom: 16 }}
                          disabled={countdown > 0}
                          onClick={() => onCheckValidate("C")}
                        >
                          <MessageOutlined /> ขอรหัส OTP เพื่อเพิกถอนสมาชิก
                        </Button>
                      </>
                    )}
                  </>
                )}

                {onAction === "register" ||
                (onAction === "edit" && onStatus === "MIG") ||
                at.brand !== "ADMIN" ? (
                  <span className="countDown">({countdown})</span>
                ) : null}
              </Col>

              {otpRef ? (
                <Col xs={24} style={styleFormInput}>
                  <label>รหัส OTP</label>
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <Input
                        name="otpPin"
                        onChange={(e) => onInputChange(e)}
                        maxLength="4"
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <div style={{ marginTop: 4 }}>รหัสอ้างอิง : {otpRef}</div>
                    </Col>
                  </Row>
                </Col>
              ) : null}

              {at.brand === "ADMIN" && onAction === "register" && otpRef ? (
                <Col xs={24} style={{ marginTop: 16, textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => onCheckValidate("")}
                    style={{ marginRight: 16 }}
                  >
                    บันทึก
                  </Button>
                </Col>
              ) : at.brand === "ADMIN" && onAction === "edit" ? (
                <Col xs={24} style={{ marginTop: 16, textAlign: "center" }}>
                  {onStatus !== "MIG" || (onStatus === "MIG" && otpRef) ? (
                    <Button
                      type="primary"
                      onClick={() => onCheckValidate("")}
                      style={{ marginRight: 16 }}
                    >
                      บันทึก
                    </Button>
                  ) : null}

                  {onStatus !== "MIG" ? (
                    <Button
                      type="primary"
                      danger
                      onClick={() => showCancelMember()}
                    >
                      ยกเลิกการเป็นสมาชิก
                    </Button>
                  ) : null}
                </Col>
              ) : null}

              {at.brand !== "ADMIN" && onAction === "register" && otpRef ? (
                <Col xs={24} style={{ marginTop: 16, textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => onCheckValidate("")}
                    style={{ marginRight: 16 }}
                  >
                    บันทึก
                  </Button>
                </Col>
              ) : at.brand !== "ADMIN" && onAction === "edit" ? (
                <Col xs={24} style={{ marginTop: 16, textAlign: "center" }}>
                  {(onStatus !== "MIG" && otpType === "E" && otpRef) ||
                  (onStatus === "MIG" && otpRef) ? (
                    <Button
                      type="primary"
                      onClick={() => onCheckValidate("")}
                      style={{ marginRight: 16 }}
                    >
                      บันทึก
                    </Button>
                  ) : null}

                  {onStatus !== "MIG" && otpType === "C" && otpRef ? (
                    <Button
                      type="primary"
                      danger
                      onClick={() => showCancelMember()}
                    >
                      ยกเลิกการเป็นสมาชิก
                    </Button>
                  ) : null}
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
