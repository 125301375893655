import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment";
import "moment/locale/th";

export default function Coupon({title,expDate, image, serial, onClick}) {
  const [data, setData] = React.useState("Not Found");

  useEffect(() => {
    setData(serial);
  }, []);

  const styleBottom = {marginBottom:8};
  return (
    <div>
      <hr />
      <Row gutter={16} justify="center" style={{ textAlign: "center" }}>
        <Col xs={24} style={{...styleBottom}}>
          
          <img src={image} alt="gifbox" width="200px" style={{with:100}} />
        </Col>
      </Row>
      <Row gutter={16} justify="start" style={{ textAlign: "left" }}>

      

        <Col xs={24} style={styleBottom}>
          <div style={{fontSize:16,fontWeight:"bold",color:'#125da8'}}>{title}</div>
        </Col>
        <Col xs={24} style={styleBottom}>
         <div>ใช้ได้ถึง{moment(expDate).add('Y',543).format("วันที่ DD MMMM YYYY")}</div>
        </Col>
        <Col xs={24} style={{ textAlign: 'center', marginTop: 15 }}>
          <Button type="primary" className="buttom-form" htmlType="submit" onClick={onClick}>
            ใช้งาน
          </Button>
        </Col>
      </Row>

      <hr />
    </div>
  );
}
