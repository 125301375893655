import React from "react";
import "./style.css";
export default function Spinner(data) {
  return (
    <div
      className="loading"
      style={{ display: data.visible ? "block" : "none" }}
    >
      Loading...
    </div>
  );
}
