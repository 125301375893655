/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { Button, Col, Input, Radio, Row, Form } from "antd";
import {
  UserAddOutlined,
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useRef, memo, forwardRef, useCallback } from "react";
import DDL_Group from "../../../component/Dropdown/DDL_Group";
import DDL_Brand from "../../../component/Dropdown/DDL_Brand";
import Swal from "sweetalert2";
import { api } from "../../../api/axios";
import DDL_Branch from "../../../component/Dropdown/DDL_Branch";
import { useForm } from "antd/lib/form/Form";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 1000,
  width: "300px",
});

const initialState = {
  userCode: "",
  password: "",
  confirmPassword: "",
  brand: "", // IC000 = ICARE, PM000 = PHARMAx, SD000 = SuperDug
  group: "", // 000 = Admin, 100 = HQ, 200 = brand
  branch: "", // รหัสสาขา
  status: "Y", // Y = ใช้งาน, N = ไม่ใช้งาน
};

const initialPassword = {
  isShow: false,
  typePassword: "password",
  showIcon: <EyeOutlined />,
};

export const onScrollToRef = (elemRef) => {
  elemRef.current.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
};

const { Search } = Input;

const FormRegisterUser = forwardRef(({
  setIsSearchComponent,
  userList,
  onSearchUser,
  setSpinding
}, ref) => {
  const [form] = useForm();
  const [input, setInput] = useState(initialState);
  const [password, setPassword] = useState(initialPassword);
  const elemRef = useRef(null);
  const prevPassRef = useRef();
  const initialGroup = {
    admin: "000",
    hq: "100",
    brand: "200"
  }
  
  const onInputChange = (e) => {
    const { name, value } = e.target;

    setInput((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const onErrorField = (message) => {
    if (message) {
      Toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const onRegister = (input, actionType = "CREATE") => {
    const { userCode, brand, password, status, group, branch } = input;

    setSpinding(true);

    const json = JSON.stringify({
      UserCode: userCode,
      BrandCode: brand,
      Password: password,
      XStatus: status,
      GroupCode: group,
      BranchCode: branch,
      ActionType: ref.current ? ref.current : actionType,
    });

    api
      .post("api/Management/addOrUpdateUser", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        const data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });

          setTimeout(() => {
            setIsSearchComponent(true);
            onClearInput();
          }, 1000);
        } else {
          setSpinding(false);
          onErrorField(data.data ? data.data : data.message);
        }
      });
  };

  const onClearInput = () => {
    setInput(initialState);
    setSpinding(false);
  };

  const marginBottom = {
    marginBottom: 16,
  };

  const onSelectOption = useCallback((values, { name }) => {
    setInput((prev) => {
      return {
        ...prev,
        [name]: values
      }
    });
    ref.current !== "UPDATE" && name === "branch" && onSearchUser({ [name]: values });
  }, [input]);

  useEffect(async () => {
    if (userList.length > 0) {
      if (ref.current === "UPDATE") {
        await getPassword(userList[0].userCode);
        const { userCode, password, brandCode, xStatus, branchCode, groupCode } = userList[0];
        prevPassRef.current = userList[0].password;

        setInput({
          userCode,
          password,
          branch: branchCode,
          group: groupCode,
          brand: brandCode ? brandCode : window.default_brand.toUpperCase(),
          status: xStatus === "ใช้งาน" ? "Y" : "N",
        });

        form.setFieldsValue({
          userCode,
          password,
          brand: brandCode ? brandCode : window.default_brand.toUpperCase(),
          group: groupCode,
          branch: branchCode,
          status: xStatus === "ใช้งาน" ? "Y" : "N",
        });
      } 
      else {
        const user = userList.filter(({ branchCode }) => (branchCode = input.branch));

        const { brandCode, status, branchCode } = user[0];

        form.setFieldsValue({
          branch: branchCode,
          brand: brandCode ? brandCode : window.default_brand.toUpperCase(),
          group: initialGroup.brand,
          status,
        });
      }
    } else {
      form.setFieldsValue(initialState);
    }

    if (elemRef.current) {
      onScrollToRef(elemRef);
    }

  }, [userList, elemRef]);

  useEffect(() => {
    return () => {
      setInput({});
      ref.current = "CLEAR";
    };
  }, []);
  
  const onShowPassword = () => {
    if (input.password && !password.isShow) {
      setPassword({
        isShow: true,
        typePassword: "text",
        showIcon: <EyeInvisibleOutlined />,
      });
    } else {
      setPassword({
        isShow: false,
        typePassword: "password",
        showIcon: <EyeOutlined />,
      });
    }
  };

  const getPassword = async (userCode) => {
    const json = JSON.stringify({
      UserCode: userCode,
    });

    const response = await api.post("api/Management/GetPasswordUser", json, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const { data } = response;

    if (data.success) {
      userList[0].password = data.data.password;
    }
  };

  const onFinish = (values) => {
    if (ref.current === "CREATE" && values.password !== values.confirmPassword) {
      onErrorField("กรุณาระบุรหัสผ่านให้ตรงกัน");
    }

    onRegister(values, ref.current);
  };

  const marginTop = {
    marginTop: 16,
  };

  return (
    <>
      <UserAddOutlined /> {ref.current === "CREATE" ? "เพิ่ม" : "แก้ไข"}ข้อมูลผู้ใช้งานระบบ
      <hr />
      <Row gutter={16} justify="center" style={marginBottom} ref={elemRef}>
        <Col xs={24} md={16}>
          <Row gutter={16} style={marginBottom}>
            <Col xs={24}>
              <label className="title-header">
                <UserOutlined /> ข้อมูลผู้ใช้งาน
              </label>
              <Form
                form={form}
                name="form-register-user"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                onFinish={onFinish}
              >
                <Row gutter={16} justify="center">
                  <Col xs={24} md={12} style={marginTop}>
                    <Form.Item
                      name="branch"
                      label="สาขา"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุสาขา",
                        },
                      ]}
                    >
                      <DDL_Branch
                        placeholder="สาขา"
                        value={input.branch}
                        name="branch"
                        optionAll={false}
                        onChange={onSelectOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} style={marginTop}>
                    <Form.Item
                      name="brand"
                      label="แบรนด์"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุแบรนด์",
                        },
                      ]}
                    >
                      <DDL_Brand
                        placeholder="แบรนด์"
                        value={input.brand}
                        name="brand"
                        type="member"
                        optionAll={false}
                        actionType={ref.current}
                        onChange={onSelectOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="group"
                      label="กลุ่มผู้ใช้งาน"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุกลุ่มผู้ใช้งาน",
                        },
                      ]}
                    >
                      <DDL_Group
                        placeholder="กลุ่ม"
                        value={input.group}
                        name="group"
                        optionAll={false}
                        onChange={onSelectOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="userCode"
                      label="ชื่อผู้ใช้งาน"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อผู้ใช้งาน",
                        },
                      ]}
                    >
                      <Input disabled={ref.current === "UPDATE"} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    {ref.current === "UPDATE" ? (
                      <>
                        <Form.Item
                          name="password"
                          label="รหัสผ่าน"
                          rules={[
                            {
                              required: true,
                              message: "กรุณาระบุรหัสผ่าน",
                            },
                          ]}
                        >
                          <Search
                            type={password.typePassword}
                            value={input.password}
                            name="password"
                            placeholder="รหัสผ่าน"
                            enterButton={password.showIcon}
                            size="middle"
                            onSearch={onShowPassword}
                            onChange={onInputChange}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name="password"
                          label="รหัสผ่าน"
                          rules={[
                            {
                              required: true,
                              message: "กรุณารหัสผ่าน",
                            },
                          ]}
                        >
                          <Input type="password" />
                        </Form.Item>
                      </>
                    )}
                  </Col>
                  {(ref.current === "CREATE" || input.password !== prevPassRef.current) && (
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="confirmPassword"
                        label="ยืนยันรหัสผ่าน"
                        rules={[
                          {
                            required: true,
                            message: "กรุณายืนยันรหัสผ่าน",
                          },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} md={12}>
                    <Form.Item name="status" label="สถานะ">
                      <Radio.Group
                        value={input.status}
                        name="status"
                        onChange={onInputChange}
                        style={{ width: "100%" }}
                      >
                        <Radio value="Y">ใช้งาน</Radio>
                        <Radio value="N">ไม่ใช้งาน</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    style={{ marginTop: 16, textAlign: "center" }}
                  >
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        บันทึก
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
});

export default memo(FormRegisterUser);
