import { Row, Col } from "antd";

import pharmax from "../../assets/img/Logo_210430_3.jpg";
import icare from "../../assets/img/Logo_210430_2.png";
import superdrug from "../../assets/img/Logo_210430.jpg";
import icare_health from "../../assets/img/Logo_210505.png";

export default function Logo({ style, textAlign }) {
  
  const styleDef = { width: 300, marginTop: 48 };

  return (
    <Row
      gutter={16}
      justify="{justify ? justify : justifyDef}"
      style={{ marginBottom: 16, textAlign: "center" }}
    >
      <Col xs={24} style={{ textAlign: textAlign }}>
        <img src={
          !localStorage.getItem('brand') ? icare_health :
          localStorage.getItem('brand').toLowerCase() === "pm000" ? pharmax :
          localStorage.getItem('brand').toLowerCase() === "ic000" ? icare:
          localStorage.getItem('brand').toLowerCase() === "sd000" ? superdrug: icare_health
        } alt="profile" style={style ? style : styleDef} />
      </Col>
    </Row>
  );
}
