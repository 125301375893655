/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { Input, Button, Row, Col } from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import Spinner from "../../component/Spinner/Spinner";
import { api } from "../../api/axios";

const TableBranch = lazy(() => import("./component/TableBranch"));
const AddBranch = lazy(() => import("./AddBranch"));

const initialState = {
  branchCode: "",
  branchName: "",
  page: 1,
  pageLength: 10,
};

const SearchBranch = () => {
  const [spinding, setSpinding] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [page, setPage] = useState(1);
  const [inputBranch, setInputBranch] = useState(initialState);
  const [isSearchComponent, setIsSearchComponent] = useState(true);

  useEffect(() => {
    getBranchList();

    return () => {
      setBranchList([]);
    };
  }, [page, isSearchComponent]);

  const getBranchList = useCallback(
    async (type) => {
      setSpinding(true);

      let json = {};

      if (type === "clear") {
        json = initialState;
      } else {
        json = {
          BranchCode: inputBranch.branchCode,
          BranchName: inputBranch.branchName,
          page,
          pageLength: 10,
        };
      }

      await api
        .post("api/Management/GetListBranch", json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((response) => {
          const { data } = response;

          if (data.success) {
            setBranchList(data.data);
            setSpinding(false);
          } else {
            setSpinding(false);
          }
        })
        .catch((err) => setSpinding(false));
    },
    [inputBranch, page, branchList]
  );

  const onChangeInput = (e) => {
    const { value, name } = e.target;

    setInputBranch((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const onChangePage = (value) => {
    setPage(value);
  };

  const onClear = () => {
    setInputBranch(initialState);

    getBranchList("clear");
  };

  const marginBottom = { marginBottom: 16 };

  const onModalBranch = (item) => {
    setIsSearchComponent(false);

    setBranchList([item]);
  };

  const onAddBranch = () => {
    setIsSearchComponent(false);
    setBranchList([]);
  };

  return (
    <>
      <Spinner visible={spinding} />
      {isSearchComponent ? (
        <>
          <BranchesOutlined /> ค้นหาข้อมูลสาขา
          <hr />
          <Row gutter={16} justify="center" style={marginBottom}>
            <Col xs={0} md={6}></Col>
            <Col xs={12} md={6}>
              <label>รหัสสาขา</label>
              <Input
                placeholder="รหัสสาขา"
                value={inputBranch.branchCode}
                name="branchCode"
                onChange={onChangeInput}
                onPressEnter={getBranchList}
              />
            </Col>
            <Col xs={12} md={6}>
              <label>ชื่อสาขา</label>
              <Input
                placeholder="ชื่อสาขา"
                name="branchName"
                value={inputBranch.branchName}
                onChange={onChangeInput}
                onPressEnter={getBranchList}
              />
            </Col>
            <Col xs={0} md={6}></Col>
          </Row>
          <Row gutter={16} justify="center" style={marginBottom}>
            <Col xs={0} md={6}></Col>
            <Col xs={12} md={6} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={getBranchList}>
                <SearchOutlined />
                ค้นหา
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button
                type="danger"
                onClick={onClear}
                disabled={
                  inputBranch.branchCode.length === 0 &&
                  inputBranch.branchName.length === 0
                }
              >
                <DeleteOutlined />
                ล้าง
              </Button>
            </Col>
            <Col xs={0} md={6}></Col>
          </Row>
            <Row gutter={16} align="middle" justify="center" style={marginBottom}>
              <Col xs={10}>ผลการค้นหา</Col>
              <Col xs={14} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  style={{ height: 60 }}
                  onClick={onAddBranch}
                >
                  เพิ่ม <br /> ข้อมูลสาขา
                </Button>
              </Col>
            </Row>
            <Col xs={24}>
              <Suspense fallback={<Spinner visible={spinding} />}>
                <TableBranch
                  data={branchList}
                  page={page}
                  onChangePage={onChangePage}
                  onModalBranch={onModalBranch}
                />
              </Suspense>
            </Col>
        </>
      ) : (
        <Suspense fallback={<Spinner visible={spinding} />}>
          <AddBranch
            setIsSearchComponent={setIsSearchComponent}
            branchList={branchList}
          />
        </Suspense>
      )}
    </>
  );
};

export default SearchBranch;
