import React, { useMemo } from "react";
import { Table, Button, Row, Col } from "antd";
import { EditOutlined } from '@ant-design/icons';
import numeral from "numeral";
import "./Table.css";

export default function TableMember({ onModalMember, onChangePage, data, page }) {
  
  function marktel(mobile){
    return "******"+mobile.substring(10,6);
  }

  const columns = [
    {
      title: "ชื่อ นามสกุล",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, item) => <span> {`${item.fname} ${item.lname}`} </span>,
    },
    {
      title: "เพศ",
      dataIndex: "sex",
      key: "sex",
      align: "center",
      render: (text) =>
        text === "M" ? "ชาย" : text === "F" ? "หญิง" : "ไม่ระบุ",
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
      render: (text, item) => <span> {`${marktel(item.mobile)}`} </span>,
    },
    {
      title: "จังหวัด",
      dataIndex: "provinceNameTH",
      key: "provinceNameTH",
      align: "center",
    },
    {
      title: "แบรนด์",
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
    },
    {
      title: "ช่องทางการสมัคร",
      dataIndex: "reg_from",
      key: "reg_from",
      align: "center",
    },
    {
      title: localStorage.getItem("groupCode") == '000' ? "แก้ไข":"ขอรหัส OTP เพื่อเข้าสู่ระบบแก้ไข",
      key: "key",
      align: "center",
      render: (text, item) => (
        localStorage.getItem("groupCode") == '000' ?
        <Button type="primary" className="btn btn-secondary" disabled={item.mobile.length !== 10} onClick={() => onModalMember(item)}>
          <EditOutlined /> แก้ไข
        </Button> :
        <Button type="primary" className="btn btn-secondary" disabled={item.mobile.length !== 10} onClick={() => onModalMember(item)}>
        <EditOutlined /> ขอรหัส OTP เพื่อเข้าสู่ระบบแก้ไข
      </Button>
      ),
    },
  ];

  const memoizedPage = useMemo(() => page, [page]);
  const memoizedData = useMemo(() => data, [data]);

  return (
    <Row>
      <Col xs={24}>
        <Table
          rowKey="member_code"
          columns={columns}
          dataSource={memoizedData}
          className="table-member"
          pagination={{
            position: ["bottomRight"],
            defaultPageSize: "10",
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: false,
            total: memoizedData.length > 0 ? memoizedData[0].totalrow : 0,
            onChange: onChangePage,
            current: memoizedPage,
            showTotal: (total, range) =>
              `แสดงรายการที่ ${numeral(range[0]).format("0,0")} 
          ถึง ${numeral(range[1]).format("0,0")} 
          จาก ${numeral(memoizedData.length > 0 ? memoizedData[0].totalrow : 0).format("0,0")} รายการ`,
          }}
        />
      </Col>
    </Row>
  );
}
