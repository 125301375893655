import React, { useState, useEffect, memo } from "react";
import { api } from "../../api/axios";
import { Select } from "antd";

const { Option } = Select;

const DDL_Branch = ({ onChange, value, optionAll }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    api
      .post(
        "api/DDl/Branch",
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        const { data, success } = response.data;

        success && setData(data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      loading={loading}
      onChange={onChange}
      value={value}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {optionAll && <Option value="">ทั้งหมด</Option>}
      {data.length > 0 &&
        data.map((item) => (
          <Option key={item.branchCode} value={item.branchCode} name="branch">
            {item.branchName}
          </Option>
        ))}
    </Select>
  );
}

export default memo(DDL_Branch);