import React, { useState } from "react";
import Spinner from "../../component/Spinner/Spinner";
import Swal from "sweetalert2";
import { Card, Col, Row, Form, Input, Button } from "antd";
import icare_health from "../../assets/img/Logo_210505.png";
import { api } from "../../api/axios";

import "./Login.css";

export default function Login() {
  const [spinding, setSpinding] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: '300px'
  });

  const onFinish = (values) => {
    
    setSpinding(true);
    localStorage.setItem("username", values.username);
    if (values.username && values.password) {
      const json = {
        username: values.username,
        password: values.password,
      };
      api
        .post("api/Auths/Authenticate", json)
        .then((response) => {
          let data = response.data;

          if (data.success && data.data) {
            localStorage.setItem("access_token", data.data.token);
            localStorage.setItem("firstName", data.data.firstName);
            localStorage.setItem("brand", data.data.brandCode);
            localStorage.setItem("companyCode", data.data.companyCode);
            localStorage.setItem("menu", JSON.stringify(data.data.menu));
            localStorage.setItem("groupCode", data.data.groupCode);

            Toast.fire({
              icon: "success",
              title: "เข้าสู่ระบบสำเร็จ",
            });

            setTimeout(() => {
              window.location = "/#/management/";
            }, 1500);
          } else {
            Toast.fire({
              icon: "error",
              title: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
            });

            setSpinding(false);
          }
        })
        .catch((err) => {
          setSpinding(false);
        });
    } else {
      setSpinding(false);
    }

  };

  return (
    <div className="body">
      <Spinner visible={spinding} />

      <Row gutter={16} justify="center" className="card">
        <Col md={16} xl={12}>
          <Card style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Row
              gutter={16}
              justify="center"
              style={{ marginBottom: 16, textAlign: "center" }}
            >
              <Col xs={24}>
              <img src={icare_health} style={{ height: 80, width: "auto" }} alt="" />
              </Col>
            </Row>

            <Row gutter={16} justify="center">
              <Col md={16} xl={12}>
                <Form
                  layout={"vertical"}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="ชื่อผู้ใช้"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาใส่ชื่อผู้ใช้ของคุณ!",
                      },
                    ]}
                  >
                    <Input autoFocus />
                  </Form.Item>

                  <Form.Item
                    label="รหัสผ่าน"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาใส่รหัสผ่านของคุณ!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      เข้าสู่ระบบ 
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card> 
        </Col>  
      </Row> 
      <div style={{position:"absolute",bottom:0,right:0}}>{window.software_version}</div>
    </div>
  );
}
