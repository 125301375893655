import React, { useState, useEffect } from "react";
import { api } from "../../api/axios";
import { Select } from "antd";

const { Option } = Select;

export default function DDL_SubDistrict({onChange, value, districtCode}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [districtCode]);

  const getData = () => {
    setLoading(true);
    const json = {
      DistrictCode: districtCode,
    };
    api
      .post("api/DDl/SubDistrict", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setData(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Select showSearch style={{ width: "100%" }} loading={loading} onChange={onChange} value={value} disabled={!districtCode}
    filterOption={(input, option) =>  
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
    }
    >
    {data
      ? data.map((item) => (
          <Option key={item.subDistrictCode} value={item.subDistrictCode} postcode={item.postCode} name="subdistrict">
            {item.subDistrictNameTH}
          </Option>
        ))
      : null}
  </Select>
  );
}
