import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "antd";
import Logo from "../../component/Logo/Logo";
import Spinner from "../../component/Spinner/Spinner";
import Swal from "sweetalert2";
import { api } from "../../api/axios";
import "./inputPin.css";
import { isInClient } from "../../component/LineLiff/LineLiff";

export default function VerifyOTP() {
  const [mobile, setMobile] = useState("");
  const [otpRef, setotpRef] = useState("");
  const [spinding, setSpinding] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  // const [pin5, setPin5] = useState("");
  // const [pin6, setPin6] = useState("");

  useEffect(() => {
    startTimer(countdown);

    let mobile = localStorage.getItem("mobile");
    let referenceCode = localStorage.getItem("referenceCode");

    if (mobile && referenceCode) {
      setMobile(mobile);
      setotpRef(referenceCode);
    } else {
      window.location = `/#/`;
    }

    return () => {
      localStorage.removeItem("action");
      localStorage.removeItem("referenceCode");
      localStorage.removeItem("otp_action");
    };
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: "300px",
  });

  const onVerfyOTP = () => {
    setSpinding(true);

    //let otpPin = pin1 + pin2 + pin3 + pin4 + pin5 + pin6;
    let otpPin = pin1 + pin2 + pin3 + pin4;

    const json = {
      mobile: mobile,
      otpCode: otpPin,
      refCode: otpRef,
    };

    api
      .post("api/OTP/VerifyOTP", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "รหัส OTP ถูกต้อง",
          });

          let onLiff = isInClient();

          if (onLiff) {
            setTimeout(() => {
              //merge
              onChengeLineUid();
            }, 1500);
          } else {
            if (localStorage.getItem("otp_action") === "detial") {
              //detial
              onChengeLineUid();
              setTimeout(() => {
                window.location = `/#/memberDetial`;
              }, 1500);
            } else {
              //register
              setTimeout(() => {
                window.location = `/#/register`;
              }, 1500);
            }
          }
        } else {
          Toast.fire({
            icon: "error",
            title: data.message,
          });
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onChengeLineUid = () => {
    let profileLine = JSON.parse(localStorage.getItem("profile"));

    const json = {
      mobile: mobile,
      brand_code: localStorage.getItem("brand"),
      social_app_data: profileLine ? profileLine.userId : "",
    };

    api
      .post("api/members/chengeLineUid", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
          });
          setTimeout(() => {
            if (data.data === "MIG") {
              window.location = `/#/register?action=edit&migration=MIG`;
            } else {
              window.location = `/#/memberDetial`;
            }
          }, 1500);
        } else {
          window.location = `/#/register`;
        }

        setSpinding(false);
      })
      .catch(() => {
        setSpinding(false);
      });
  };

  const startTimer = (number) => {
    var counter = number;
    var i = setInterval(function () {
      setCountdown(counter--);
      if (counter < 0) {
        clearInterval(i);
      }
    }, 1000);
  };

  const onReSentOTP = () => {
    setSpinding(true);
    setPin1("");
    setPin2("");
    setPin3("");
    setPin4("");
    // setPin5("");
    // setPin6("");

    const json = {
      mobile: mobile,
      brand: localStorage.getItem("brand"),
      type: "R",
    };

    api
      .post("api/OTP/RequestOTP", json)
      .then((response) => {
        let data = response.data;
        if (data.success) {
          setotpRef(data.data.referenceCode);
          localStorage.setItem("referenceCode", data.data.referenceCode);
          startTimer(60);
        }
        setSpinding(false);
      })
      .catch(() => {
        setSpinding(false);
      });
  };

  const isNumber = (e) => {
    let id = e.target.id.split("pin")[1];

    switch (id) {
      case "1":
        var value = document.getElementById("pin1").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin2").focus();
        }

        setPin1(value);
        break;
      case "2":
        value = document.getElementById("pin2").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin3").focus();
        } else {
          document.getElementById("pin1").focus();
        }

        setPin2(value);
        break;
      case "3":
        value = document.getElementById("pin3").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin4").focus();
        } else {
          document.getElementById("pin2").focus();
        }

        setPin3(value);
        break;
      /* case "4":
        value = document.getElementById("pin4").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin5").focus();
        } else {
          document.getElementById("pin3").focus();
        }

        setPin4(value);
        break;
      case "5":
        value = document.getElementById("pin5").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin6").focus();
        } else {
          document.getElementById("pin4").focus();
        }

        setPin5(value);
        break; */

      default:
        value = document.getElementById("pin4").value;

        value = value[value.length - 1];

        if (!value) {
          document.getElementById("pin3").focus();
        }

        setPin4(value);
    }
  };

  return (
    <div>
      <Row gutter={0} justify="center" style={{ marginBottom: 16 }}>
        <Col xs={22}>
          <Spinner visible={spinding} />
          <Logo />

          <div>
            <div className="title-header">ยืนยันรหัส OTP</div>
            <div className="title-detail">
              <div>กรุณากรอกรหัส OTP ที่ได้รับ ของหมายเลข</div>
              {mobile}
            </div>

            <Row
              gutter={16}
              justify="center"
              style={{ marginBottom: 16, textAlign: "center" }}
            >
              <Col xs={24} md={14}>
                <label className="label-form">รหัส OTP</label>
              </Col>
              <Col xs={24} md={14}>
                <Input
                  type="number"
                  id="pin1"
                  maxLength="1"
                  className="pin-text pin mobile-input"
                  inputMode="tel"
                  value={pin1}
                  onChange={isNumber}
                />
                <Input
                  type="number"
                  id="pin2"
                  maxLength="1"
                  className="pin-text pin mobile-input"
                  inputMode="tel"
                  value={pin2}
                  onChange={isNumber}
                />
                <Input
                  type="number"
                  id="pin3"
                  maxLength="1"
                  className="pin-text pin mobile-input"
                  inputMode="tel"
                  value={pin3}
                  onChange={isNumber}
                />
                <Input
                  type="number"
                  id="pin4"
                  maxLength="1"
                  className="pin-text pin mobile-input"
                  inputMode="tel"
                  value={pin4}
                  onChange={isNumber}
                />
                {/* <Input
                  type="number"
                  id="pin5"
                  maxLength="1"
                  className="pin-text pin mobile-input"
                  inputMode="tel"
                  value={pin5}
                  onChange={isNumber}
                />
                <Input
                  type="number"
                  id="pin6"
                  maxLength="1"
                  className="pin-text pin mobile-input"
                  inputMode="tel"
                  value={pin6}
                  onChange={isNumber}
                /> */}
              </Col>
              <Col
                xs={22}
                md={14}
                style={{ textAlign: "center", marginTop: 8 }}
              >
                <span className="title-detail">รหัสอ้างอิง : {otpRef}</span>
                <span className="countDown">({countdown})</span>
              </Col>
              <Col
                xs={22}
                md={14}
                style={{ textAlign: "center", marginTop: 8 }}
              >
                <Button
                  type="primary"
                  className="buttom-form"
                  onClick={onVerfyOTP}
                >
                  ยืนยัน
                </Button>
              </Col>
              <Col
                xs={22}
                md={14}
                style={{ textAlign: "center", marginTop: 16 }}
              >
                <span>ไม่ได้รับรหัส OTP?</span>
                <Button
                  type="link"
                  disabled={countdown > 0}
                  onClick={onReSentOTP}
                >
                  <span style={{ textDecoration: "underline" }}>
                    ส่งอีกครั้ง
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="footer" style={{ textAlign: "center", fontSize: 10 }}>
        <span>{window.software_version}</span>
      </div>
    </div>
  );
}
