import React, { useState, useEffect } from "react";
import { Table, Row, Col, Select, Checkbox } from "antd";
import numeral from "numeral";
import "../../Manage/component/Table.css";



export default function TableCustomer({ data, page, pageData, onChangePage, loading, onListResult, reload, handleChangePage }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { Option } = Select;
  const columns = [
    {
      title: "ชื่อ-สกุล",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => {
        return (
          <div style={{
            width: 140, justifyContent: 'left', alignItems: 'left', display: 'flex',
            textOverflow: 'ellipsis',
            wordWrap: 'break-word',
            overflow: 'hidden',
            maxLines: '3.6em',
            lineHeight: '1.8em'
          }}>
            <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
          </div>
        )

      }
    },
    {
      title: "เพศ",
      dataIndex: "sex",
      key: "sex",
      align: "center",
      render: (text, record, index) => {
        return <div style={{
          width: 80, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
        </div>
      }
    },
    {
      title: "อายุ",
      dataIndex: "age",
      key: "age",
      align: "center",
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
    },
    {
      title: "จังหวัด",
      dataIndex: "provinceNameTH",
      key: "provinceNameTH",
      align: "center",
      render: (text, record, index) => {
        return <div style={{
          width: 160, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
        </div>
      }
    },
    {
      title: "เเบรนด์",
      dataIndex: "brandNameEng",
      key: "brandNameEng",
      align: "center",
      render: (text, record, index) => {
        return <div style={{
          width: 120, justifyContent: 'left', alignItems: 'left', display: 'flex',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
        </div>
      }
    },
    {
      title: "Line App",
      dataIndex: "line",
      key: "line",
      align: "center",
      render: (text, record, index) => {
        return <div style={{
          width: 60, justifyContent: 'left', alignItems: 'left', display: 'block',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxLines: '3.6em',
          lineHeight: '1.8em'
        }}>
          <span style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 24 }}>{text}</span>
        </div>
      }
    }
  ];

  useEffect(() => {
    if (reload) {
      setSelectedRowKeys([])
      onListResult([])
    }
  }, [reload])

  let records = [];
  const handleSelect = (record, selected) => {
    if (selected) {
      records = [...records, record];
      setSelectedRowKeys((keys) => [...keys, record.key]);
      onListResult(records);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });

      onListResult(records);
    }
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) => keys.length === data.length ? [] : data.filter(data => data.line === "มี").map(data => data.key));
    onListResult(data.filter(data => data.line === "มี" && data));
  }

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < data.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    columnTitle: headerCheckbox,
    onSelect: handleSelect,
    getCheckboxProps: (record) => {
      return ({
        disabled: record?.line === 'ไม่มี',
        // Column configuration not to be checked
        name: record?.name,
      })
    }
  };

  function changeSizePage(value) {
    handleChangePage(value)
  }

  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={20} style={{ textAlign: "start" }}>
        </Col>
        <Col xs={4} style={{ justifyContent: 'end' }}>

          <span style={{ fontWeight: 'bold', marginLeft: 24 }}>เเสดง</span>
          <Select
            defaultValue="10"
            style={{ marginLeft: 10, marginRight: 10 }}
            className="mr-2 ml-2 mb-3"
            onChange={changeSizePage}>
            <Option value="10">10</Option>
            <Option value="25">25</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </Select>
          รายการ
        </Col>

      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rowKey="key"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            className="table-member"
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              pageSize: page,
              defaultPageSize: "10",
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: false,
              total: data.length > 0 ? data[0].totalrow : 0,
              onChange: onChangePage,
              current: pageData,
              showTotal: (total, range) =>
                `แสดงรายการที่ ${numeral(range[0]).format("0,0")} 
          ถึง ${numeral(range[1]).format("0,0")} 
          จาก ${numeral(data.length > 0 ? data.length : 0).format("0,0")} รายการ`,
            }}
          />
        </Col>

      </Row>
    </>
  );
}
