import React, { useEffect, useState } from "react";

import RegisterMember from "../../view/Manage/RegisterMember";
import SearchMember from "../../view/Manage/SearchMember";
import Broadcast from "../../view/Broadcast/Broadcast";
import Delivery from "../../view/Delivery/Search";

import { Layout, Card, Affix } from "antd";
import { SearchOutlined, PlusSquareOutlined, PieChartOutlined, UserAddOutlined, FundViewOutlined, BranchesOutlined } from "@ant-design/icons";
import TopicMenu from "../../component/TopicMenu";

import NavBar from "../../component/NavBar/NavBar";
import SideBar from "../../component/SideBar/SideBar";
import SearchBranch from "../../view/Manage/SearchBranch";
import SearchUser from "../../view/Manage/SearchUser";

const Full = props => {
  useEffect(() => {
    let access_token = localStorage.getItem("access_token");
    if (!access_token) window.location = `/#/login`;

    var menu = JSON.parse(localStorage.getItem("menu"));
    setMenuApi(menu);
  }, []);

  const search = props.location.search; // returns the URL query String
  const params = new URLSearchParams(search);
  const key = params.get('key');

  const component = [<SearchMember />, <RegisterMember />, <Broadcast />, <Delivery />, <SearchUser />, <SearchBranch />];

  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const [menuApi, setMenuApi] = useState([]);

  const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
  };

  const Menu = (
    <TopicMenu
      icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />, <UserAddOutlined />, <BranchesOutlined />]}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  useEffect(() => {
    if (key !== null) {
      window.location = `/#/management`;
      setSelectedKey(key)
      setContentIndex(key)
    }
  }, [key])
  
  return (
    <div>
      <Affix>
        <NavBar menu={Menu} name={"PHARMAX USER"} />
      </Affix>
      <Layout>
        <Affix offsetTop={60}>
          <SideBar menu={Menu} />
        </Affix>
        <Layout.Content className="content">
          <Card className="ant-card-bordered">{component[contentIndex]}</Card>
        </Layout.Content>
      </Layout>
      <div className="footer" style={{ textAlign: "right" }}>{window.software_version}</div>
    </div>
  );
}


export default Full