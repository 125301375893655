export const onRandomString = (length) => {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

export const isNumberKey = (str) => {
  var pattern = /^\d+$/;
  return pattern.test(str);
};

var maxDay = 31;

export const ConvertYear = (year) => {
  if (year > 2500) {
    year = year - 543;
  }

  return year;
};

export const onSelectMonth = (year, month) => {
  var dd = new Date().getDate();
  var mm = new Date().getMonth() + 1;
  var yy = new Date().getFullYear();

  year = ConvertYear(year);

  var d = new Date(year, month, 0).getDate();

  if (year == yy && month == mm) {
    maxDay = dd;

  } else {
    maxDay = d;
  }


};

export const dayOfMonth = () => {
  var day = [];
  for (var i = 1; i <= maxDay; i++) {
    day.push(String("0" + i).slice(-2));
  }

  return day;
};

export const monthOfYear = (year) => {
  const monthNames = [
    { id: "01", name: "มกราคม" },
    { id: "02", name: "กุมภาพันธ์" },
    { id: "03", name: "มีนาคม" },
    { id: "04", name: "เมษายน" },
    { id: "05", name: "พฤษภาคม" },
    { id: "06", name: "มิถุนายน" },
    { id: "07", name: "กรกฎาคม" },
    { id: "08", name: "สิงหาคม" },
    { id: "09", name: "กันยายน" },
    { id: "10", name: "ตุลาคม" },
    { id: "11", name: "พฤศจิกายน" },
    { id: "12", name: "ธันวาคม" },
  ];

  var mm = new Date().getMonth() + 1;
  var yy = new Date().getFullYear();

  if (year == yy) {
    return monthNames.filter((item) => item.id <= mm);
  }

  return monthNames;
};

export const yearOfAll = () => {
  var year = [];
  var year_now = new Date().getFullYear();
  for (var i = year_now; i > year_now - 100; i--) {
    year.push(i + 543);
  }
  return year;
};

export const ValidateEmail = (mail) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(mail)) {
    return (true)
  }
  return (false)
};

export const GetLineProfile = () => {
  var lineProfile = localStorage.getItem("profile");
  if (lineProfile) {
    return JSON.parse(lineProfile);;
  } else {
    return null;
  }
};

export function parseJwt(token) {
  if (token != "") {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
};

export const ValidateNumeric = (value) => {
  const reg = /^-?\d*(\d*)?$/;
  if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
    return (true);
  }
  return (false)
};