import React, { useState, useEffect, useCallback } from "react";
import { Input, Button, Row, Col, Select, Modal } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import Spinner from "../../component/Spinner/Spinner";
import { api } from "../../api/axios";
import DDL_Province from "../../component/Dropdown/DDL_Province";
import DDL_Brand from "../../component/Dropdown/DDL_Brand";

import TableMember from "./component/TableMember";
import Logo from "../../component/Logo/Logo";

import Swal from "sweetalert2";

const { Option } = Select;

export default function SearchMember() {
  const [spinding, setSpinding] = useState(false);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [sex, setSex] = useState("A");
  const [provinces, setProvinces] = useState();
  const [brand, setBrand] = useState("");
  const [admin, setAdmin] = useState(localStorage.getItem("groupCode") === "000" || localStorage.getItem("groupCode") === "100");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);
  const marginBottom = { marginBottom: 16 };

  const [validateMobile, setValidateMobile] = useState(false);
  const [OTPMobile, setOTPMobile] = useState("");
  const errorMessage = "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง";

  const [otpRef, setotpRef] = useState("");
  const [countdown, setCountdown] = useState(60);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
/*   const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState(""); */


  const onModalMember = (item) => {
    let action = localStorage.getItem("groupCode");
    if (action != "000") {
    setIsModalVisible(true);
    localStorage.setItem("member_code", item.member_code);
    localStorage.setItem("brand_code", item.brand_code);
    localStorage.setItem("mobile", item.mobile);
    //localStorage.setItem("action", "confirm");
    localStorage.setItem("mstatus", item.mstatus_code);
    // document.getElementsByClassName("ant-menu-item")[1].click();
    }else{
      localStorage.setItem("action", "confirm");
      localStorage.setItem("member_code", item.member_code);
      localStorage.setItem("brand_code", item.brand_code);
      localStorage.setItem("mobile", item.mobile);
      document.getElementsByClassName("ant-menu-item")[1].click();
    }

  };

  const getOTP = () => {
    // setOTPMobile(localStorage.getItem("mobile"));
    RequestOTP()
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    width: '300px'
  });

  const RequestOTP = (otp_action) => {
    setSpinding(true);

    const json = {
      mobile: localStorage.getItem("mobile"),
      brand: localStorage.getItem("brand"),
      type: "R"
    };
    api
      .post("api/OTP/RequestOTP", json)
      .then((response) => {
        let data = response.data;
        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "ระบบกำลังจัดส่งรหัส OTP ไปยังเบอร์โทรศัพท์ของท่าน",
          });
          setotpRef(data.data.referenceCode);
          localStorage.setItem("otp_action", otp_action);
          startTimer(60);
          setTimeout(() => {
            setIsVerifyOTP(true);
          }, 1000);
        } else {
          Toast.fire({
            icon: "error",
            title: "พบปัญหาในการเชื่อมต่อ กรุณาลองใหม่หรือแจ้งเจ้าหน้าที่",
          });
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onChangeMobile = (e) => {
    if (e.target.value.length < 11) {
      setMobile(e.target.value);
    }
  };


  const isNumber = (e) => {
    let id = e.target.id.split("pin")[1];

    switch (id) {
      case "1":
        var value = document.getElementById("pin1").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin2").focus();
        }

        setPin1(value);
        break;
      case "2":
        value = document.getElementById("pin2").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin3").focus();
        } else {
          document.getElementById("pin1").focus();
        }

        setPin2(value);
        break;
      case "3":
        value = document.getElementById("pin3").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin4").focus();
        } else {
          document.getElementById("pin2").focus();
        }

        setPin3(value);
        break;
     /*  case "4":
        value = document.getElementById("pin4").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin5").focus();
        } else {
          document.getElementById("pin3").focus();
        }

        setPin4(value);
        break; */
      /* case "5":
        value = document.getElementById("pin5").value;

        if (value) {
          value = value[value.length - 1];
          document.getElementById("pin6").focus();
        } else {
          document.getElementById("pin4").focus();
        }

        setPin5(value);
        break; */

      default:
        value = document.getElementById("pin4").value;

        value = value[value.length - 1];

        if (!value) {
          document.getElementById("pin3").focus();
        }

        setPin4(value);
    }
  };


  const onVerfyOTP = () => {
    setSpinding(true);
    localStorage.setItem("action", "confirm");
    // let otpPin = pin1 + pin2 + pin3 + pin4 + pin5 + pin6;
    let otpPin = pin1 + pin2 + pin3 + pin4;

    const json = {
      mobile: localStorage.getItem("mobile"),
      otpCode: otpPin,
      refCode: otpRef,
    };

    api
      .post("api/OTP/VerifyOTP", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "รหัส OTP ถูกต้อง",
          });
          document.getElementsByClassName("ant-menu-item")[1].click();
        } else {
          Toast.fire({
            icon: "error",
            title: data.message,
          });
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };


  const onReSentOTP = () => {
    setSpinding(true);
    setPin1("");
    setPin2("");
    setPin3("");
    setPin4("");
    /* setPin5("");
    setPin6(""); */
    const json = {
      mobile: localStorage.getItem("mobile"),
      brand: localStorage.getItem("brand"),
      type: "R",
    };

    api
      .post("api/OTP/RequestOTP", json)
      .then((response) => {
        let data = response.data;
        if (data.success) {
          setotpRef(data.data.referenceCode);
          localStorage.setItem("referenceCode", data.data.referenceCode);
          startTimer(60);
          
        }
        setSpinding(false);
      })
      .catch(() => {
        setSpinding(false);
      });
  };

  const startTimer = (number) => {
    var counter = number;
    var i = setInterval(function () {
      setCountdown(counter--);
      if (counter < 0) {
        clearInterval(i);
      }
    }, 1000);
  };


  const onClean = () => {
    setFirstName("");
    setLastName("");
    setMobile("");
    setSex("A");
    setBrand("");
    setProvinces(undefined);
    setPage(1);

    localStorage.removeItem("mobile");
    localStorage.removeItem("member_code");

    getDataList("clear");
  };

  const getDataList = useCallback((type) => {
    setSpinding(true);

    let json = {};
    if (type === "clear") {
      json = {
        fname: "",
        lname: "",
        mobile: "",
        sex: "A",
        province_code: undefined,
        brand_code: admin ? brand : localStorage.getItem("brand"),
        page: 1,
        pageLength: 10,
      };
    } else {
      json = {
        fname: firstName,
        lname: lastName,
        mobile: mobile,
        sex: sex,
        province_code: provinces,
        brand_code: admin ? brand : localStorage.getItem("brand"),
        page: page,
        pageLength: 10,
      };
    }

    api
      .post("api/Management/GetListMember", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setUserList(data.data);

          setSpinding(false);
        } else {
          setSpinding(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }

        setSpinding(false);
      });
  }, [page, firstName, lastName, mobile, sex, provinces, brand, admin, isVerifyOTP]);

  useEffect(() => {
    let action = localStorage.getItem("action");
    if (action == "search") {
      setMobile(localStorage.getItem("mobile"));
      setTimeout(() => {
        document.getElementsByClassName("ant-btn ant-btn-primary")[0].click();
      }, 100);
      }else{
        getDataList();
      }
    
  }, [page]);

  const onChangePage = (value) => {
    setPage(value);
  };

  const onSelectProvinces = (e) => {
    setProvinces(e);
  };

  const onSelectBrand = (e) => {
    setBrand(e);
  };

  const styleHide1 = { display: admin ? "-webkit-box" : "none" };

  return (
    <>
      <Spinner visible={spinding} />
      <SearchOutlined /> ค้นหาข้อมูลสมาชิก
      <hr />
      <Row gutter={16} justify="center" style={marginBottom}>
        <Col xs={0} md={6}></Col>
        <Col xs={12} md={6}>
          <label>ชื่อ</label>
          <Input
            placeholder="ชื่อ"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Col>
        <Col xs={12} md={6}>
          <label>นามสกุล</label>
          <Input
            placeholder="นามสกุล"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Col>
        <Col xs={0} md={6}></Col>
      </Row>
      <Row gutter={16} justify="center" style={marginBottom}>
        <Col xs={0} md={6}></Col>
        <Col xs={12} md={6}>
          <label>เบอร์โทรศัพท์</label>
          <Input
            type="number"
            className="mobile-input"
            value={mobile}
            placeholder="เบอร์โทรศัพท์"
            maxLength={10}
            onChange={onChangeMobile}
          />
        </Col>
        <Col xs={12} md={6}>
          <label>เพศ</label>
          <Select
            placeholder="เพศ"
            defaultValue="A"
            value={sex}
            style={{ width: "100%" }}
            onChange={(e) => setSex(e)}
          >
            <Option value="A">ทั้งหมด</Option>
            <Option value="M">ชาย</Option>
            <Option value="F">หญิง</Option>
            <Option value="N">ไม่ระบุ</Option>
          </Select>
        </Col>
        <Col xs={0} md={6}></Col>
      </Row>
      <Row gutter={16} justify="center" style={marginBottom}>
        <Col xs={0} md={6}></Col>
        <Col xs={12} md={6}>
          <label>จังหวัด</label>
          <DDL_Province
            placeholder={"จังหวัด"}
            onChange={onSelectProvinces}
            value={provinces}
          />
        </Col>

        <Col xs={12} md={6}>
          <div style={styleHide1}>
            <label>แบรนด์ </label>
            <DDL_Brand
              type={"member"}
              placeholder={"แบรนด์"}
              onChange={onSelectBrand}
              value={brand}
              optionAll={true}
            />
          </div>
        </Col>
        <Col xs={0} md={6}></Col>
      </Row>
      <Row gutter={16} justify="center" style={marginBottom}>
        <Col xs={0} md={6}></Col>
        <Col xs={12} md={6} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={getDataList}>
            <SearchOutlined />
            ค้นหา
          </Button>
        </Col>
        <Col xs={12} md={6}>
          <Button type="danger" onClick={onClean}>
            <DeleteOutlined />
            ล้าง
          </Button>
        </Col>
        <Col xs={0} md={6}></Col>
      </Row>
      <Row gutter={16} justify="center" style={marginBottom}>
        <Col xs={10}>ผลการค้นหา</Col>
        <Col xs={14} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            style={{ height: 60 }}
            onClick={() => {
              localStorage.setItem("action", "register");
              document.getElementsByClassName("ant-menu-item")[1].click();
            }}
          >
            สร้าง <br /> ข้อมูลสมาชิก
          </Button>
        </Col>
        <Col xs={24}>
          <TableMember
            data={userList}
            onModalMember={onModalMember}
            page={page}
            onChangePage={onChangePage}
          />
        </Col>
      </Row>

      <Modal
        closable={true}
        onCancel={() => {setIsModalVisible(false);
          setIsVerifyOTP(false);
          localStorage.removeItem("member_code");
          localStorage.removeItem("mobile");}
        }
        title="ขอรหัส OTP เพื่อเข้าสู่ระบบแก้ไข"
        open={isModalVisible}
        footer={false}
        width={600}
      >
        <Row gutter={0} justify="center">
          <Col xs={22}>
            <Spinner visible={spinding} />
            <Logo />

            <div>
            </div>
            {!isVerifyOTP ? <div>
              <div className="title-header">ตรวจสอบเบอร์โทรศัพท์มือถือ 4 ตัวท้าย</div>
              {/* <div className="title-detail">
                กรุณากรอกเบอร์โทรศัพท์มือถือเพื่อรับรหัส OTP
              </div> */}

              <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
                <Col xs={22} md={14}>
                  <label className="label-form">เบอร์โทรศัพท์มือถือ</label>
                </Col>
                <Col xs={22} md={14}>
                  <Input
                    type="text"
                    className="mobile-input"
                    value={localStorage.getItem("mobile") ? "******"+localStorage.getItem("mobile").substring(10,6):""}
                    onChange={onChangeMobile}
                  />
                </Col>
                <Col xs={22} md={14} style={{ marginTop: 16 }}>
                  {validateMobile ? (
                    <label className="label-error">{errorMessage}</label>
                  ) : null}
                </Col>
                <Col
                  xs={22}
                  md={14}
                  style={{ textAlign: "center", marginTop: 8 }}
                >
                  <Button
                    type="primary"
                    className="buttom-form"
                    onClick={getOTP}
                  >
                    รับรหัส OTP
                  </Button>
                </Col>
                <Col>
                <p style={{ textAlign: "center", marginTop: 8, color: "red" }}>โปรดระวังการใช้งาน!! ระบบจะส่ง SMS ไปยังเบอร์ดังกล่าวทันที</p>
                </Col>
              </Row>
            </div> : <div>
              <div className="title-header">ยืนยันรหัส OTP</div>
              <div className="title-detail">
                <div>กรุณากรอกรหัส OTP ที่ได้รับ ของหมายเลข</div>
                {localStorage.getItem("mobile") ? "******"+localStorage.getItem("mobile").substring(10,6):""}
              </div>

              <Row
                gutter={16}
                justify="center"
                style={{ marginBottom: 16, textAlign: "center" }}
              >
                <Col xs={24} md={14}>
                  <label className="label-form">รหัส OTP</label>
                </Col>
                <Col xs={24} md={14}>
                  <Input
                    type="number"
                    id="pin1"
                    maxLength="1"
                    className="pin-text pin mobile-input"
                    inputMode="tel"
                    value={pin1}
                    onChange={isNumber}
                  />
                  <Input
                    type="number"
                    id="pin2"
                    maxLength="1"
                    className="pin-text pin mobile-input"
                    inputMode="tel"
                    value={pin2}
                    onChange={isNumber}
                  />
                  <Input
                    type="number"
                    id="pin3"
                    maxLength="1"
                    className="pin-text pin mobile-input"
                    inputMode="tel"
                    value={pin3}
                    onChange={isNumber}
                  />
                  <Input
                    type="number"
                    id="pin4"
                    maxLength="1"
                    className="pin-text pin mobile-input"
                    inputMode="tel"
                    value={pin4}
                    onChange={isNumber}
                  />
                  {/* <Input
                    type="number"
                    id="pin5"
                    maxLength="1"
                    className="pin-text pin mobile-input"
                    inputMode="tel"
                    value={pin5}
                    onChange={isNumber}
                  />
                  <Input
                    type="number"
                    id="pin6"
                    maxLength="1"
                    className="pin-text pin mobile-input"
                    inputMode="tel"
                    value={pin6}
                    onChange={isNumber}
                  /> */}
                </Col>
                <Col
                  xs={22}
                  md={14}
                  style={{ textAlign: "center", marginTop: 8 }}
                >
                  <span className="title-detail">รหัสอ้างอิง : {otpRef} </span>
                  <span className="countDown">({countdown})</span>
                </Col>
                <Col
                  xs={22}
                  md={14}
                  style={{ textAlign: "center", marginTop: 8 }}
                >
                  <Button
                    type="primary"
                    className="buttom-form"
                    onClick={onVerfyOTP}
                  >
                    ยืนยัน
                  </Button>
                </Col>
                <Col
                  xs={22}
                  md={14}
                  style={{ textAlign: "center", marginTop: 16 }}
                >
                  <span>ไม่ได้รับรหัส OTP?</span>
                  <Button
                    type="link"
                    disabled={countdown > 0}
                    onClick={onReSentOTP}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      ส่งอีกครั้ง
                    </span>
                  </Button>
                </Col>
              </Row>
            </div>}
          </Col>
        </Row>
      </Modal>
    </>
  );
}
