import React, { useEffect, useState } from 'react'
import NavBar from "../../component/NavBar/NavBar";
import TableCoupon from "./table/TableCoupon";
import { Layout, Card, Affix, Col, Row, DatePicker, Input, Button, Typography, Form } from "antd";
import {
    DeleteOutlined,
    PieChartOutlined,
    SearchOutlined,
    PlusSquareOutlined,
    FundViewOutlined,
    UserAddOutlined,
    BranchesOutlined
} from "@ant-design/icons";
import { api } from "../../api/axios";
import SideBar from "../../component/SideBar/SideBar";
import TopicMenu from "../../component/TopicMenu";
import moment from "moment";

export default function Coupon() {
    const { Title } = Typography;
    const [page, setPage] = useState(1);
    const [pageResult, setPageResult] = useState(10);
    const [pageTotal, setPageTotal] = useState(0);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const margin = { marginBottom: 36, marginTop: 36 };
    const [boardCastList, setBoardCastList] = useState([]);
    const dateFormat = 'DD/MM/YYYY';

    function onChange(date) {
        form.setFieldsValue({ dateStart: date });
    }

    function onChangeEnd(date) {
        form.setFieldsValue({ dateFinish: date });
    }

    useEffect(() => {
        getDataList('', '', '', '');
    }, []);

    const onChangePage = (value) => {
        setPage(value);
    };

    const onClickItem = (value) => {
        localStorage.setItem("promotion_code", value.promotion_code);
        localStorage.setItem("promotion_name", value.promotion_name);
        localStorage.setItem("promotion_desc", value.promotion_desc);
        localStorage.setItem("customer_open", false);

        let json = {
            "promotion_code": value.promotion_code,
            "promotion_name": value.promotion_name,
            "promotion_desc": value.promotion_desc,
            "coupon_image_url": value.coupon_image_url,
            "pro_group_code": value.pro_group_code
        }

        api.post("Broadcast/SaveBroadcastCoupon", json, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        }).then((response) => {
            let result = response.data;

            if (result.success) {
                window.location = "/#/broadcast/customer/" + result.data.id;
            } else {

            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    window.location = `/#/logout`;
                }
            }
        });
    };

    const getDataList = (code, message, dateStart, dateFinish) => {
        let json = {
            "promotion_code": code,
            "promotion_desc": message,
            "active_date": dateStart,
            "coupon_expired_date": dateFinish,
            "start": 0,
            "length": 100000,
            "column": "promotion_code",
            "sorter": "desc",
        }
        setLoading(true)
        api
            .post("Broadcast/GetBroadcastCoupon", json, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                setLoading(false)
                let data = response.data;
                if (data.success) {

                    setBoardCastList(data.data.data);
                    setPageTotal(data.data.recordTotal);
                } else {
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.response) {
                    if (err.response.status === 401) {
                        window.location = `/#/logout`;
                    }
                }
            });
    };


    const onClear = () => {
        form.resetFields();
    }


    const onHandleChangePageResult = (value) => {
        setPageResult(value);
    };


    const onFinish = (values) => {
        var dateStart = ''
        var dateFinish = ''
        if (values.dateStart === undefined) {
            dateStart = '';
        } else {
            dateStart = moment(values.dateStart, "YYYY-MM-DD").format("YYYY/MM/DD");
        }

        if (values.dateFinish === undefined) {
            dateFinish = '';
        } else {
            dateFinish = moment(values.dateFinish, "YYYY-MM-DD").format("YYYY/MM/DD");
        }
        getDataList(values.code, values.message, dateStart, dateFinish);
    };


    useEffect(() => {
        let access_token = localStorage.getItem("access_token");
        if (!access_token) window.location = `/#/login`;

        var menu = JSON.parse(localStorage.getItem("menu"));

        setMenuApi(menu);
    }, []);



    const [selectedKey, setSelectedKey] = useState("2");
    const [menuApi, setMenuApi] = useState([]);

    const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

    const changeSelectedKey = (event) => {
        const key = event.key
        window.location = `/#/management?key=${key}`;
    };

    const Menu = (
        <TopicMenu
            icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />, <UserAddOutlined />, <BranchesOutlined />]}
            topics={topics}
            selectedKey={selectedKey}
            changeSelectedKey={changeSelectedKey}
        />
    );

    return (
        <div>
            <Affix>
                <NavBar menu={null} name={"PHARMAX USER"} />
            </Affix>
            <Layout>
                <Affix offsetTop={60}>
                    <SideBar menu={Menu} />
                </Affix>
                <Layout.Content style={{ marginLeft: 48, marginRight: 48, marginTop: 24 }}>
                    <Card style={{ paddingRight: 24, paddingLeft: 24 }}>
                        <div>
                            <PieChartOutlined /> Broadcast coupon
                            <hr />
                            ส่งคูปอง
                        </div>


                        <Title level={4} style={{ marginTop: 36 }}>
                            ค้นหาคูปอง
                        </Title>
                        <hr />

                        <Form form={form} name="control-hooks" onFinish={onFinish}>
                            <Row gutter={16} justify="center" style={{ marginTop: 36, }}>
                                <Col xs={12} md={6}>
                                    รหัสคูปอง
                                </Col>
                                <Col xs={12} md={6}>
                                    ชื่อคูปอง
                                </Col>
                                <Col xs={12} md={6}>
                                    วันที่ออกคูปอง
                                </Col>
                                <Col xs={12} md={6}>
                                    วันที่หมดอายุ
                                </Col>
                            </Row>
                            <Row gutter={16} justify="center" style={{ marginTop: 6, marginBottom: 36 }}>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="code"
                                    >
                                        <Input
                                            style={{ width: "100%" }}
                                            placeholder="รหัสคูปอง"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="message"
                                    >
                                        <Input
                                            style={{ width: "100%" }}
                                            placeholder="ชื่อคูปอง"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="dateStart"
                                    >
                                        <DatePicker placeholder="เลือกวันที่" style={{ width: "100%" }} onChange={onChange}
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="dateFinish"
                                    >
                                        <DatePicker placeholder="เลือกวันที่" style={{ width: "100%" }} onChange={onChangeEnd}
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16} justify="center" style={margin}>
                                <Col xs={0} md={6}></Col>
                                <Col xs={12} md={6} style={{ textAlign: "right" }}>
                                    <Button type="danger" onClick={onClear}>
                                        <DeleteOutlined />
                                        ล้าง
                                    </Button>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Button type="primary" htmlType="submit">
                                        <SearchOutlined />
                                        ค้นหา
                                    </Button>
                                </Col>
                                <Col xs={0} md={6}></Col>
                            </Row>
                        </Form>


                        <Col xs={24} style={{ marginTop: 48 }}>
                            <TableCoupon
                                data={boardCastList}
                                page={page}
                                pageResult={pageResult}
                                loading={loading}
                                pageCount={pageTotal}
                                onChangePage={onChangePage}
                                onClick={onClickItem}
                                handleChangePage={onHandleChangePageResult}
                            />
                        </Col>
                    </Card>
                </Layout.Content>
            </Layout>

        </div>
    )
}
