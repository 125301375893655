import React, { useRef, useState, useEffect } from 'react'
import NavBar from "../../component/NavBar/NavBar";
import { Form, Layout, Card, Affix, Col, Row, Input, Button, Modal } from "antd";
import {
  DeleteOutlined,
  PieChartOutlined,
  SearchOutlined,
  PlusSquareOutlined,
  FundViewOutlined,
  UserAddOutlined,
  BranchesOutlined
} from "@ant-design/icons";
import { api } from "../../api/axios";
import SideBar from "../../component/SideBar/SideBar";
import TopicMenu from "../../component/TopicMenu";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom';

export default function Message() {
  const { TextArea } = Input;
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [imageValue, setImageValue] = useState(null)
  const fileInput = useRef();
  const [form] = Form.useForm();
  const history = useHistory();
  
  const onFilechange = (e) => {
    if (e.target.files[0].name === undefined) {
      form.setFieldsValue({ file: '' });
      setImageValue(null);
    } else {
      form.setFieldsValue({ file: e.target.files[0].name });
      setImageValue(e.target.files[0]);
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const onFinish = (values) => {
    if (values.broadcast_message === '' || values.broadcast_message.trim() === '') {
      Modal.confirm({
        title: "ข้อความ",
        icon: <InfoCircleOutlined />,
        content:
          "กรุณากรอกรายละเอียดให้ครบถ้วน",
        okText: "ตกลง",
        cancelText: "ยกเลิก",
      });
    } else if (values.broadcast_subject === undefined || values.broadcast_subject.trim() === '') {
      Modal.confirm({
        title: "ข้อความ",
        icon: <InfoCircleOutlined />,
        content:
          "กรุณากรอกหัวข้อเรื่องให้ครบถ้วน",
        okText: "ตกลง",
        cancelText: "ยกเลิก",
      });
    }
    else {
      var companyCode = localStorage.getItem("companyCode");
      var username = localStorage.getItem("username");
      localStorage.setItem("promotion_name", values.broadcast_subject);
      localStorage.setItem("promotion_desc", values.broadcast_message);
      localStorage.setItem("customer_open", false);
      const data = new FormData();
      data.append("broadcast_subject", values.broadcast_subject);
      data.append("broadcast_message", values.broadcast_message);
      data.append("files", imageValue);
      data.append("company_code", companyCode);
      data.append("user_login", username);
      api.post("Broadcast/SaveBroadcastMessage", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data; charset=UTF-8",
        },
      }).then((response) => {
        let result = response.data;
        if (result.success) {
          history.push({
            pathname: `/broadcast/customer/${result.data.id}`,
            broadcastMessage: true
          });
        } else {

        }
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
    }

  };

  const onClearData = () => {
    setImage("");
    form.setFieldsValue({ file: '' });
    form.setFieldsValue({ broadcast_subject: '' });
    setDescription('');
  }

  const onImgLoad = (img) => {
    if (img.target.naturalHeight > 1000 || img.target.naturalWidth > 1000) {
      setImage("");
      form.setFieldsValue({ file: '' });
      Modal.confirm({
        title: 'เเจ้งเตือน',
        icon: <ExclamationCircleOutlined />,
        content: 'ขนาดภาพจะต้องไม่เกิน 1000x1000 pixel',
        okText: 'ตกลง',
        cancelText: 'ยกเลิก',
      });
    }
  }

  useEffect(() => {
    let access_token = localStorage.getItem("access_token");
    if (!access_token) window.location = `/#/login`;

    var menu = JSON.parse(localStorage.getItem("menu"));
    
    setMenuApi(menu);
  }, []);

  const [selectedKey, setSelectedKey] = useState("2");
  const [menuApi, setMenuApi] = useState([]);

  const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

  const changeSelectedKey = (event) => {
    const key = event.key
    window.location = `/#/management?key=${key}`;
  };

  const Menu = (
    <TopicMenu
      icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />, <UserAddOutlined />, <BranchesOutlined />]}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  function isInputMax(evt) {

    if (evt.target.value.length >= 200) {
      evt.preventDefault();
    }
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <div>
        <Affix>
          <NavBar menu={null} name={"PHARMAX USER"} />
        </Affix>
        <Layout>
          <Affix offsetTop={60}>
            <SideBar menu={Menu} />
          </Affix>
          <Layout.Content style={{ marginLeft: 48, marginRight: 48, marginTop: 24 }}>
            <Card style={{ paddingRight: 24, paddingLeft: 24 }}>
              <div>
                <PieChartOutlined /> Broadcast message
                <hr />
                ส่งข้อความ
              </div>

              {image === '' ?
                null
                :
                <div style={{
                  marginTop: 24,
                  marginBottom: 48,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <img onLoad={onImgLoad} src={image} alt="" id="img" className="img" width="400" height="400" />
                </div>
              }

              <Row style={{ marginTop: 24 }}>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                  <Form.Item
                    label={"หัวข้อเรื่อง"}
                    name="broadcast_subject"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกหัวข้อเรื่อง',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10, offset: 1 }}>
                  <Form.Item
                    label={"เเนบภาพ"}
                    name="file"
                    rules={[
                      {
                        message: 'Please input your File!',
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 2, offset: 1 }}>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => fileInput.current.click()}
                    >
                      เลือกไฟล์
                    </Button>

                    <input
                      ref={fileInput}
                      onChange={onFilechange}
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                  </div>
                </Col>
              </Row>
              <Row  >
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    label={"ข้อความ"}
                    name="broadcast_message"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกข้อความ'
                      }
                    ]}
                  >
                    <TextArea showCount maxLength={200} rows={6}
                      onKeyPress={(event) => {
                        isInputMax(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: 12 }}>
                <Col flex="60px" style={{ marginRight: 24 }}>
                  <Button type="danger" style={{ width: '100%' }}
                    onClick={() => { onClearData() }}>
                    <DeleteOutlined />
                    ล้าง
                  </Button >
                </Col>
                <Col flex="60px" >
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                  >
                    ยืนยัน
                  </Button>
                </Col>
              </Row>
            </Card>
          </Layout.Content>
        </Layout>
        <div className="footer" style={{ textAlign: "right" }}>{window.software_version}</div>
      </div>
    </Form>
  )
}
