import React, { useState, useEffect } from 'react'
import NavBar from "../../component/NavBar/NavBar";
import { Layout, Card, Affix, Col, Row, Typography, Button, Image, Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../api/axios";
import TableDeliveryDetail from "./table/TableDeliveryDetail";
import {
  DeleteOutlined,
  PieChartOutlined,
  SearchOutlined,
  PlusSquareOutlined,
  FundViewOutlined
} from "@ant-design/icons";
import SideBar from "../../component/SideBar/SideBar";
import TopicMenu from "../../component/TopicMenu";
export default function Detail() {
  const { Title } = Typography;
  const marginBottom = { marginBottom: 36 };
  const marginTitle = { marginBottom: 16 };
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [image, setImage] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [pathResult, setPathResult] = useState('');
  const [pageResult, setPageResult] = useState(0);
  const [pageSuccess, setPageSuccess] = useState(10);
  const [mobileData, setMobile] = useState('');
  const [listJobBroadcast, setListJobBroadcastMapping] = useState([]);

  const onChangePage = (value) => {
    setPage(value);
  };

  useEffect(() => {
    const indexOfFirst = history.location.pathname.substring(1, location.length).indexOf('/');
    const indexCondiftion = history.location.pathname.substring(1, history.location.pathname.length).indexOf('/', indexOfFirst + 2);
    const path = history.location.pathname.substring(indexCondiftion + 2, history.location.pathname.length)
    setPathResult(path)
    getData(Number(path))
  }, []);


  const getDataList = (id) => {
    let json = {
      "broadcast_id": id
    }
    setLoading(true)
    api
      .post("api/BachJob/ListJobBroadcastMapping", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response.data;

        if (data.success) {
          var list = []
          for (const element of data.data.items) {

            if (element.statusName === "รอคิวส่ง") {
              var obj = {
                broadcast_id: element.broadcast_id,
                id: element.id,
                mobile: element.mobile,
                statusName: element.statusName,
                schedule_date: '',
                sent_date: element.schedule_date,
                send_status: element.send_status
              }
              list.push(obj)
            } else {
              var obj1 = {
                broadcast_id: element.broadcast_id,
                id: element.id,
                mobile: element.mobile,
                statusName: element.statusName,
                schedule_date: element.sent_date,
                sent_date: element.schedule_date,
                send_status: element.send_status
              }
              list.push(obj1)
            }
          }
          setPageResult(data.data.items.length)
          setListJobBroadcastMapping(list)
        } else {
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
  };


  const getData = (id) => {
    setMobile("")
    let json = {
      "id": id
    }
    setLoading(true)
    api
      .post("Broadcast/GetBroadcastImage", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response.data;

        if (data.success) {
          var name = ''
          var image = ''
          if (data.data.promotion_message == null) {
            name = data.data.coupon_message
          } else {
            name = data.data.promotion_message
          }

          if (data.data.coupon_image == null) {
            image = data.data.message_image
          } else {
            image = data.data.coupon_image
          }
          setImage(image)
          setMessage(name)
          setSubject(data.data.promotion_name)
          getDataList(id)
        } else {
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("access_token");
    if (!access_token) window.location = `/#/login`;

    var menu = JSON.parse(localStorage.getItem("menu"));
    
    setMenuApi(menu);
  }, []);



  const [selectedKey, setSelectedKey] = useState("3");
  const [menuApi, setMenuApi] = useState([]);

  const topics = menuApi ? menuApi.map((item) => item.menu_th) : [];

  const changeSelectedKey = (event) => {
    const key = event.key
    window.location = `/#/management?key=${key}`;
  };

  const onHandleChangePageResult = (value) => {
    setPageSuccess(value);
  };



  function onChangeMobile(e) {
    let isnum = /^\d+$/.test(e.target.value);

    if (isnum) {
      setMobile(e.target.value)
    } else {
      setMobile("")
    }
  }

  function isInputNumber(evt) {
    const ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }

  const onSearch = () => {
    var list = []
    if (mobileData !== "") {
      for (const element of listJobBroadcast) {
        if (element.mobile !== null) {
          if (element.mobile.toLowerCase().includes(mobileData.toLowerCase())) {
            list.push(element);
          }
        }
      }

    }
    setPageResult(list.length)
    setListJobBroadcastMapping(list)
  }

  const Menu = (
    <TopicMenu
      icon={[<SearchOutlined />, <PlusSquareOutlined />, <PieChartOutlined />, <FundViewOutlined />]}
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <div>
      <Affix>
        <NavBar menu={null} name={"PHARMAX USER"} />
      </Affix>
      <Layout>
        <Affix offsetTop={60}>
          <SideBar menu={Menu} />
        </Affix>
        <Layout.Content style={{ marginLeft: 48, marginRight: 48 }}>
          <Card style={{ paddingRight: 24, paddingLeft: 24 }}>
            <Title level={4} style={{ marginTop: 36 }}>
              การจัดส่งคูปอง/ข้อความ
            </Title>
            <hr style={marginBottom} />

            <Row style={{ marginTop: 12 }}>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Image
                  width={"70%"}
                  src={image}
                />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                <Title level={5} style={marginTitle}>
                  รหัสคูปอง/หัวข้อเรื่อง : {subject}
                </Title>
                <Title level={5} style={marginTitle}>
                  ชื่อคูปอง/ข้อความ : {message}
                </Title>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                  <Title level={5} style={{ marginTop: 48 }}>
                    ค้นหาเบอร์โทรศัพท์ :
                  </Title>

                  <Row gutter={24} style={{ marginTop: 12, }}>
                    <Col xs={12} md={12}>
                      <Input
                        value={mobileData}
                        style={{ width: "100%" }}
                        onChange={onChangeMobile}
                        onKeyPress={(event) => {
                          isInputNumber(event);
                        }}
                        maxLength={10}
                      />
                    </Col>

                    <Col xs={12} md={6} style={{ textAlign: "right" }}>
                      <Button type="danger" onClick={() => { getData(Number(pathResult)) }}>
                        <DeleteOutlined />
                        ล้าง
                      </Button>
                    </Col>
                    <Col xs={12} md={6}>
                      <Button type="primary" onClick={() => { onSearch() }}>
                        <SearchOutlined />
                        ค้นหา
                      </Button>
                    </Col>
                  </Row>

                </Col>
                <Button type="primary" style={{ marginTop: 36 }} onClick={() => {
                  window.location = `/#/management?key=3`;
                }}>
                  กลับหน้าเเรก
                </Button>
              </Col>
            </Row>
            <Col xs={24} style={{ marginTop: 48 }}>
              <TableDeliveryDetail
                data={listJobBroadcast}
                page={page}
                loading={loading}
                onChangePage={onChangePage}
                pageResult={pageResult}
                pageSuccess={pageSuccess}
                handleChangePage={onHandleChangePageResult}
              />
            </Col>
          </Card>
        </Layout.Content>
      </Layout>

    </div>
  )
}
