import React, { useState, useEffect } from "react";
import Logo from "../../component/Logo/Logo";
import LockImg from "../../assets/img/lock.png";
import ProfileImg from "../../assets/img/profile-user.png";
import PhoneImg from "../../assets/img/cell-phone.png";
import CakeImg from "../../assets/img/cake.png";
import Spinner from "../../component/Spinner/Spinner";
import { api } from "../../api/axios";
import moment from "moment";
import Swal from "sweetalert2";

import pharmax_card from "../../assets/img/pharmax_card.png";
import icare_card from "../../assets/img/icare_card.png";
import superdrug_card from "../../assets/img/superdrug_card.png";
import backcard_pharmax from "../../assets/img/backcard_pharmax.png";
import backcard_icare from "../../assets/img/backcard_icare.png";
import backcard_superdrug from "../../assets/img/backcard_superdrug.png";

import { Input, Button, Row, Col, Modal, Select, Card } from "antd";
import ReactCardFlip from "react-card-flip";
import {
  MobileOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { isInClient } from "../../component/LineLiff/LineLiff";
import Coupon from "../../component/Coupon/Coupon";
import Barcode from "react-barcode";

moment.locale("th");

export default function MemberDetial() {
  const [spinding, setSpinding] = useState(false);
  const [flipCard, setFipCard] = useState(false);
  const [profile, setProfile] = useState({});
  const [couponData, setCouponData] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const styleLogo = { height: 36, width: "auto" };
  const styleImg = { height: 32, width: "auto", marginRight: 16 };

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1500,
    width: "300px",
  });

  useEffect(() => {
    onGetProfile();
  }, []);

  const onGetProfile = () => {
    setSpinding(true);

    let mobile = localStorage.getItem("mobile");
    let brand = localStorage.getItem("brand");
    let profileLine = localStorage.getItem("profile");

    const json = {
      Mobile: mobile,
      brand_code: brand,
    };
    api
      .post("api/members/GetProfile", json)
      .then((response) => {
        let data = response.data;
        if (data.success) {
          setProfile(data.data);

          onListMemberCoupon(data.data.member_code);
        } else {
          window.location = `/#/logout`;
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onListMemberCoupon = (member_code) => {
    setSpinding(true);
    setCouponData([]);

    const json = {
      member_code: member_code,
    };
    api
      .post("api/coupon/ListMemberCoupon", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          setCouponData(data.data);
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onRedeemCoupon = (coupon_serial) => {
    setSpinding(true);

    const json = {
      member_code: profile.member_code,
      coupon_serial: coupon_serial,
      redeem_value: 1,
    };
    api
      .post("api/coupon/RedeemCoupon", json)
      .then((response) => {
        let data = response.data;

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: "ใช้งานคูปองเรียบร้อยแล้ว",
          });

          onListMemberCoupon(profile.member_code);
        } else {
          Modal.confirm({
            title: "Confirm",
            icon: <InfoCircleOutlined />,
            content: data.message,
            okButtonProps: { style: { display: "none" } },
            okText: "ตกลง",
            cancelText: "ปิด",
          });
        }
        setSpinding(false);
      })
      .catch((err) => {
        setSpinding(false);
      });
  };

  const onEditProfile = () => {
    setSpinding(true);
    setTimeout(() => {
      window.location =
        `/#/register?action=edit&migration=` + profile.mstatus_code;
    }, 1000);
  };

  const onUseCoupon = (coupon_serial) => {
    Modal.confirm({
      title: "Confirm",
      icon: <InfoCircleOutlined />,
      content: (
        <div>
          <p>คุณต้องการใช้คูปองใช่หรือไม่?</p>{" "}
          <p>(กรุณา​แสดงคูปองนี้ และกดใช้คูปองต่อหน้าพนักงานที่สาขา)</p>
          <div style={{ textAlign: 'center'}}>
            <Barcode
              value={coupon_serial}
              fontSize={10}
              width={2}
              height={50}
              displayValue={false}
            />
            <text style={{ fontSize: 20, display: 'block' }}>{coupon_serial}</text>
          </div>
        </div>
      ),
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      width: 500,
      onOk: () => onRedeemCoupon(coupon_serial),
    });
  };

  return (
    <>
      <Spinner visible={spinding} />

      <Logo />
      <Row gutter={0} justify="center">
        <Col xs={20}>
          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14}>
              <label className="title-header">รายละเอียดสมาชิก</label>
            </Col>
            <Col xs={24} md={14} style={{ textAlign: "center" }}>
              <ReactCardFlip isFlipped={flipCard} flipDirection="horizontal">
                {localStorage.getItem("brand").toLowerCase() === "pm000" ? (
                  <img
                    src={pharmax_card}
                    alt="front_card"
                    onClick={() => setFipCard(!flipCard)}
                    style={{
                      margin: 8,
                      maxWidth: 300,
                    }}
                  />
                ) : localStorage.getItem("brand").toLowerCase() === "ic000" ? (
                  <img
                    src={icare_card}
                    alt="front_card"
                    onClick={() => setFipCard(!flipCard)}
                    style={{
                      margin: 8,
                      maxWidth: 300,
                    }}
                  />
                ) : localStorage.getItem("brand").toLowerCase() === "sd000" ? (
                  <img
                    src={superdrug_card}
                    alt="front_card"
                    onClick={() => setFipCard(!flipCard)}
                    style={{
                      margin: 8,
                      maxWidth: 300,
                    }}
                  />
                ) : null}

                {localStorage.getItem("brand").toLowerCase() === "pm000" ? (
                  <img
                    src={backcard_pharmax}
                    alt="backcard_pharmax"
                    onClick={() => setFipCard(!flipCard)}
                    style={{
                      margin: 8,
                      maxWidth: 300,
                    }}
                  />
                ) : localStorage.getItem("brand").toLowerCase() === "ic000" ? (
                  <img
                    src={backcard_icare}
                    alt="backcard_icare"
                    onClick={() => setFipCard(!flipCard)}
                    style={{
                      margin: 8,
                      maxWidth: 300,
                    }}
                  />
                ) : localStorage.getItem("brand").toLowerCase() === "sd000" ? (
                  <img
                    src={backcard_superdrug}
                    alt="backcard_superdrug"
                    onClick={() => setFipCard(!flipCard)}
                    style={{
                      margin: 8,
                      maxWidth: 300,
                    }}
                  />
                ) : null}

                {/* <img
                  src={backcard_icare}
                  alt="backcard_icare"
                  onClick={() => setFipCard(!flipCard)}
                  style={{
                    margin: 8,
                    maxWidth: 300,
                  }}
                /> */}

                {/* <Card
                  style={{
                    width: "100%",
                    maxWidth: 400,
                    minHeight: 200,
                    marginBottom: 16,
                    borderRadius: 25,
                    backgroundColor: "#e6a919",
                    cursor: "pointer",
                    wordBreak: "break-word",
                    boxShadow: "5px 8px #8888886b",
                  }}
                  onClick={() => setFipCard(!flipCard)}
                >
                  <Logo style={styleLogo} textAlign={"left"} />
                  <label
                    style={{
                      position: "absolute",
                      top: "85%",
                      left: "50%",
                      marginRight: "-50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: 200,
                      textOverflow: "ellipsis",
                    }}
                  >
                    เป็นสมาชิกวันที่{" "}
                    {profile
                      ? moment(profile.register_date)
                          .add(543, "y")
                          .format("DD/MM/YYYY")
                      : ""}
                  </label>
                </Card>

                <Card
                  style={{
                    width: "100%",
                    maxWidth: 400,
                    minHeight: 200,
                    marginBottom: 16,
                    borderRadius: 25,
                    backgroundColor: "#e6a919",
                    cursor: "pointer",
                    boxShadow: "5px 8px #8888886b",
                  }}
                  onClick={() => setFipCard(!flipCard)}
                >
                  <img
                    src={LockImg}
                    alt="Lock"
                    style={{
                      height: 225,
                      width: "auto",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginRight: "-50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Card> */}
              </ReactCardFlip>
            </Col>
          </Row>
          
          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                  onClick={() => setIsActive(!isActive)}
                >
                  <div className="title-header" style={{ marginBottom: 0 }}>ดูรายละเอียด</div>
                  <div
                    style={{
                      display: "flex"
                    }}
                  >
                    {isActive ? (
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 20, height: 20 }} viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 20, height: 20 }} viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                      </svg>
                    )}
                  </div>
                </div>
                {isActive && (
                  <div style={{
                    paddingTop: 16,
                    paddingBottom: 16
                  }}>
                    <div style={{ marginBottom: 16 }}>
                      <img src={ProfileImg} style={styleImg} alt="Profile" />
                      {profile ? `${profile.fname} ${profile.lname}` : ""}
                    </div>
                    <div>
                      <img src={PhoneImg} style={styleImg} alt="Phone" />
                      {profile ? profile.mobile : ""}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14}>
              <div style={{ textAlign: 'center'}}>
                <Barcode
                  value={profile.mobile}
                  fontSize={10}
                  width={2}
                  height={50}
                  displayValue={false}
                />
              </div>
            </Col>
          </Row>

          {/* <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14}>
              <img src={ProfileImg} style={styleImg} alt="Profile" />
              {profile ? `${profile.fname} ${profile.lname}` : ""}
            </Col>
          </Row>

          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14}>
              <img src={PhoneImg} style={styleImg} alt="Phone" />
              {profile ? profile.mobile : ""}
            </Col>
          </Row> */}

          {profile.mstatus_code === "MIG" ? (
            <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
              <Col xs={20} md={14} style={{ textAlign: 'center' }}>
                <label style={{ color: "red" }}>
                  กรุณาแก้ไขข้อมูลสมาชิก
                </label>
                <br/>
                <label style={{ color: "red" }}>
                  เพื่อรับสิทธิประโยชน์
                </label>
              </Col>
            </Row>
          ) : null}

          {/* <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col xs={20} md={14}>
              <img src={CakeImg} style={styleImg} alt="Cake" />
              {profile
                ? profile.birthdate ? moment(profile.birthdate).format("DD/MM")
                  + "/" + moment(profile.birthdate).add(543, "y").format("YYYY") : ""
                : ""}
            </Col>
          </Row> */}

          <Row
            gutter={16}
            justify="center"
            style={{
              marginBottom: 16,
              display: isInClient() ? "flex" : "none",
            }}
          >
            <Col xs={20} md={14}>
              <Button
                type="primary"
                onClick={onEditProfile}
                style={{ marginRight: 16 }}
              >
                แก้ไขข้อมูลสมาชิก
              </Button>
            </Col>
          </Row>

          {couponData.length > 0
            ? couponData.map((item) => (
                <Coupon
                  title={item.promotion_name}
                  expDate={item.expire_date}
                  image={item.coupon_image}
                  serial={item.coupon_serial}
                  onClick={() => onUseCoupon(item.coupon_serial)}
                />
              ))
            : null}
        </Col>
      </Row>
      <div className="footer" style={{ textAlign: "center", fontSize: 10 }}>
        <span>{window.software_version}</span>
      </div>
    </>
  );
}
