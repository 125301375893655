import React from "react";
import { Table, Row, Col, Select } from "antd";
import numeral from "numeral";
import moment from "moment";
import "../../Manage/component/Table.css";


export default function TableDeliveryDetail({ data, page, onChangePage, loading, pageResult, pageSuccess, handleChangePage }) {
  const { Option } = Select;
  const columns = [
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "mobile",
      key: "mobile",
      align: "center"
    },
    {
      title: "เริ่ม วันที่-เวลา",
      dataIndex: "sent_date",
      key: "sent_date",
      align: "center",
      render: (text, record, index) => {
        return <span style={{ fontWeight: 'bold' }}>{moment(text).format("DD/MM/YYYY HH:mm")}</span>;
      }
    },
    {
      title: "ถึง วันที่-เวลา",
      dataIndex: "schedule_date",
      key: "schedule_date",
      align: "center",
      render: (text, record, index) => {
        return <span style={{ fontWeight: 'bold' }}>{
          text === '' ? null :
            moment(text).format("DD/MM/YYYY HH:mm")}</span>;
      }
    },
    {
      title: "สถานะจัดส่ง",
      dataIndex: "statusName",
      key: "statusName",
      align: "center",
      render: (text, item) => (
        <div style={item.send_status === "S" ? null : { color: "#FF0000" }}>
          {item.statusName}
        </div>
      ),
    },
  ];

  function changeSizePage(value) {
    handleChangePage(value)
  }

  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        <Col xs={20} style={{ textAlign: "start" }}>

          <span style={{ fontWeight: 'bold', marginLeft: 8, paddingTop: 24 }}>ผลการค้นหา  {pageResult} รายการ</span>
        </Col>
        <Col xs={4} style={{ justifyContent: 'end' }}>

          <span style={{ fontWeight: 'bold', marginLeft: 24 }}>เเสดง</span>
          <Select
            defaultValue="10"
            style={{ marginLeft: 10, marginRight: 10 }}
            className="mr-2 ml-2 mb-3"
            onChange={changeSizePage}>
            <Option value="10">10</Option>
            <Option value="25">25</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </Select>
          รายการ
        </Col>

      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rowKey="member_code"
            columns={columns}
            dataSource={data}
            className="table-member"
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              pageSize: pageSuccess,
              defaultPageSize: "10",
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: true,
              total: data.length > 0 ? data[0].totalrow : 0,
              onChange: onChangePage,
              current: page,
              showTotal: (total, range) =>
                `แสดงรายการที่ ${numeral(range[0]).format("0,0")} 
          ถึง ${numeral(range[1]).format("0,0")} 
          จาก ${numeral(data.length > 0 ? data.length : 0).format("0,0")} รายการ`,
            }}
          />
        </Col>

      </Row>
    </>
  );
}
