import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Radio,
} from "antd";
import Spinner from "../../component/Spinner/Spinner";
import {
  PieChartOutlined,
} from "@ant-design/icons";

export default function Broadcast() {
  const [spinding, setSpinding] = useState(false);
  const [value, setValue] = useState(1);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, []);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onClick = () => {
    if (value === 1) {
      window.location = "/#/broadcast/messages";
    }
    else {
      window.location = "/#/broadcast/coupon";
    }
  }

  const styleFormInput = { paddingTop: 30, paddingBottom: 30 };
  const styleFormtextAlign = { textAlign: 'center' };

  return (
    <div>
      <Spinner visible={spinding} />
      <PieChartOutlined /> Broadcast
      <hr />
      <Row gutter={16} justify="center" style={styleFormInput}>
        <Col xs={8} md={8}></Col>
        <Col xs={8} md={8} style={styleFormtextAlign}>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>ส่งข้อความ</Radio>
            <Radio value={2}>ส่งคูปอง</Radio>
          </Radio.Group>
        </Col>
        <Col xs={8} md={8}></Col>
      </Row>
      <Row gutter={16} justify="center" style={styleFormInput}>
        <Col xs={8} md={8}></Col>
        <Col xs={8} md={8} style={styleFormtextAlign}>
          <Button type="primary" onClick={onClick}>
            ตกลง
          </Button>
        </Col>
        <Col xs={8} md={8}></Col>
      </Row>
      <div className="footer" style={{ textAlign: "center", fontSize: 10 }}>
        <span>{window.software_version}</span>
      </div>
    </div>
  );
}
