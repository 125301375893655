import React, { useEffect, useState } from 'react'
import { Col, Row, Radio, Input, Button, Checkbox, DatePicker, Select, TimePicker, Form, Modal } from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  FundViewOutlined
} from "@ant-design/icons";
import TableDelivery from "./table/TableDelivery";
import { api } from "../../api/axios";
import moment from "moment";
import { ExclamationCircleOutlined } from '@ant-design/icons';
export default function Search() {
  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [bachJobList, setBachJobList] = useState([]);
  const [timeStart, setTimeStart] = useState('');
  const [timeStop, setTimeFinish] = useState('');
  const [mobileData, setMobile] = useState('');
  const [nameData, setName] = useState('');
  const [dateStart, setDateStart] = useState('');
  const [dateStop, setDateFinish] = useState('');
  const [pageResult, setPageResult] = useState(0);
  const [pageSuccess, setPageSuccess] = useState(10);
  const [listChecked, setListChecked] = useState(['รอคิวส่ง', 'ส่งสำเร็จ', 'ยกเลิก']);


  const [value, setValue] = useState(1);

  const options = [
    { label: 'รอคิวส่ง', value: 'รอคิวส่ง' },
    { label: 'ส่งสำเร็จ', value: 'ส่งสำเร็จ' },
    { label: 'ยกเลิก', value: 'ยกเลิก' },
  ];

  const { Option } = Select;
  const dateFormat = 'DD/MM/YYYY';
  const dateCurrent = moment(new Date()).format("YYYY-MM-DD")
  let hour = ""
  let min = ""
  let sec = ""
  if (new Date().getHours() < 10) {
    hour = "0" + new Date().getHours()
  } else {
    hour = new Date().getHours()
  }

  if (new Date().getMinutes() < 10) {
    min = "0" + new Date().getMinutes()
  } else {
    min = new Date().getMinutes()
  }

  if (new Date().getSeconds() < 10) {
    sec = "0" + new Date().getSeconds()
  } else {
    sec = new Date().getSeconds()
  }
  const timeCurrent = hour + ":" + min + ":" + sec

  const getDataList = (subject, mobile, start_date, end_date, is_show_waiting, is_show_success, is_show_cancel) => {
    let json = {
      "subject_coupon": subject,
      "mobile": mobile,
      "start_date": start_date,
      "end_date": end_date,
      "is_show_waiting": is_show_waiting,
      "is_show_success": is_show_success,
      "is_show_cancel": is_show_cancel
    }
    setLoading(true)
    api
      .post("api/BachJob/ListJobBroadcast", json, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response.data;

        if (data.success) {
          if (data.data.items.length == 0) {
            setBachJobList([])
          } else {
            setPageResult(data.data.items.length)
            setBachJobList(data.data.items)
          }
        } else {
          setBachJobList([])
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
            window.location = `/#/logout`;
          }
        }
      });
  };

  const onChangePage = (value) => {
    setPage(value);
  };


  const onClickItem = (value) => {
    localStorage.setItem("image", value.broadcast_image_url);
    localStorage.setItem("message", value.broadcast_message);
    localStorage.setItem("subject", value.broadcast_subject);
    window.location = "/#/broadcast/report-boardcast/" + value.id;
  };

  const onClickCancel = (value) => {
    api
      .put("api/BachJob/CancelBroadcast/" + value.id, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response.data;
        if (data.success) {
          setVisible(true)

        } else {
          Modal.error({
            title: 'ไม่สามารถยกเลิกรายการได้',
            icon: <ExclamationCircleOutlined />,
            content: 'เนื่องจากรายการนี้อยู่ในขั้นตอนการ Broadcast เเล้ว',
            okText: 'ตกลง',
          });
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
          }
        }
      });
  };

  const onClear = () => {
    form.resetFields();
    var date = dateCurrent + "T" + timeCurrent + ".000Z"
    var date_start = dateCurrent + "T" + "00:00:00.000Z"
    setMobile("")
    setName("")
    setValue(1)
    setListChecked(['รอคิวส่ง', 'ส่งสำเร็จ', 'ยกเลิก'])
    var dateResult = new Date()
    setDateStart(moment(dateResult).format('YYYY-MM-DD'))
    setDateFinish(moment(dateResult).format('YYYY-MM-DD'))
    getDataList("", "", date_start, date, true, true, true)
  }

  const onFinish = (values) => {
    var subject_coupon = ""
    var mobile = ""
    var start_date = ""
    var end_date = ""
    var is_show_waiting = false
    var is_show_success = false
    var is_show_cancel = false

    if (value === 1) {
      subject_coupon = ""
      setName("")
    } else {
      subject_coupon = nameData
      setName(nameData)
    }


    if (values.check_mobile === true) {
      mobile = mobileData
    } else {
      mobile = ""
    }

    var diff = moment(dateStop).diff(moment(dateStart), 'days')

    if (dateStart)
      if (diff >= 0) {
        if (dateStart == '' && timeStart == '') {
          start_date = dateCurrent + "T" + "00:00:00.000Z"
        } else if (dateStart == '' && timeStart != '') {
          start_date = dateCurrent + "T" + timeStart + ".000Z"
        } else if (dateStart != '' && timeStart == '') {
          start_date = dateStart + "T" + "00:00:00.000Z"
        } else {
          start_date = dateStart + "T" + timeStart + ".000Z"
        }



        if (dateStop == '' && timeStop == '') {
          end_date = dateCurrent + "T" + timeCurrent + ".000Z"
        } else if (dateStop == '' && timeStop != '') {
          end_date = dateCurrent + "T" + timeStop + ".000Z"
        } else if (dateStop != '' && timeStop == '') {
          end_date = dateStop + "T" + timeCurrent + ".000Z"
        } else {
          end_date = dateStop + "T" + timeStop + ".000Z"
        }



        var wait = listChecked.filter(item => item === "รอคิวส่ง")
        var success = listChecked.filter(item => item === "ส่งสำเร็จ")
        var cancel = listChecked.filter(item => item === "ยกเลิก")

        if (wait.length === 1) {
          is_show_waiting = true
        } else {
          is_show_waiting = false
        }

        if (success.length === 1) {
          is_show_success = true
        } else {
          is_show_success = false
        }

        if (cancel.length === 1) {
          is_show_cancel = true
        } else {
          is_show_cancel = false
        }

        getDataList(
          subject_coupon,
          mobile,
          start_date,
          end_date,
          is_show_waiting,
          is_show_success,
          is_show_cancel)
      } else {
        Modal.error({
          title: '',
          icon: <ExclamationCircleOutlined />,
          content: 'เลือกช่วงวันที่ค้นหาไม่ถูกต้อง',
          okText: 'ตกลง',
        });
      }


  };



  const onConfirm = () => {
    var date = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString();
    getDataList("", "", date, date, false, false, false)
    setVisible(false)
  }


  const onCancel = () => {
    setVisible(false)
  }


  function isInputNumber(evt) {
    const ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }



  function onChangeOpen(time, timeString) {
    setTimeStart(timeString)
  }


  function onChangeFinish(time, timeString) {
    setTimeFinish(timeString)
  }


  function onChangeDateStart(date) {
    if (date !== null) {
      setDateStart(date.format('YYYY-MM-DD'))
    }
  }

  function onChangeDateFinish(date) {
    if (date !== null) {
      setDateFinish(date.format('YYYY-MM-DD'))
    }
  }


  function onChange(checkedValues) {
    setListChecked(checkedValues)
  }

  function onChangeRadio(e) {
    if (e.target.value === 1) {
      setMobile("")
      setName("")
      setListChecked(['รอคิวส่ง', 'ส่งสำเร็จ', 'ยกเลิก'])
    }
    setValue(e.target.value)
  }

  function onChangeName(e) {
    setName(e.target.value)
  }


  function onChangeMobile(e) {
    let isnum = /^\d+$/.test(e.target.value);

    if (isnum) {
      setMobile(e.target.value)
    } else {
      setMobile("")
    }

  }

  const onHandleChangePageResult = (value) => {
    setPageSuccess(value);
  };


  useEffect(() => {
    var date = new Date()
    setDateStart(moment(date).format('YYYY-MM-DD'))
    setDateFinish(moment(date).format('YYYY-MM-DD'))
  }, []);

  return (
    <div>
      <FundViewOutlined /> การจัดส่งคูปอง/ข้อความ
      <hr />

      <div >
        ค้นหา
      </div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row style={{ marginTop: 12 }}>
          <Col flex="280px" >
            <Form.Item
            >
              <Radio.Group style={{ marginTop: 4 }} onChange={onChangeRadio} value={value}>
                <Radio value={1} checked>เลือกทั้งหมด</Radio>
                <Radio value={2}>ชื่อคูปอง/หัวข้อเรื่อง</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="200px" style={{ marginLeft: 12 }}>
            <Form.Item
            >
              <Input value={nameData} onChange={onChangeName} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: 12 }}>
          <Col flex="140px" style={{ marginTop: 4 }}>
            <Form.Item
              name="check_mobile"
              valuePropName="checked"
            >
              <Checkbox>
                เบอร์โทรศัพท์
              </Checkbox>
            </Form.Item>
          </Col>
          <Col flex="200px" style={{ marginLeft: 24 }} >
            <Form.Item
            >
              <Input
                value={mobileData}
                onChange={onChangeMobile}
                onKeyPress={(event) => {
                  isInputNumber(event);
                }}
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: 12 }}>
          <Col xs={{ span: 24 }} lg={{ span: 3 }}>
            <label>จาก วันที่ส่ง :</label>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3 }}>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <label>ถึง วันที่ส่ง :</label>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3, offset: 1 }}>

          </Col>
        </Row>

        <Row style={{ marginTop: 8 }}>
          <Col xs={{ span: 24 }} lg={{ span: 3 }}>
            <Form.Item
              name="date_start"
            >
              <DatePicker placeholder="เลือกวันที่" style={{ width: "100%" }} initialvalues={moment()} format={dateFormat}
                onChange={onChangeDateStart} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3 }}>
            <Form.Item
              name="time_start"
            >
              <TimePicker onChange={onChangeOpen} placeholder="เลือกเวลา" style={{ width: "80%", marginLeft: 24 }} initialvalues={moment('00:00:00', 'HH:mm:ss')} initialValues={moment('00:00:00', 'HH:mm:ss')}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item
              name="date_finish"
            >
              <DatePicker placeholder="เลือกวันที่" style={{ width: "100%" }} initialvalues={moment()} format={dateFormat}
                onChange={onChangeDateFinish} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3 }}>
            <Form.Item
              name="time_finish"
            >
              <TimePicker onChange={onChangeFinish} placeholder="เลือกเวลา" style={{ width: "80%", marginLeft: 24 }} initialvalues={moment(timeCurrent, 'HH:mm:ss')} initialValues={moment(timeCurrent, 'HH:mm:ss')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: 12 }}>
          <label>สถานะจัดส่ง : </label>
        </Row>

        <Row style={{ marginBottom: 36 }}>
          <Col flex="280px" >
            <Form.Item
            >
              <Checkbox.Group style={{ marginTop: 8 }} options={options} initialvalues={['รอคิวส่ง', 'ส่งสำเร็จ', 'ยกเลิก']} onChange={onChange} />
            </Form.Item>
          </Col>
          <Col flex="60px">
            <Button type="danger" onClick={onClear} style={{ width: '100%' }}>
              <DeleteOutlined />
              ล้าง
            </Button>
          </Col>
          <Col flex="60px" style={{ marginLeft: 24 }}>
            <Button type="primary" htmlType="submit">
              <SearchOutlined />
              ค้นหา
            </Button>
          </Col>
        </Row>
      </Form>

      <Col xs={24} style={{ marginTop: 48 }}>
        <TableDelivery
          data={bachJobList}
          page={page}
          loading={loading}
          onChangePage={onChangePage}
          onClickDetail={onClickItem}
          onClickCanel={onClickCancel}
          pageResult={pageResult}
          pageSuccess={pageSuccess}
          handleChangePage={onHandleChangePageResult}
        />
      </Col>


      <Modal
        title=""
        visible={visible}
        onOk={onConfirm}
        onCancel={onCancel}
        okText="ตกลง"
        cancelText="ยกเลิก"
        cancelButtonProps={{ style: { display: 'none' } }}>
        <p>ยกเลิกรายการสำเร็จเเล้ว</p>
      </Modal>

    </div>
  )
}
